import React from "react";
import classNames from "./Chat.module.scss";
import { IChatMessage } from "./Chat.types";

export interface IChatMessageActionsProps {
  chatMessage: IChatMessage;
  onMessageCopy?: (chatMessage: IChatMessage) => void;
  onMessageThumbsUp?: (chatMessage: IChatMessage) => void;
  onMessageThumbsDown?: (chatMessage: IChatMessage) => void;
}

export const ChatMessageActions = (props: IChatMessageActionsProps) => {
  const { chatMessage, onMessageCopy, onMessageThumbsUp, onMessageThumbsDown } = props;

  if (!chatMessage) {
    return null;
  }

  return (
    <div className={classNames.messageActions}>
      <span className={classNames.actionIcon} title="Good response" onClick={() => onMessageThumbsUp(chatMessage)}>
        &#x1F44D;
      </span>
      <span className={classNames.actionIcon} title="Bad response" onClick={() => onMessageThumbsDown(chatMessage)}>
        &#x1F44E;
      </span>
      <span className={classNames.actionIcon} title="Copy message content" onClick={() => onMessageCopy(chatMessage)}>
        &#x1F4CB;
      </span>
    </div>
  );
};
