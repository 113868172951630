import React, { useState, useContext, useCallback, CSSProperties, useEffect, useMemo, useRef } from "react";
import classNames from "./CopilotPane.module.scss";
import Chat from "../../shared/components/Chat/Chat";
import { ChatRole, IChatMessage } from "../../shared/components/Chat/Chat.types";
import { connect } from "react-redux";
import { IState } from "../../reducers/interfaces";
import { fetchChatResponse, fetchCopilotChatResponse, fetchTopPrompts } from "../../api/radarApi";
import { AppModuleContext } from "../common/AppModule";
import { AppContext } from "../../app/App";
import CopilotCommandBar from "./CopilotCommandBar";
import { getUserId, shouldRefreshCopilotCloseStatus } from "../common/helper";
import { getCodeContent, getMessageContent } from "./CopilotPane.helper";
import { IChatRequest, IContent, IDebugMessage } from "./interfaces";
import { createGuid } from "../../shared/utilities/miscHelper";

export enum CopilotPaneSize {
  min,
  normal,
  large,
  max,
}

export enum CopilotMode {
  Immersive,
  Assistive,
}

export const CopilotChatDataTextMaxSize = 25000;

export interface ICopilotPaneProps {
  className?: string;
  style?: CSSProperties;
  hideResizeButtons?: boolean;
  largeHeaderText?: boolean;
  copilotMode?: CopilotMode;
  useFloatingInputPane?: boolean;
}

export interface ICopilotPaneStateProps {
  userPhoto: string;
  userId: string;
}

export const CopilotPane = (props: ICopilotPaneProps & ICopilotPaneStateProps) => {
  const {
    userPhoto,
    className,
    style,
    largeHeaderText,
    hideResizeButtons,
    copilotMode = CopilotMode.Assistive,
    useFloatingInputPane,
    userId,
  } = props;
  const [sessionId, setSessionId] = useState<string>(createGuid());
  const [messages, setMessages] = useState<IChatMessage[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [hasUserAskedQuestion, setHasUserAskedQuestion] = useState<boolean>(false);
  const [debugMessages, setDebugMessages] = useState<IDebugMessage[]>([]);
  const [showDebugMessages, setShowDebugMessages] = useState<boolean>(false);
  const { appState, changeUserSettings } = useContext(AppContext);
  const { useCopilot, userSettings } = appState;
  const { appModuleState } = useContext(AppModuleContext);
  const {
    copilotDataText,
    copilotSuggestions,
    copilotExtraChatMessages,
    copilotInitialMessages,
    copilotInitialMessageCount = 5,
    copilotFollowupMessageCount = 3,
    copilotPaneSize = CopilotPaneSize.normal,
    appModuleName,
  } = appModuleState;
  const [topPrompts, setTopPrompts] = useState<any[]>();
  const debugMessagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [debugMessages]);

  const scrollToBottom = () => {
    debugMessagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setTimeout(() => fetchTopPrompts(appModuleName).then((result: any) => setTopPrompts(result)), 1000);
  }, [appModuleName]);

  const userPrompts = useMemo(() => {
    if (userSettings?.promptHistory && userSettings?.promptHistory[appModuleName]?.length) {
      return userSettings.promptHistory[appModuleName].sort((a, b) =>
        a.useCount > b.useCount || a.lastUsed > b.lastUsed
          ? -1
          : a.useCount < b.useCount || a.lastUsed < b.lastUsed
          ? 1
          : 0
      );
    }
    return null;
  }, [userSettings, appModuleName]);

  const getInitialMessages = useCallback(() => {
    let messages: IChatMessage[] =
      copilotMode === CopilotMode.Immersive
        ? []
        : copilotInitialMessages?.length
        ? copilotInitialMessages
        : copilotSuggestions?.length
        ? initialChatMessagesWithSuggestions.slice()
        : initialAssistiveChatMessages.slice();

    if (copilotDataText && copilotMode !== CopilotMode.Immersive) {
      messages.push({
        role: ChatRole.system,
        content: copilotDataText,
        hidden: true,
      });
    }

    if (copilotExtraChatMessages?.length) {
      messages = messages.concat(copilotExtraChatMessages);
    }

    return messages;
  }, [copilotDataText, copilotSuggestions, copilotExtraChatMessages, copilotInitialMessages, copilotMode]);

  const resetMessages = useCallback(() => {
    if ((useCopilot && copilotDataText) || copilotMode === CopilotMode.Immersive) {
      setHasUserAskedQuestion(false);
      setMessages(getInitialMessages());
      setSessionId(createGuid());
    }
  }, [useCopilot, copilotDataText, copilotMode, getInitialMessages]);

  useEffect(() => {
    setMessages(getInitialMessages());
  }, [getInitialMessages]);

  const getCopilotChatResponse = (userContent: string, extraContent?: object) => {
    setLoadingData(true);

    let chatRequest: IChatRequest = {
      ...extraContent,
      sessionId,
      userId,
      content: userContent,
    };

    fetchCopilotChatResponse(chatRequest)
      .then((result: any) => {
        setMessages((messages) => {
          result?.messages?.length && setDebugMessages((debugMessages) => debugMessages.concat(result.messages));

          let newMessages = messages.slice();
          newMessages.push({ role: ChatRole.assistant, content: getMessageContent(result, onExecuteQuery) });
          return newMessages;
        });
      })
      .catch((error) => {
        setMessages((messages) => {
          let newMessages = messages.slice();
          newMessages.push({ role: ChatRole.assistant, content: `Sorry, error occurred. ${error}` });
          return newMessages;
        });
      })
      .finally(() => setLoadingData(false));
  };

  const getChatResponse = (newMessages: IChatMessage[], doNotUpdateResponse = false) => {
    setLoadingData(true);

    fetchChatResponse(newMessages)
      .then((result: any) => {
        if (result?.responseText && !doNotUpdateResponse) {
          newMessages.push({ role: ChatRole.assistant, content: result.responseText });
        } else if (result?.error) {
          newMessages.push({ role: ChatRole.assistant, content: `Sorry, error occurred. ${result.error}` });
        }

        if (!doNotUpdateResponse) {
          setMessages(newMessages.slice());
          setHasUserAskedQuestion(true);
        }
      })
      .catch((error) => {
        newMessages.push({ role: ChatRole.assistant, content: `Sorry, error occurred. ${error}` });
        setMessages(newMessages.slice());
      })
      .finally(() => setLoadingData(false));
  };

  const updateUserPromptHistory = useCallback(
    (message: string) => {
      // Save user message/prompt history.
      let newUserSettings = userSettings;
      !newUserSettings && (newUserSettings = {});
      !newUserSettings.promptHistory && (newUserSettings.promptHistory = {});
      !newUserSettings.promptHistory[appModuleName] && (newUserSettings.promptHistory[appModuleName] = []);

      let existingPrompt = newUserSettings.promptHistory[appModuleName].find(
        (prompt) => prompt.message.toLowerCase().trim() === message.toLowerCase().trim()
      );

      if (existingPrompt) {
        existingPrompt.lastUsed = new Date().toISOString();
        existingPrompt.useCount = existingPrompt.useCount + 1;
      } else {
        newUserSettings.promptHistory[appModuleName].push({ message, lastUsed: new Date().toISOString(), useCount: 1 });
      }

      changeUserSettings(newUserSettings);
    },
    [appModuleName, changeUserSettings, userSettings]
  );

  const onSendRequested = (message: string | JSX.Element, doNotUpdateResponse = false) => {
    message = typeof message === "string" ? message?.trim() : message;

    if (!message) return;

    setMessages((messages) => {
      let newMessages = messages?.slice() || [];
      newMessages.push({ role: ChatRole.user, content: message });

      if (copilotMode === CopilotMode.Immersive) {
        getCopilotChatResponse(message.toString());
      } else {
        getChatResponse(newMessages, doNotUpdateResponse);
      }
      return newMessages;
    });

    typeof message === "string" && setTimeout(() => updateUserPromptHistory(message.toString()), 500);
  };

  const onDebugToggled = () => {
    setShowDebugMessages(!showDebugMessages);
  };

  const onExecuteQuery = (content: IContent) => {
    let messageContent = getCodeContent(content);

    setMessages((messages) => {
      let newMessages = messages?.slice() || [];
      newMessages.push({ role: ChatRole.user, content: messageContent });

      getCopilotChatResponse(content.body, { type: content.type, metadata: content.metadata });

      return newMessages;
    });
  };

  useEffect(() => {
    if (shouldRefreshCopilotCloseStatus() && copilotSuggestions?.length) {
      onSendRequested(copilotSuggestions[0], true);
    }
  }, [copilotSuggestions]); // eslint-disable-line react-hooks/exhaustive-deps

  const onPromptSelected = (prompt: string) => {
    onSendRequested(prompt);
  };

  const onResetMessages = () => {
    resetMessages();
  };

  const finalSuggestions = useMemo(() => {
    const userQuestions = messages
      ?.filter((message) => message.role === ChatRole.user && !message.hidden)
      .map((message) => message.content);

    const topPromptSuggestions = topPrompts
      ?.map((prompt) => prompt.message)
      .filter((message) => !copilotSuggestions?.find((s) => s.toLowerCase() === message.toLowerCase()))
      ?.slice(0, 2);

    const topUserSuggestions = userPrompts
      ?.map((prompt) => prompt.message)
      .filter(
        (message) =>
          !copilotSuggestions?.find((s) => s.toLowerCase() === message.toLowerCase()) &&
          !topPromptSuggestions?.find((s) => s.toLowerCase() === message.toLowerCase())
      )
      ?.slice(0, 5);

    const combinedSuggestions = copilotSuggestions?.concat(topPromptSuggestions)?.concat(topUserSuggestions);

    return hasUserAskedQuestion
      ? combinedSuggestions?.filter(
          (suggestion) => !userQuestions?.find((s) => s?.toString()?.toLowerCase() === suggestion.toLowerCase())
        )
      : combinedSuggestions;
  }, [copilotSuggestions, hasUserAskedQuestion, messages, topPrompts, userPrompts]);

  const rootClassNames = [classNames.root, className];
  const headerClassNames = [classNames.paneHeader];
  const messagesClassNames = [];

  if (largeHeaderText) {
    headerClassNames.push(classNames.largeHeaderText);
  }

  if (copilotMode === CopilotMode.Immersive) {
    messagesClassNames.push(classNames.immersive);
  }

  return (
    <div className={rootClassNames.join(" ")} style={style}>
      <CopilotCommandBar
        topPrompts={topPrompts}
        userPrompts={userPrompts}
        hideResizeButtons={hideResizeButtons}
        copilotMode={CopilotMode.Immersive}
        onPromptSelected={onPromptSelected}
        onDebugToggled={onDebugToggled}
      />
      {copilotPaneSize !== CopilotPaneSize.min ? (
        <div className={classNames.copilotContentRoot}>
          <div className={classNames.copilotContent}>
            <div className={headerClassNames.join(" ")}>Commerce Radar Copilot</div>
            <div className={classNames.chatPane}>
              <Chat
                chatMessages={messages}
                userPhoto={userPhoto}
                loadingData={loadingData}
                convertMarkdown={true}
                suggestions={!loadingData && finalSuggestions}
                maxSuggestions={hasUserAskedQuestion ? copilotFollowupMessageCount : copilotInitialMessageCount}
                messagesClassName={messagesClassNames.join(" ")}
                useFloatingInputPane={useFloatingInputPane}
                onSendRequested={onSendRequested}
                onResetMessages={onResetMessages}
                onSuggestionClick={(suggestion) => onSendRequested(suggestion)}
              />
            </div>
          </div>
          {showDebugMessages && (
            <div className={classNames.debugConsolePane}>
              <div className={classNames.title}>Debug Console</div>
              <div className={classNames.content}>
                {debugMessages?.map((message) => {
                  return (
                    <div className={classNames.message}>
                      <div className={classNames.timestamp}>
                        {new Date(message.timestamp).toLocaleString("en-US", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                          fractionalSecondDigits: 3,
                        })}
                      </div>
                      <div className={classNames.debugContent}>{message.message}</div>
                    </div>
                  );
                })}
                <div ref={debugMessagesEndRef} />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className={classNames.verticalText}>Commerce Radar Copilot</div>
      )}
    </div>
  );
};

const mapStateToProps = (state: IState) => ({
  userPhoto: state.app.login_user_photo,
  userId: getUserId(state.app.login_user),
});

export default connect(mapStateToProps)(CopilotPane);

const initialAssistiveChatMessages = [
  { role: ChatRole.system, content: "Welcome to Commerce Radar Copilot." },
  { role: ChatRole.system, content: "What information you want to know about this data on the left?" },
];

const initialChatMessagesWithSuggestions = [
  { role: ChatRole.system, content: "Welcome to Commerce Radar Copilot." },
  {
    role: ChatRole.system,
    content:
      "Enter what you want to know about this data on the left, or select one of the following common questions.",
  },
];
