import { ILink, INode } from "../shared/components/Diagram";
import {
  IControlResult,
  IControlReport,
  IControlViewList,
  IControlView,
  IControl,
  IEntityList,
} from "./controls/interfaces";
import { IIncident, IIncidentView } from "./incidents/interfaces";
import {
  IFilter,
  ILinkedItem,
  IComment,
  IReportGroup,
  IFilterItem,
  IEntity,
  IDevOpsItem,
  IDevOpsView,
  IIcMConnector,
  IEvent,
  IDevOpsOrg,
  IChecklistDevOpsItem,
  IFinancialEntity,
  IFinancialEntityCount,
  IFinancialEntitySearch,
  IEntityTest,
  IEntityMetric,
  IEntityTraceMetric,
  IEntityTile,
  IEntityView,
  IEntityTraceView,
  ISearchField,
  ISearchFilter,
  ITeam,
  ISearchResultItem,
  IOkrMetric,
  ITemplate,
  IConfigItem,
} from "./common/interfaces";
import { TimeSelectorValue } from "../shared/components/TimeSelector";
import { SearchItemKey } from "./graph/helper";
import { Dictionary, IConfigEnvironment, IEnvironment } from "../app/interfaces";
import { IVisualProps } from "../shared/components/Visual";
import { IConfigDefs } from "./management/configDefs/ConfigDefsEdit.helper";
import { IColumn } from "@fluentui/react";

export interface IAppModuleState {
  // Incidents related
  loading_incidents?: boolean;
  loading_incident?: boolean;
  loading_incident_views?: boolean;
  loading_incident_details?: boolean;
  loading_incident_tiles?: boolean;
  incidents?: IIncident[];
  filtered_incidents?: IIncident[];
  filtered_incident_ids?: string[];
  selected_incident_id?: string;
  selected_incident_index?: number;
  incident_filters?: IFilter[];
  selected_incident_filters?: string[];
  selected_incident_time?: TimeSelectorValue;
  incidents_sorted_descending?: boolean;
  incidents_sorted_column_key?: string;
  selected_incident_view_id?: string;
  selected_incident_view?: IIncidentView;
  incident_views?: IIncidentView[];
  incident_view_search_text?: string;
  incidents_default_columns?: IColumn[];
  // Controls related
  control_view_filters?: IFilter[];
  selected_control_view_id?: string;
  selected_control_node?: INode;
  selected_control_link?: ILink;
  selected_control?: IControlResult;
  selected_control_index?: number;
  filtered_control_ids?: string[];
  control_report?: IControlReport;
  control_results?: IControlResult[];
  control_result_items?: IControlResult[];
  loading_control_results?: boolean;
  loading_control_result_items?: boolean;
  selected_control_views?: IControlView[];
  control_views?: IControlViewList;
  controls?: IControl[];
  loading_control_view?: boolean;
  is_next_view_collapsed?: boolean;
  control_entities?: IEntityList;
  loading_controls?: boolean;
  loading_control_tiles?: boolean;
  loading_control_result_history?: boolean;
  loading_control_result_details?: boolean;
  selected_control_result_id?: string;
  saving_control_details?: boolean;
  editing_control_comment?: IComment;
  saving_control_comment?: boolean;
  editing_control_linked_item?: ILinkedItem;
  saving_control_linked_item?: boolean;
  icm_connectors?: IIcMConnector[];
  standard_templates?: ITemplate[];
  creating_control_incident?: boolean;
  control_metadata?: any;
  // Config related
  loading_config?: boolean;
  loading_config_items?: boolean;
  loading_config_defs_items?: boolean;
  loading_config_item_history?: boolean;
  loading_config_environments?: boolean;
  config_environments?: Dictionary<IConfigEnvironment>;
  default_environment?: string;
  loading_config_attribute_values?: boolean;
  config_attribute_values?: {};
  config_items?: any[];
  config_def?: IConfigDefs;
  config_history?: any[];
  publish_history?: any[];
  editing_config_value_original?: string;
  editing_config_values: Dictionary<any>;
  editing_config_original_values: Dictionary<any>;
  editing_config_value?: string;
  editing_config_item_global?: object;
  saving_config?: boolean;
  saving_config_success?: boolean;
  publishing_config?: boolean;
  publishing_config_success?: boolean;
  reverting_config?: boolean;
  reverting_config_success?: boolean;
  loading_financial_entity_journal_items?: boolean;
  loading_financial_entity_journal_items_success?: boolean;
  financial_entity_journal_items?: {};
  service_content_configs?: any[];
  compliance_configs?: IConfigItem[];
  teams?: ITeam[];
  team_selected_date?: string;
  team_tiles?: any[];
  team_okr_metrics?: IOkrMetric[];
  selected_team_id?: string;
  loading_team_tiles?: boolean;
  loading_team_okr_metrics?: boolean;
  search_result?: ISearchResultItem[];
  loading_search_result?: boolean;
  environments?: IEnvironment[];
  // Report related
  loading_report_group?: boolean;
  selected_report_group?: IReportGroup;
  reports?: object;
  report_loading?: boolean;
  loading_report_tile?: boolean;
  report_error?: string;
  report_tile_error?: string;
  selected_report_id?: string;
  selected_report_filters?: IFilterItem[];
  report_excel_downloading?: boolean;
  // Graph related
  entity_types?: string[];
  all_entity_types?: string[];
  search_entity_type?: string;
  search_entity_id?: string;
  search_entity_version?: string;
  search_entity_start_time?: Date;
  search_entity_end_time?: Date;
  search_item_key?: SearchItemKey;
  search_entity_date_type?: string;
  selected_entity?: IEntity;
  no_entity_found?: boolean;
  no_entity_trace_found?: boolean;
  entity_selection_history?: (IEntity | IFinancialEntitySearch)[];
  show_entity_details?: boolean;
  entity_details?: object;
  financial_entities?: IFinancialEntity[];
  metric_search_entities?: IFinancialEntity[];
  entity_search_entities?: IFinancialEntity[];
  financial_entity_counts?: IFinancialEntityCount[];
  entity_tests?: IEntityTest[];
  loading_entity?: boolean;
  loading_entity_tests?: boolean;
  loading_entity_metrics?: boolean;
  loading_entity_trace_metrics?: boolean;
  loading_entity_tiles?: boolean;
  loading_financial_entities?: boolean;
  loading_financial_entity_counts?: boolean;
  loading_entity_details?: boolean;
  loading_entity_relationships?: boolean;
  entity_views?: IEntityView[];
  entity_trace_views?: IEntityTraceView[];
  entity_trace_view_id?: string;
  entity_trace_metrics?: IEntityTraceMetric[];
  entity_metrics_for_graph?: IEntityMetric[];
  entity_metrics_raw?: IEntityMetric[];
  entity_metrics_by_selected_view?: IEntityMetric[];
  entity_search_metrics_for_graph?: IEntityMetric[];
  entity_tiles?: IEntityTile[];
  selected_entity_view_id?: string;
  selected_metric_ids?: string[];
  graph_subpane_selected_key?: string;
  metric_search_fields?: ISearchField[];
  metric_search_filters?: ISearchFilter[];
  entity_search_fields?: ISearchField[];
  entity_search_filters?: ISearchFilter[];
  default_search_filters?: ISearchFilter[];
  search_random_top?: boolean;
  search_batch_size?: number;
  entity_metrics_query_timestamp?: string;
  entity_metrics_query_time?: number;
  search_view_fields?: IVisualProps[];
  search_view_filters?: ISearchFilter[];
  // DevOps related
  selected_devops_view_id?: string;
  devops_items?: IDevOpsItem[];
  devops_views?: IDevOpsView[];
  selected_devops_view?: IDevOpsView;
  loading_devops_items?: boolean;
  loading_devops_views?: boolean;
  generating_devops_workitems?: boolean;
  generated_devops_workitem?: IChecklistDevOpsItem;
  downloading_email_file?: boolean;
  deleting_devops_workitem?: boolean;
  devops_search_text?: string;
  devops_filters?: IFilter[];
  devops_view_column_field_names?: string[];
  filtered_devops_items?: IDevOpsItem[];
  selected_devops_inline_filters?: string[];
  devops_orgs?: IDevOpsOrg[];
  // Event related
  radar_events?: IEvent[];
  // Others
  errors?: ICustomProps;
}

export enum errorType {
  incidents,
  incidentDetails,
  incidentOpenAiSummary,
  controlResults,
  controlView,
  controls,
  controlComment,
  controlLinkedItem,
  controlTiles,
  controlIncident,
  configAttributeValues,
  config,
  reportGroup,
  reportExcelDownload,
  entity,
  entityDetails,
  entityRelationships,
  entityTests,
  entityTiles,
  entityTraces,
  financialEntities,
  devOpsItems,
  devOpsViews,
  devOpsWorkItems,
  devOpsEmailDownload,
  devOpsWorkItemDelete,
  teamTiles,
  teamOkrMetrics,
}

export enum searchDateType {
  thisMonth = "This Month",
  lastMonth = "Last Month",
  last7Days = "Last 7 Days",
  customDate = "Custom Date Range",
  customMonth = "Custom Month",
}

export interface ICustomProps {
  [props: string]: any;
}

export interface IItemBase {
  id?: string;
  name?: string;
}
