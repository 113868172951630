export enum ChatRole {
  system = "system",
  assistant = "assistant",
  user = "user",
}

export enum ChatAlign {
  left,
  right,
}

export interface IChatMessage {
  role: ChatRole;
  content: string | JSX.Element;
  align?: ChatAlign;
  hidden?: boolean;
  suggestions?: string[];
}
