import { IVisualProps } from "../../shared/components/Visual";
import { IDataSource, ITile } from "../common/interfaces";

export interface IChatRequest {
  sessionId: string;
  userId: string;
  content: string;
  metadata?: object;
}

export interface IChatResponse {
  contents: IContent[];
  messages?: IDebugMessage[];
}

export interface IContent {
  type: ContentType;
  body?: string;
  metadata?: object;
  data?: any[];
  queryType?: QueryType;
  dataSource?: IDataSource;
  visualProps?: IVisualProps;
  tiles?: ITile[];
}

export enum ContentType {
  Markdown = "markdown",
  Code = "code",
  Table = "table",
  Tile = "tile",
  CloseDashboard = "closeDashboard",
  ControlStatuses = "controlStatuses",
  FinLivesite = "finLivesite",
}

export enum QueryType {
  CosmosDbSql = "CosmosDbSql",
  Kusto = "Kusto",
  Sql = "Sql",
}

export interface IDebugMessage {
  message: string;
  timestamp?: string;
  type?: string;
}
