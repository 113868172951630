import React from "react";
import { ITileProps } from "./Tile";
import classNames from "./TileFullScreen.module.scss";
import { ActionButton, Modal } from "@fluentui/react";

export interface ITileFullScreenProps extends ITileProps {
  onClose: () => void;
  children?: React.ReactNode;
}

export class TileFullScreen extends React.Component<ITileFullScreenProps> {
  render() {
    const { title, subtitle, onClose, children } = this.props;
    const titleId = "TileFullScreenTitleId";

    let childContent = Array.isArray(children) && children.length ? children[children.length - 1] : children;

    return (
      <Modal
        containerClassName={classNames.root}
        isBlocking={true}
        isOpen={true}
        onDismiss={onClose}
        titleAriaId={titleId}
      >
        <div className={classNames.header}>
          <div id={titleId} className={classNames.title} dangerouslySetInnerHTML={{ __html: title }} title={title} />
          <div className={classNames.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} title={subtitle} />
          <ActionButton
            onClick={onClose}
            title="Close Full Screen"
            className={classNames.close}
            aria-label="Close Full Screen"
            iconProps={{
              iconName: "ChromeClose",
            }}
          />
        </div>
        <div className={classNames.content}>
          <div className={classNames.contentInner}>
            {React.isValidElement(childContent) && React.cloneElement(childContent)}
          </div>
        </div>
      </Modal>
    );
  }
}

export default TileFullScreen;
