import React from "react";
import { createRoot } from "react-dom/client";
import { Store, createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import reducers from "./reducers";
import { actionCreator as appActionCreator } from "./app/duck";
import thunk from "redux-thunk";
import "./index.css";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { initializeIcons } from "@fluentui/react/lib/Icons";

initializeIcons();

//@ts-ignore
const reduxDevtools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store: Store = createStore(reducers, reduxDevtools(applyMiddleware(thunk)));

store.dispatch<any>(appActionCreator.initializeApp());

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
