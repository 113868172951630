import { INode, ILink } from "../shared/components/Diagram";
import { IAppModuleState, errorType, searchDateType } from "./interfaces";
import { IControlResult, IControlView, IControl, IEntity, IEntityList } from "./controls/interfaces";
import { IIncident, IIncidentView } from "./incidents/interfaces";
import { IAction } from "../reducers/interfaces";
import { TimeSelectorValue } from "../shared/components/TimeSelector";
import * as thunkActions from "./thunk";
import { getIncidents, getIncidentsFilters, getFilteredIncidentIds } from "./incidents/IncidentsReport.helper";
import {
  getControlReport,
  getControlReportByNode,
  getControlReportByLink,
  getControlResults,
  getSelectedControlViews,
} from "./controls/ControlItemList.helper";
import { updateControlViews } from "./controls/HealthDiagram.helper";
import {
  IComment,
  ILinkedItem,
  IReportGroup,
  IReport,
  IFilterItem,
  ITile,
  ISearchFilter,
  IEntityView,
  IOkrMetric,
} from "./common/interfaces";
import { ConfigItemType } from "../api/radarApi";
import {
  getFinancialEntitiesByEntityView,
  getSelectedEntityTypes,
  transformEntityMetrics,
  getNewSelectionHistoryForAggregateSearch,
  EntitySubPaneKey,
  dynamicEntityViewId,
  getStartEndDates,
  getAnchorEntityTypes,
  getSearchFields,
  SearchItemKey,
  getDefaultSearchFilters,
  getSearchViewFields,
  getSearchViewFilters,
  changeSearchViewFilters,
} from "./graph/helper";
import {
  featureRequestsViewId,
  getFilteredDevOpsItems,
  getDevOpsViewColumnFieldNames,
  getDevOpsInlineFilters,
  getDefaultDevOpsInlineFilters,
} from "./works/helper";
import { IVisualProps } from "../shared/components/Visual";
import { IConfigDefs } from "./management/configDefs/ConfigDefsEdit.helper";
import { getFirstOfMonthISODate } from "../shared/utilities/miscHelper";
import { updateTeamReport } from "./okrs/helper";
import { IEnvironment, IUserInfo } from "../app/interfaces";
import { isGlobalEnvironment } from "../api/apiBase";
import { getEnvironmentConfigTypeSettings } from "./management/common/ConfigItemEdit.helper";
import { IField } from "../shared/components/DataForm";

export const selectedEntityViewIdStateName = "selectedEntityViewId";

export const actionTypes = {
  // Incidents related.
  REQUEST_INCIDENTS: "REQUEST_INCIDENTS",
  RECEIVED_INCIDENTS: "RECEIVED_INCIDENTS",
  REQUEST_INCIDENTS_FAILED: "REQUEST_INCIDENTS_FAILED",
  SELECT_INCIDENT: "SELECT_INCIDENT",
  FILTER_INCIDENTS: "FILTER_INCIDENTS",
  UNSELECT_INCIDENT: "UNSELECT_INCIDENT",
  REQUEST_INCIDENT_DETAILS: "REQUEST_INCIDENT_DETAILS",
  RECEIVED_INCIDENT_DETAILS: "RECEIVED_INCIDENT_DETAILS",
  REQUEST_INCIDENT_DETAILS_FAILED: "REQUEST_INCIDENT_DETAILS_FAILED",
  SELECT_INCIDENT_FILTER: "SELECT_INCIDENT_FILTER",
  SELECT_SYSTEM_NODE: "SELECT_SYSTEM_NODE",
  SELECT_SYSTEM_LINK: "SELECT_SYSTEM_LINK",
  SELECT_INCIDENT_TIME: "SELECT_INCIDENT_TIME",
  CHANGE_INCIDENTS: "CHANGE_INCIDENTS",
  SELECT_INCIDENT_VIEW: "SELECT_INCIDENT_VIEW",
  REQUEST_INCIDENT_VIEWS: "REQUEST_INCIDENT_VIEWS",
  RECEIVED_INCIDENT_VIEWS: "RECEIVED_INCIDENT_VIEWS",
  REQUEST_INCIDENT_VIEWS_FAILED: "REQUEST_INCIDENT_VIEWS_FAILED",
  REQUEST_INCIDENT_TILES: "REQUEST_INCIDENT_TILES",
  RECEIVED_INCIDENT_TILES: "RECEIVED_INCIDENT_TILES",
  REQUEST_INCIDENT_TILES_FAILED: "REQUEST_INCIDENT_TILES_FAILED",
  CHANGE_INCIDENTS_SEARCH_TEXT: "CHANGE_INCIDENTS_SEARCH_TEXT",
  DOWNLOAD_INCIDENTS_DATA_TO_EXCEL_STARTED: "DOWNLOAD_INCIDENTS_DATA_TO_EXCEL_STARTED",
  DOWNLOAD_INCIDENTS_DATA_TO_EXCEL_COMPLETED: "DOWNLOAD_INCIDENTS_DATA_TO_EXCEL_COMPLETED",
  DOWNLOAD_INCIDENTS_DATA_TO_EXCEL_FAILED: "DOWNLOAD_INCIDENTS_DATA_TO_EXCEL_FAILED",
  LOAD_INCIDENT_STARTED: "LOAD_INCIDENT_STARTED",
  LOAD_INCIDENT_COMPLETED: "LOAD_INCIDENT_COMPLETED",
  LOAD_INCIDENT_FAILED: "LOAD_INCIDENT_FAILED",
  LOAD_INCIDENT_OPENAI_SUMMARY_STARTED: "LOAD_INCIDENT_OPENAI_SUMMARY_STARTED",
  LOAD_INCIDENT_OPENAI_SUMMARY_COMPLETED: "LOAD_INCIDENT_OPENAI_SUMMARY_COMPLETED",
  LOAD_INCIDENT_OPENAI_SUMMARY_FAILED: "LOAD_INCIDENT_OPENAI_SUMMARY_FAILED",
  // Controls related
  UPDATE_DIAGRAM_NODE_POSITION: "UPDATE_DIAGRAM_NODE_POSITION",
  SELECT_ALL_CONTROLS: "SELECT_ALL_CONTROLS",
  SELECT_CONTROL: "SELECT_CONTROL",
  UNSELECT_CONTROL: "UNSELECT_CONTROL",
  REQUEST_CONTROL_RESULTS: "REQUEST_CONTROL_RESULTS",
  RECEIVED_CONTROL_RESULTS: "RECEIVED_CONTROL_RESULTS",
  REQUEST_CONTROL_RESULTS_FAILED: "REQUEST_CONTROL_RESULTS_FAILED",
  LOAD_CONTROL_RESULT_STARTED: "LOAD_CONTROL_RESULT_STARTED",
  LOAD_CONTROL_RESULT_COMPLETED: "LOAD_CONTROL_RESULT_COMPLETED",
  LOAD_CONTROL_RESULT_FAILED: "LOAD_CONTROL_RESULT_FAILED",
  REQUEST_CONTROL_RESULT_ITEMS: "REQUEST_CONTROL_RESULT_ITEMS",
  RECEIVED_CONTROL_RESULT_ITEMS: "RECEIVED_CONTROL_RESULT_ITEMS",
  REQUEST_CONTROL_RESULT_ITEMS_FAILED: "REQUEST_CONTROL_RESULT_ITEMS_FAILED",
  SELECT_CONTROL_FILTER: "SELECT_CONTROL_FILTER",
  LOAD_CONTROL_VIEW: "LOAD_CONTROL_VIEW",
  REQUEST_CONTROL_VIEWS: "REQUEST_CONTROL_VIEWS",
  RECEIVED_CONTROL_VIEWS: "RECEIVED_CONTROL_VIEWS",
  REQUEST_CONTROL_VIEWS_FAILED: "REQUEST_CONTROL_VIEWS_FAILED",
  REQUEST_CONTROLS: "REQUEST_CONTROLS",
  RECEIVED_CONTROLS: "RECEIVED_CONTROLS",
  REQUEST_CONTROLS_FAILED: "REQUEST_CONTROLS_FAILED",
  TOGGLE_NEXT_VIEW: "TOGGLE_NEXT_VIEW",
  REQUEST_ENTITIES: "REQUEST_ENTITIES",
  RECEIVED_ENTITIES: "RECEIVED_ENTITIES",
  REQUEST_ENTITIES_FAILED: "REQUEST_ENTITIES_FAILED",
  REQUEST_CONTROL_TILES: "REQUEST_CONTROL_TILES",
  RECEIVED_CONTROL_TILES: "RECEIVED_CONTROL_TILES",
  REQUEST_CONTROL_TILES_FAILED: "REQUEST_CONTROL_TILES_FAILED",
  SELECT_CONTROL_DRILLDOWN_VALUE: "SELECT_CONTROL_DRILLDOWN_VALUE",
  REQUEST_CONTROL_RESULT_HISTORY: "REQUEST_CONTROL_RESULT_HISTORY",
  RECEIVED_CONTROL_RESULT_HISTORY: "RECEIVED_CONTROL_RESULT_HISTORY",
  REQUEST_CONTROL_RESULT_HISTORY_FAILED: "REQUEST_CONTROL_RESULT_HISTORY_FAILED",
  SELECT_CONTROL_RESULT: "SELECT_CONTROL_RESULT",
  REQUEST_CONTROL_RESULT_DETAILS: "REQUEST_CONTROL_RESULT_DETAILS",
  RECEIVED_CONTROL_RESULT_DETAILS: "RECEIVED_CONTROL_RESULT_DETAILS",
  REQUEST_CONTROL_RESULT_DETAILS_FAILED: "REQUEST_CONTROL_RESULT_DETAILS_FAILED",
  UPDATE_CONTROL_RESULT_DETAILS_STARTED: "UPDATE_CONTROL_RESULT_DETAILS_STARTED",
  UPDATE_CONTROL_RESULT_DETAILS_COMPLETED: "UPDATE_CONTROL_RESULT_DETAILS_COMPLETED",
  UPDATE_CONTROL_RESULT_DETAILS_FAILED: "UPDATE_CONTROL_RESULT_DETAILS_FAILED",
  EDIT_CONTROL_COMMENT: "EDIT_CONTROL_COMMENT",
  CANCEL_CONTROL_COMMENT_EDIT: "CANCEL_CONTROL_COMMENT_EDIT",
  UPDATE_CONTROL_COMMENT_STARTED: "UPDATE_CONTROL_COMMENT_STARTED",
  UPDATE_CONTROL_COMMENT_COMPLETED: "UPDATE_CONTROL_COMMENT_COMPLETED",
  UPDATE_CONTROL_COMMENT_FAILED: "UPDATE_CONTROL_COMMENT_FAILED",
  EDIT_CONTROL_LINKED_ITEM: "EDIT_CONTROL_LINKED_ITEM",
  CANCEL_CONTROL_LINKED_ITEM_EDIT: "CANCEL_CONTROL_LINKED_ITEM_EDIT",
  UPDATE_CONTROL_LINKED_ITEM_STARTED: "UPDATE_CONTROL_LINKED_ITEM_STARTED",
  UPDATE_CONTROL_LINKED_ITEM_COMPLETED: "UPDATE_CONTROL_LINKED_ITEM_COMPLETED",
  UPDATE_CONTROL_LINKED_ITEM_FAILED: "UPDATE_CONTROL_LINKED_ITEM_FAILED",
  CREATE_CONTROL_INCIDENT_STARTED: "CREATE_CONTROL_INCIDENT_STARTED",
  CREATE_CONTROL_INCIDENT_COMPLETED: "CREATE_CONTROL_INCIDENT_COMPLETED",
  CREATE_CONTROL_INCIDENT_FAILED: "CREATE_CONTROL_INCIDENT_FAILED",
  // Config related.
  LOAD_CONFIG_ITEMS_STARTED: "LOAD_CONFIG_ITEMS_STARTED",
  LOAD_CONFIG_ITEMS_COMPLETED: "LOAD_CONFIG_ITEMS_COMPLETED",
  LOAD_CONFIG_ITEMS_FAILED: "LOAD_CONFIG_ITEMS_FAILED",
  CLEAR_CONFIG_ITEMS: "CLEAR_CONFIG_ITEMS",
  LOAD_CONFIG_DEFS_STARTED: "LOAD_CONFIG_DEFS_STARTED",
  LOAD_CONFIG_DEFS_COMPLETED: "LOAD_CONFIG_DEFS_COMPLETED",
  LOAD_CONFIG_ENVIRONMENTS_STARTED: "LOAD_CONFIG_ENVIRONMENTS_STARTED",
  LOAD_CONFIG_ENVIRONMENTS_COMPLETED: "LOAD_CONFIG_ENVIRONMENTS_COMPLETED",
  LOAD_CONFIG_ENVIRONMENTS_FAILED: "LOAD_CONFIG_ENVIRONMENTS_FAILED",
  DUPLICATE_CONFIG_ITEM_STARTED: "DUPLICATE_CONFIG_ITEM_STARTED",
  DUPLICATE_CONFIG_ITEM_COMPLETED: "DUPLICATE_CONFIG_ITEM_COMPLETED",
  DUPLICATE_CONFIG_ITEM_FAILED: "DUPLICATE_CONFIG_ITEM_FAILED",
  PUBLISH_CONFIG_ITEM_STARTED: "PUBLISH_CONFIG_ITEM_STARTED",
  PUBLISH_CONFIG_ITEM_COMPLETED: "PUBLISH_CONFIG_ITEM_COMPLETED",
  PUBLISH_CONFIG_ITEM_FAILED: "PUBLISH_CONFIG_ITEM_FAILED",
  PUBLISH_CONFIG_ITEM_RESET: "PUBLISH_CONFIG_ITEM_RESET",
  LOAD_CONFIG_ITEM_STARTED: "LOAD_CONFIG_ITEM_STARTED",
  LOAD_CONFIG_ITEM_COMPLETED: "LOAD_CONFIG_ITEM_COMPLETED",
  LOAD_CONFIG_ITEM_FAILED: "LOAD_CONFIG_ITEM_FAILED",
  LOAD_CONFIG_ATTRIBUTE_VALUES_STARTED: "LOAD_CONFIG_ATTRIBUTE_VALUES_STARTED",
  LOAD_CONFIG_ATTRIBUTE_VALUES_COMPLETED: "LOAD_CONFIG_ATTRIBUTE_VALUES_COMPLETED",
  LOAD_CONFIG_ATTRIBUTE_VALUES_FAILED: "LOAD_CONFIG_ATTRIBUTE_VALUES_FAILED",
  CREATE_CONFIG_ITEM: "CREATE_CONFIG_ITEM",
  EDIT_CONFIG_ITEM: "EDIT_CONFIG_ITEM",
  UPDATE_CONFIG_ITEM_STARTED: "UPDATE_CONFIG_ITEM_STARTED",
  UPDATE_CONFIG_ITEM_COMPLETED: "UPDATE_CONFIG_ITEM_COMPLETED",
  UPDATE_CONFIG_ITEM_FAILED: "UPDATE_CONFIG_ITEM_FAILED",
  DELETE_CONFIG_ITEM_STARTED: "DELETE_CONFIG_ITEM_STARTED",
  DELETE_CONFIG_ITEM_COMPLETED: "DELETE_CONFIG_ITEM_COMPLETED",
  DELETE_CONFIG_ITEM_FAILED: "DELETE_CONFIG_ITEM_FAILED",
  REVERT_CONFIG_ITEM_STARTED: "REVERT_CONFIG_ITEM_STARTED",
  REVERT_CONFIG_ITEM_COMPLETED: "REVERT_CONFIG_ITEM_COMPLETED",
  REVERT_CONFIG_ITEM_FAILED: "REVERT_CONFIG_ITEM_FAILED",
  LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_STARTED: "LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_STARTED",
  LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_COMPLETED: "LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_COMPLETED",
  LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_FAILED: "LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_FAILED",
  LOAD_TEAM_TILES_STARTED: "LOAD_TEAM_TILES_STARTED",
  LOAD_TEAM_TILES_COMPLETED: "LOAD_TEAM_TILES_COMPLETED",
  LOAD_TEAM_TILES_FAILED: "LOAD_TEAM_TILES_FAILED",
  LOAD_TEAM_REPORT_STARTED: "LOAD_TEAM_REPORT_STARTED",
  LOAD_TEAM_REPORT_COMPLETED: "LOAD_TEAM_REPORT_COMPLETED",
  LOAD_TEAM_REPORT_FAILED: "LOAD_TEAM_REPORT_FAILED",
  LOAD_TEAM_OKR_METRICS_STARTED: "LOAD_TEAM_OKR_METRICS_STARTED",
  LOAD_TEAM_OKR_METRICS_COMPLETED: "LOAD_TEAM_OKR_METRICS_COMPLETED",
  LOAD_TEAM_OKR_METRICS_FAILED: "LOAD_TEAM_OKR_METRICS_FAILED",
  CHANGE_TEAM_SELECTED_KEY: "CHANGE_TEAM_SELECTED_KEY",
  CHANGE_TEAM_SELECTED_DATE: "CHANGE_TEAM_SELECTED_DATE",
  SET_ENVIRONMENTS: "SET_ENVIRONMENTS",
  // Report related.
  GET_REPORT_GROUP_STARTED: "GET_REPORT_GROUP_STARTED",
  GET_REPORT_GROUP_COMPLETED: "GET_REPORT_GROUP_COMPLETED",
  GET_REPORT_GROUP_FAILED: "GET_REPORT_GROUP_FAILED",
  GET_REPORT_STARTED: "GET_REPORT_STARTED",
  GET_REPORT_COMPLETED: "GET_REPORT_COMPLETED",
  GET_REPORT_FAILED: "GET_REPORT_FAILED",
  GET_REPORT_TILE_STARTED: "GET_REPORT_TILE_STARTED",
  GET_REPORT_TILE_COMPLETED: "GET_REPORT_TILE_COMPLETED",
  GET_REPORT_TILE_FAILED: "GET_REPORT_TILE_FAILED",
  SELECT_REPORT: "SELECT_REPORT",
  UPDATE_REPORT_FILTERS: "UPDATE_REPORT_FILTERS",
  CLEAR_REPORT_STATE: "CLEAR_REPORT_STATE",
  DOWNLOAD_REPORT_DATA_TO_EXCEL_STARTED: "DOWNLOAD_REPORT_DATA_TO_EXCEL_STARTED",
  DOWNLOAD_REPORT_DATA_TO_EXCEL_COMPLETED: "DOWNLOAD_REPORT_DATA_TO_EXCEL_COMPLETED",
  DOWNLOAD_REPORT_DATA_TO_EXCEL_FAILED: "DOWNLOAD_REPORT_DATA_TO_EXCEL_FAILED",
  DOWNLOAD_REPORT_DATA_TO_EXCEL_RESET: "DOWNLOAD_REPORT_DATA_TO_EXCEL_RESET",
  LOAD_REPORT_TILE_COLUMN_STARTED: "LOAD_REPORT_TILE_COLUMN_STARTED",
  LOAD_REPORT_TILE_COLUMN_COMPLETED: "LOAD_REPORT_TILE_COLUMN_COMPLETED",
  LOAD_REPORT_TILE_COLUMN_FAILED: "LOAD_REPORT_TILE_COLUMN_FAILED",
  // Graph related.
  SET_SEARCH_ENTITY_TYPE: "SET_SEARCH_ENTITY_TYPE",
  SET_SEARCH_ENTITY_ID: "SET_SEARCH_ENTITY_ID",
  SET_SEARCH_ENTITY_VERSION: "SET_SEARCH_ENTITY_VERSION",
  LOAD_ENTITY_STARTED: "LOAD_ENTITY_STARTED",
  LOAD_ENTITY_COMPLETED: "LOAD_ENTITY_COMPLETED",
  LOAD_ENTITY_FAILED: "LOAD_ENTITY_FAILED",
  LOAD_ENTITY_RELATIONSHIPS_STARTED: "LOAD_ENTITY_RELATIONSHIPS_STARTED",
  LOAD_ENTITY_RELATIONSHIPS_COMPLETED: "LOAD_ENTITY_RELATIONSHIPS_COMPLETED",
  LOAD_ENTITY_RELATIONSHIPS_FAILED: "LOAD_ENTITY_RELATIONSHIPS_FAILED",
  SHOW_ENTITY_DETAILS: "SHOW_ENTITY_DETAILS",
  HIDE_ENTITY_DETAILS: "HIDE_ENTITY_DETAILS",
  LOAD_ENTITY_DETAILS_STARTED: "LOAD_ENTITY_DETAILS_STARTED",
  LOAD_ENTITY_DETAILS_COMPLETED: "LOAD_ENTITY_DETAILS_COMPLETED",
  LOAD_ENTITY_DETAILS_FAILED: "LOAD_ENTITY_DETAILS_FAILED",
  LOAD_FINANCIAL_ENTITIES_STARTED: "LOAD_FINANCIAL_ENTITIES_STARTED",
  LOAD_FINANCIAL_ENTITIES_COMPLETED: "LOAD_FINANCIAL_ENTITIES_COMPLETED",
  LOAD_FINANCIAL_ENTITIES_FAILED: "LOAD_FINANCIAL_ENTITIES_FAILED",
  CHANGE_SEARCH_ENTITY_START_TIME: "CHANGE_SEARCH_ENTITY_START_TIME",
  CHANGE_SEARCH_ENTITY_END_TIME: "CHANGE_SEARCH_ENTITY_END_TIME",
  CHANGE_SEARCH_ENTITY_MONTH: "CHANGE_SEARCH_ENTITY_MONTH",
  CHANGE_SEARCH_ENTITY_SELECTED_KEY: "CHANGE_SEARCH_ENTITY_SELECTED_KEY",
  CHANGE_SEARCH_ENTITY_DATE_TYPE: "CHANGE_SEARCH_ENTITY_DATE_TYPE",
  LOAD_ENTITY_TESTS_STARTED: "LOAD_ENTITY_TESTS_STARTED",
  LOAD_ENTITY_TESTS_COMPLETED: "LOAD_ENTITY_TESTS_COMPLETED",
  LOAD_ENTITY_TESTS_FAILED: "LOAD_ENTITY_TESTS_FAILED",
  BACK_TO_PREVIOUS_ENTITY_SELECTION: "BACK_TO_PREVIOUS_SELECTION",
  SELECT_ENTITY_VIEW: "SELECT_ENTITY_VIEW",
  LOAD_ENTITY_METRICS_STARTED: "LOAD_ENTITY_METRICS_STARTED",
  LOAD_ENTITY_METRICS_COMPLETED: "LOAD_ENTITY_METRICS_COMPLETED",
  LOAD_ENTITY_METRICS_FAILED: "LOAD_ENTITY_METRICS_FAILED",
  LOAD_ENTITY_TRACE_METRICS_STARTED: "LOAD_ENTITY_TRACE_METRICS_STARTED",
  LOAD_ENTITY_TRACE_METRICS_COMPLETED: "LOAD_ENTITY_TRACE_METRICS_COMPLETED",
  LOAD_ENTITY_TRACE_METRICS_FAILED: "LOAD_ENTITY_TRACE_METRICS_FAILED",
  CHANGE_ENTITY_METRIC: "CHANGE_ENTITY_METRIC",
  CHANGE_SUBPANE_SELECTED_KEY: "CHANGE_SUBPANE_SELECTED_KEY",
  CHANGE_ENTITY_METRIC_SELECTION: "CHANGE_ENTITY_METRIC_SELECTION",
  LOAD_ENTITY_TILES_STARTED: "LOAD_ENTITY_TILES_STARTED",
  LOAD_ENTITY_TILES_COMPLETED: "LOAD_ENTITY_TILES_COMPLETED",
  LOAD_ENTITY_TILES_FAILED: "LOAD_ENTITY_TILES_FAILED",
  ADD_SEARCH_FILTER: "ADD_SEARCH_FILTER",
  EDIT_SEARCH_FILTER: "EDIT_SEARCH_FILTER",
  REMOVE_SEARCH_FILTER: "REMOVE_SEARCH_FILTER",
  GROUP_SEARCH_FILTER: "GROUP_SEARCH_FILTER",
  UNGROUP_SEARCH_FILTER: "UNGROUP_SEARCH_FILTER",
  CLEAR_ALL_SEARCH_FILTERS: "CLEAR_ALL_SEARCH_FILTERS",
  RESTORE_SEARCH_FILTERS: "RESTORE_SEARCH_FILTERS",
  CHANGE_SEARCH_VIEW_FILTER: "CHANGE_SEARCH_VIEW_FILTER",
  CHANGE_SEARCH_BATCH_SIZE: "CHANGE_SEARCH_BATCH_SIZE",
  CHANGE_SEARCH_RANDOM_TOP: "CHANGE_SEARCH_RANDOM_TOP",
  // DevOps related.
  LOAD_DEVOPS_VIEW_STARTED: "LOAD_DEVOPS_VIEW_STARTED",
  LOAD_DEVOPS_VIEW_COMPLETED: "LOAD_DEVOPS_VIEW_COMPLETED",
  LOAD_DEVOPS_VIEW_FAILED: "LOAD_DEVOPS_VIEW_FAILED",
  LOAD_DEVOPS_VIEWS_STARTED: "LOAD_DEVOPS_VIEWS_STARTED",
  LOAD_DEVOPS_VIEWS_COMPLETED: "LOAD_DEVOPS_VIEWS_COMPLETED",
  LOAD_DEVOPS_VIEWS_FAILED: "LOAD_DEVOPS_VIEWS_FAILED",
  GENERATE_DEVOPS_WORKITEMS_STARTED: "GENERATE_DEVOPS_WORKITEMS_STARTED",
  GENERATE_DEVOPS_WORKITEMS_COMPLETED: "GENERATE_DEVOPS_WORKITEMS_COMPLETED",
  GENERATE_DEVOPS_WORKITEMS_FAILED: "GENERATE_DEVOPS_WORKITEMS_FAILED",
  DOWNLOAD_EMAIL_FILE_STARTED: "DOWNLOAD_EMAIL_FILE_STARTED",
  DOWNLOAD_EMAIL_FILE_COMPLETED: "DOWNLOAD_EMAIL_FILE_COMPLETED",
  DOWNLOAD_EMAIL_FILE_FAILED: "DOWNLOAD_EMAIL_FILE_FAILED",
  DELETE_DEVOPS_WORKITEM_STARTED: "DELETE_DEVOPS_WORKITEM_STARTED",
  DELETE_DEVOPS_WORKITEM_COMPLETED: "DELETE_DEVOPS_WORKITEM_COMPLETED",
  DELETE_DEVOPS_WORKITEM_FAILED: "DELETE_DEVOPS_WORKITEM_FAILED",
  CHANGE_DEVOPS_SEARCH_TEXT: "CHANGE_DEVOPS_SEARCH_TEXT",
  SELECT_DEVOPS_INLINE_FILTER: "SELECT_DEVOPS_INLINE_FILTER",
};

export const actionCreator = {
  ...thunkActions,
  // =============================================================================
  // Incidents related
  updateDiagramNodePosition: (viewId: string, node: INode) => ({
    type: actionTypes.UPDATE_DIAGRAM_NODE_POSITION,
    viewId,
    node,
  }),
  requestIncidents: () => ({
    type: actionTypes.REQUEST_INCIDENTS,
  }),
  receivedIncidents: (icmData: any) => ({
    type: actionTypes.RECEIVED_INCIDENTS,
    icmData,
  }),
  requestIncidentsFailed: (error: string) => ({
    type: actionTypes.REQUEST_INCIDENTS_FAILED,
    error,
  }),
  selectIncident: (incident: IIncident | number) => ({
    type: actionTypes.SELECT_INCIDENT,
    incident,
  }),
  filterIncidents: (filteredIncidents: IIncident[]) => ({
    type: actionTypes.FILTER_INCIDENTS,
    filteredIncidents,
  }),
  unselectIncident: () => ({
    type: actionTypes.UNSELECT_INCIDENT,
  }),
  requestIncidentDetails: (id: string) => ({
    type: actionTypes.REQUEST_INCIDENT_DETAILS,
    id,
  }),
  receivedIncidentDetails: (id: string, icmData: any) => ({
    type: actionTypes.RECEIVED_INCIDENT_DETAILS,
    id,
    icmData,
  }),
  requestIncidentDetailsFailed: (id: string, error: string) => ({
    type: actionTypes.REQUEST_INCIDENT_DETAILS_FAILED,
    id,
    error,
  }),
  selectIncidentFilter: (filterValue: string) => ({
    type: actionTypes.SELECT_INCIDENT_FILTER,
    filterValue,
  }),
  selectSystemNode: (node: INode) => ({
    type: actionTypes.SELECT_SYSTEM_NODE,
    node,
  }),
  selectSystemLink: (link: ILink) => ({
    type: actionTypes.SELECT_SYSTEM_LINK,
    link,
  }),
  selectIncidentTime: (timeValue: TimeSelectorValue) => ({
    type: actionTypes.SELECT_INCIDENT_TIME,
    timeValue,
  }),
  changeIncidents: (incidents: IIncident[], isSortedDescending: boolean, sortedColumnKey: string) => ({
    type: actionTypes.CHANGE_INCIDENTS,
    incidents,
    isSortedDescending,
    sortedColumnKey,
  }),
  selectIncidentView: (incidentViewId: string) => ({
    type: actionTypes.SELECT_INCIDENT_VIEW,
    incidentViewId,
  }),
  requestIncidentViews: () => ({
    type: actionTypes.REQUEST_INCIDENT_VIEWS,
  }),
  receivedIncidentViews: (incidentViews: IIncidentView[], defaultViewId: string) => ({
    type: actionTypes.RECEIVED_INCIDENT_VIEWS,
    incidentViews,
    defaultViewId,
  }),
  requestIncidentViewsFailed: (error: string) => ({
    type: actionTypes.REQUEST_INCIDENT_VIEWS_FAILED,
    error,
  }),
  requestIncidentTiles: (incidentViewId: string) => ({
    type: actionTypes.REQUEST_INCIDENT_TILES,
    incidentViewId,
  }),
  receivedIncidentTiles: (incidentViewId: string, tileResults: any[]) => ({
    type: actionTypes.RECEIVED_INCIDENT_TILES,
    incidentViewId,
    tileResults,
  }),
  requestIncidentTilesFailed: (error: string) => ({
    type: actionTypes.REQUEST_INCIDENT_TILES_FAILED,
    error,
  }),
  changeIncidentSearchText: (searchText: string) => ({
    type: actionTypes.CHANGE_INCIDENTS_SEARCH_TEXT,
    searchText,
  }),
  downloadIncidentsDataToExcelStarted: () => ({
    type: actionTypes.DOWNLOAD_INCIDENTS_DATA_TO_EXCEL_STARTED,
  }),
  downloadIncidentsDataToExcelCompleted: () => ({
    type: actionTypes.DOWNLOAD_INCIDENTS_DATA_TO_EXCEL_COMPLETED,
  }),
  downloadIncidentsDataToExcelFailed: (error) => ({
    type: actionTypes.DOWNLOAD_INCIDENTS_DATA_TO_EXCEL_FAILED,
    error,
  }),
  loadIncidentStarted: (incidentId: string) => ({
    type: actionTypes.LOAD_INCIDENT_STARTED,
    incidentId,
  }),
  loadIncidentCompleted: (incident: IIncident) => ({
    type: actionTypes.LOAD_INCIDENT_COMPLETED,
    incident,
  }),
  loadIncidentFailed: (error) => ({
    type: actionTypes.LOAD_INCIDENT_FAILED,
    error,
  }),
  loadIncidentOpenAiSummaryStarted: (incidentId: string) => ({
    type: actionTypes.LOAD_INCIDENT_OPENAI_SUMMARY_STARTED,
    incidentId,
  }),
  loadIncidentOpenAiSummaryCompleted: (incidentId: string, summaryText: string) => ({
    type: actionTypes.LOAD_INCIDENT_OPENAI_SUMMARY_COMPLETED,
    incidentId,
    summaryText,
  }),
  loadIncidentOpenAiSummaryFailed: (incidentId: string, error: string) => ({
    type: actionTypes.LOAD_INCIDENT_OPENAI_SUMMARY_FAILED,
    incidentId,
    error,
  }),
  // =============================================================================
  // Controls related
  selectAllControls: () => ({
    type: actionTypes.SELECT_ALL_CONTROLS,
  }),
  selectControl: (id: string) => ({
    type: actionTypes.SELECT_CONTROL,
    id,
  }),
  unselectControl: () => ({
    type: actionTypes.UNSELECT_CONTROL,
  }),
  requestControlResults: () => ({
    type: actionTypes.REQUEST_CONTROL_RESULTS,
  }),
  receivedControlResults: (controlResults: IControlResult[], userInfo: IUserInfo) => ({
    type: actionTypes.RECEIVED_CONTROL_RESULTS,
    controlResults,
    userInfo,
  }),
  requestControlResultsFailed: (error: string) => ({
    type: actionTypes.REQUEST_CONTROL_RESULTS_FAILED,
    error,
  }),
  loadControlResultStarted: (controlId: string) => ({
    type: actionTypes.LOAD_CONTROL_RESULT_STARTED,
    controlId,
  }),
  loadControlResultCompleted: (controlResult: IControlResult) => ({
    type: actionTypes.LOAD_CONTROL_RESULT_COMPLETED,
    controlResult,
  }),
  loadControlResultFailed: (error: string) => ({
    type: actionTypes.LOAD_CONTROL_RESULT_FAILED,
    error,
  }),
  requestControlResultItems: (id: string) => ({
    type: actionTypes.REQUEST_CONTROL_RESULT_ITEMS,
    id,
  }),
  receivedControlResultItems: (controlResultItems: IControlResult[], totalCount) => ({
    type: actionTypes.RECEIVED_CONTROL_RESULT_ITEMS,
    controlResultItems,
    totalCount,
  }),
  requestControlResultItemsFailed: (error: string) => ({
    type: actionTypes.REQUEST_CONTROL_RESULT_ITEMS_FAILED,
    error,
  }),
  selectControlFilter: (filterValue: string, userInfo: IUserInfo) => ({
    type: actionTypes.SELECT_CONTROL_FILTER,
    filterValue,
    userInfo,
  }),
  requestControlViews: (ids: string) => ({
    type: actionTypes.REQUEST_CONTROL_VIEWS,
    ids,
  }),
  receivedControlViews: (controlViews: IControlView[]) => ({
    type: actionTypes.RECEIVED_CONTROL_VIEWS,
    controlViews,
  }),
  requestControlViewsFailed: (error: string) => ({
    type: actionTypes.REQUEST_CONTROL_VIEWS_FAILED,
    error,
  }),
  requestControls: () => ({
    type: actionTypes.REQUEST_CONTROLS,
  }),
  receivedControls: (controls: IControl[]) => ({
    type: actionTypes.RECEIVED_CONTROLS,
    controls,
  }),
  requestControlsFailed: (error: string) => ({
    type: actionTypes.REQUEST_CONTROLS_FAILED,
    error,
  }),
  toggleNextView: () => ({
    type: actionTypes.TOGGLE_NEXT_VIEW,
  }),
  requestEntities: (ids: string) => ({
    type: actionTypes.REQUEST_ENTITIES,
    ids,
  }),
  receivedEntities: (entities: IEntity[]) => ({
    type: actionTypes.RECEIVED_ENTITIES,
    entities,
  }),
  requestEntitiesFailed: (error: string) => ({
    type: actionTypes.REQUEST_ENTITIES_FAILED,
    error,
  }),
  requestControlTiles: (controlId: string) => ({
    type: actionTypes.REQUEST_CONTROL_TILES,
    controlId,
  }),
  receivedControlTiles: (controlId: string, tileResults: any[]) => ({
    type: actionTypes.RECEIVED_CONTROL_TILES,
    controlId,
    tileResults,
  }),
  requestControlTilesFailed: (error: string) => ({
    type: actionTypes.REQUEST_CONTROL_TILES_FAILED,
    error,
  }),
  selectControlDrilldownValue: (selectedDrilldownValue: string) => ({
    type: actionTypes.SELECT_CONTROL_DRILLDOWN_VALUE,
    selectedDrilldownValue,
  }),
  requestControlResultHistory: (controlId: string) => ({
    type: actionTypes.REQUEST_CONTROL_RESULT_HISTORY,
    controlId,
  }),
  receivedControlResultHistory: (controlId: string, controlResults: any[]) => ({
    type: actionTypes.RECEIVED_CONTROL_RESULT_HISTORY,
    controlId,
    controlResults,
  }),
  requestControlResultHistoryFailed: (error: string) => ({
    type: actionTypes.REQUEST_CONTROL_RESULT_HISTORY_FAILED,
    error,
  }),
  selectControlResult: (controlResultId: string) => ({
    type: actionTypes.SELECT_CONTROL_RESULT,
    controlResultId,
  }),
  requestControlResultDetails: (controlId: string, controlResultId: string) => ({
    type: actionTypes.REQUEST_CONTROL_RESULT_DETAILS,
    controlId,
    controlResultId,
  }),
  receivedControlResultDetails: (controlId: string, results: any, userInfo: IUserInfo) => ({
    type: actionTypes.RECEIVED_CONTROL_RESULT_DETAILS,
    controlId,
    results,
    userInfo,
  }),
  requestControlResultDetailsFailed: (error: string, userInfo: IUserInfo) => ({
    type: actionTypes.REQUEST_CONTROL_RESULT_DETAILS_FAILED,
    error,
    userInfo,
  }),
  updateControlResultDetailsStarted: () => ({
    type: actionTypes.UPDATE_CONTROL_RESULT_DETAILS_STARTED,
  }),
  updateControlResultDetailsCompleted: (controlId: string, results: any, userInfo: IUserInfo) => ({
    type: actionTypes.UPDATE_CONTROL_RESULT_DETAILS_COMPLETED,
    controlId,
    results,
    userInfo,
  }),
  updateControlResultDetailsFailed: (error: string) => ({
    type: actionTypes.UPDATE_CONTROL_RESULT_DETAILS_FAILED,
    error,
  }),
  editControlComment: (comment: IComment) => ({
    type: actionTypes.EDIT_CONTROL_COMMENT,
    comment,
  }),
  cancelControlCommentEdit: () => ({
    type: actionTypes.CANCEL_CONTROL_COMMENT_EDIT,
  }),
  updateControlCommentStarted: (comment: IComment) => ({
    type: actionTypes.UPDATE_CONTROL_COMMENT_STARTED,
    comment,
  }),
  updateControlCommentCompleted: (comment: IComment) => ({
    type: actionTypes.UPDATE_CONTROL_COMMENT_COMPLETED,
    comment,
  }),
  updateControlCommentFailed: (error: string) => ({
    type: actionTypes.UPDATE_CONTROL_COMMENT_FAILED,
    error,
  }),
  editControlLinkedItem: (linkedItem: ILinkedItem) => ({
    type: actionTypes.EDIT_CONTROL_LINKED_ITEM,
    linkedItem,
  }),
  cancelControlLinkedItemEdit: () => ({
    type: actionTypes.CANCEL_CONTROL_LINKED_ITEM_EDIT,
  }),
  updateControlLinkedItemStarted: (linkedItem: ILinkedItem) => ({
    type: actionTypes.UPDATE_CONTROL_LINKED_ITEM_STARTED,
    linkedItem,
  }),
  updateControlLinkedItemCompleted: (linkedItem: ILinkedItem) => ({
    type: actionTypes.UPDATE_CONTROL_LINKED_ITEM_COMPLETED,
    linkedItem,
  }),
  updateControlLinkedItemFailed: (error: string) => ({
    type: actionTypes.UPDATE_CONTROL_LINKED_ITEM_FAILED,
    error,
  }),
  createControlIncidentStarted: () => ({
    type: actionTypes.CREATE_CONTROL_INCIDENT_STARTED,
  }),
  createControlIncidentCompleted: (result) => ({
    type: actionTypes.CREATE_CONTROL_INCIDENT_COMPLETED,
    result,
  }),
  createControlIncidentFailed: (error: string) => ({
    type: actionTypes.CREATE_CONTROL_INCIDENT_FAILED,
    error,
  }),
  // Config related
  loadConfigItemsStarted: () => ({
    type: actionTypes.LOAD_CONFIG_ITEMS_STARTED,
  }),
  loadConfigItemsCompleted: (configItemType: ConfigItemType, configItems: any[], doNotUpdateConfigItems?: boolean) => ({
    type: actionTypes.LOAD_CONFIG_ITEMS_COMPLETED,
    configItemType,
    configItems,
    doNotUpdateConfigItems,
  }),
  loadConfigItemsFailed: (error: string) => ({
    type: actionTypes.LOAD_CONFIG_ITEMS_FAILED,
    error,
  }),
  clearConfigItems: () => ({
    type: actionTypes.CLEAR_CONFIG_ITEMS,
  }),
  loadConfigDefsStarted: () => ({
    type: actionTypes.LOAD_CONFIG_DEFS_STARTED,
  }),
  loadConfigDefsCompleted: (configDef: IConfigDefs) => ({
    type: actionTypes.LOAD_CONFIG_DEFS_COMPLETED,
    configDef,
  }),
  loadConfigEnvironmentsStarted: () => ({
    type: actionTypes.LOAD_CONFIG_ENVIRONMENTS_STARTED,
  }),
  loadConfigEnvironmentsCompleted: (configEnvironments: any[]) => ({
    type: actionTypes.LOAD_CONFIG_ENVIRONMENTS_COMPLETED,
    configEnvironments,
  }),
  loadConfigEnvironmentsFailed: (error: string) => ({
    type: actionTypes.LOAD_CONFIG_ENVIRONMENTS_FAILED,
    error,
  }),
  duplicateConfigItemStarted: () => ({
    type: actionTypes.DUPLICATE_CONFIG_ITEM_STARTED,
  }),
  duplicateConfigItemCompleted: (configItem) => ({
    type: actionTypes.DUPLICATE_CONFIG_ITEM_COMPLETED,
    configItem,
  }),
  duplicateConfigItemFailed: (error: string) => ({
    type: actionTypes.DUPLICATE_CONFIG_ITEM_FAILED,
    error,
  }),
  publishConfigItemStarted: (configItemId: string) => ({
    type: actionTypes.PUBLISH_CONFIG_ITEM_STARTED,
    configItemId,
  }),
  publishConfigItemCompleted: (configItemId: string, configItem) => ({
    type: actionTypes.PUBLISH_CONFIG_ITEM_COMPLETED,
    configItemId,
    configItem,
  }),
  publishConfigItemFailed: (error: string) => ({
    type: actionTypes.PUBLISH_CONFIG_ITEM_FAILED,
    error,
  }),
  publishConfigItemReset: () => ({
    type: actionTypes.PUBLISH_CONFIG_ITEM_RESET,
  }),
  loadConfigItemStarted: (configItemId: string) => ({
    type: actionTypes.LOAD_CONFIG_ITEM_STARTED,
    configItemId,
  }),
  loadConfigItemCompleted: (
    configItem,
    configItemType?: ConfigItemType,
    doNotUpdateConfigValue?: boolean,
    environment?: string
  ) => ({
    type: actionTypes.LOAD_CONFIG_ITEM_COMPLETED,
    configItem,
    configItemType,
    doNotUpdateConfigValue,
    environment,
  }),
  loadConfigItemFailed: (error: string) => ({
    type: actionTypes.LOAD_CONFIG_ITEM_FAILED,
    error,
  }),
  loadConfigAttributeValuesStarted: (id: string) => ({
    type: actionTypes.LOAD_CONFIG_ATTRIBUTE_VALUES_STARTED,
  }),
  loadConfigAttributeValuesCompleted: (id: string, configValues) => ({
    type: actionTypes.LOAD_CONFIG_ATTRIBUTE_VALUES_COMPLETED,
    id,
    configValues,
  }),
  loadConfigAttributeValuesFailed: (error: string) => ({
    type: actionTypes.LOAD_CONFIG_ATTRIBUTE_VALUES_FAILED,
    error,
  }),
  createConfigItem: (defaultItem, userId: string) => ({
    type: actionTypes.CREATE_CONFIG_ITEM,
    defaultItem,
    userId,
  }),
  editConfigItem: (newValue: string, environment?: string) => ({
    type: actionTypes.EDIT_CONFIG_ITEM,
    newValue,
    environment,
  }),
  updateConfigItemStarted: () => ({
    type: actionTypes.UPDATE_CONFIG_ITEM_STARTED,
  }),
  updateConfigItemCompleted: (configItemType: ConfigItemType, configItem, environment?) => ({
    type: actionTypes.UPDATE_CONFIG_ITEM_COMPLETED,
    configItemType,
    configItem,
    environment,
  }),
  updateConfigItemFailed: (error: string) => ({
    type: actionTypes.UPDATE_CONFIG_ITEM_FAILED,
    error,
  }),
  deleteConfigItemStarted: () => ({
    type: actionTypes.DELETE_CONFIG_ITEM_STARTED,
  }),
  deleteConfigItemCompleted: () => ({
    type: actionTypes.DELETE_CONFIG_ITEM_COMPLETED,
  }),
  deleteConfigItemFailed: (error: string) => ({
    type: actionTypes.DELETE_CONFIG_ITEM_FAILED,
    error,
  }),
  revertConfigItemStarted: (configItemId: string) => ({
    type: actionTypes.REVERT_CONFIG_ITEM_STARTED,
    configItemId,
  }),
  revertConfigItemCompleted: (configItemId: string, configItem) => ({
    type: actionTypes.REVERT_CONFIG_ITEM_COMPLETED,
    configItemId,
    configItem,
  }),
  revertConfigItemFailed: (error: string) => ({
    type: actionTypes.REVERT_CONFIG_ITEM_FAILED,
    error,
  }),
  loadFinancialEntityJournalItemsStarted: (financialRecordType: string, numberToSelect: number) => ({
    type: actionTypes.LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_STARTED,
    financialRecordType,
    numberToSelect,
  }),
  loadFinancialEntityJournalItemsCompleted: (financialRecordType: string, journalItems, uid: string) => ({
    type: actionTypes.LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_COMPLETED,
    financialRecordType,
    journalItems,
    uid,
  }),
  loadFinancialEntityJournalItemsFailed: (error: string) => ({
    type: actionTypes.LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_FAILED,
    error,
  }),
  loadTeamTilesStarted: (teamId: string) => ({
    type: actionTypes.LOAD_TEAM_TILES_STARTED,
    teamId,
  }),
  loadTeamTilesCompleted: (teamTiles) => ({
    type: actionTypes.LOAD_TEAM_TILES_COMPLETED,
    teamTiles,
  }),
  loadTeamTilesFailed: (error: string) => ({
    type: actionTypes.LOAD_TEAM_TILES_FAILED,
    error,
  }),
  loadTeamReportStarted: (teamId: string, reportId: string) => ({
    type: actionTypes.LOAD_TEAM_REPORT_STARTED,
    teamId,
    reportId,
  }),
  loadTeamReportCompleted: (teamId, report) => ({
    type: actionTypes.LOAD_TEAM_REPORT_COMPLETED,
    teamId,
    report,
  }),
  loadTeamReportFailed: (error: string) => ({
    type: actionTypes.LOAD_TEAM_REPORT_FAILED,
    error,
  }),
  loadTeamOkrMetricsStarted: (teamId: string) => ({
    type: actionTypes.LOAD_TEAM_OKR_METRICS_STARTED,
    teamId,
  }),
  loadTeamOkrMetricsCompleted: (okrMetrics: IOkrMetric[]) => ({
    type: actionTypes.LOAD_TEAM_OKR_METRICS_COMPLETED,
    okrMetrics,
  }),
  loadTeamOkrMetricsFailed: (error: string) => ({
    type: actionTypes.LOAD_TEAM_OKR_METRICS_FAILED,
    error,
  }),
  changeTeamSelectedKey: (teamId: string) => ({
    type: actionTypes.CHANGE_TEAM_SELECTED_KEY,
    teamId,
  }),
  changeTeamSelectedDate: (date: string) => ({
    type: actionTypes.CHANGE_TEAM_SELECTED_DATE,
    date,
  }),
  setEnvironments: (environments: IEnvironment[]) => ({
    type: actionTypes.SET_ENVIRONMENTS,
    environments,
  }),
  // Report related actions
  getReportGroupStarted: () => ({
    type: actionTypes.GET_REPORT_GROUP_STARTED,
  }),
  getReportGroupCompleted: (reportGroup: IReportGroup) => ({
    type: actionTypes.GET_REPORT_GROUP_COMPLETED,
    reportGroup,
  }),
  getReportGroupFailed: (error: string) => ({
    type: actionTypes.GET_REPORT_GROUP_FAILED,
    error,
  }),
  getReportStarted: (reportId: string) => ({
    type: actionTypes.GET_REPORT_STARTED,
    reportId,
  }),
  getReportCompleted: (report: IReport) => ({
    type: actionTypes.GET_REPORT_COMPLETED,
    report,
  }),
  getReportFailed: (error: string) => ({
    type: actionTypes.GET_REPORT_FAILED,
    error,
  }),
  getReportTileStarted: (reportId: string, tileIndex: number) => ({
    type: actionTypes.GET_REPORT_TILE_STARTED,
    reportId,
    tileIndex,
  }),
  getReportTileCompleted: (reportId: string, tileIndex: number, tile: ITile) => ({
    type: actionTypes.GET_REPORT_TILE_COMPLETED,
    reportId,
    tileIndex,
    tile,
  }),
  getReportTileFailed: (error: string) => ({
    type: actionTypes.GET_REPORT_TILE_FAILED,
    error,
  }),
  selectReport: (reportId: string) => ({
    type: actionTypes.SELECT_REPORT,
    reportId,
  }),
  updateReportFilters: (filters: IFilterItem[]) => ({
    type: actionTypes.UPDATE_REPORT_FILTERS,
    filters,
  }),
  clearReportState: (reportId: string) => ({
    type: actionTypes.CLEAR_REPORT_STATE,
    reportId,
  }),
  downloadReportDataToExcelStarted: () => ({
    type: actionTypes.DOWNLOAD_REPORT_DATA_TO_EXCEL_STARTED,
  }),
  downloadReportDataToExcelCompleted: () => ({
    type: actionTypes.DOWNLOAD_REPORT_DATA_TO_EXCEL_COMPLETED,
  }),
  downloadReportDataToExcelFailed: (error) => ({
    type: actionTypes.DOWNLOAD_REPORT_DATA_TO_EXCEL_FAILED,
    error,
  }),
  downloadReportDataToExcelReset: () => ({
    type: actionTypes.DOWNLOAD_REPORT_DATA_TO_EXCEL_RESET,
  }),
  loadReportTileColumnStarted: () => ({
    type: actionTypes.LOAD_REPORT_TILE_COLUMN_STARTED,
  }),
  loadReportTileColumnCompleted: () => ({
    type: actionTypes.LOAD_REPORT_TILE_COLUMN_COMPLETED,
  }),
  loadReportTileColumnFailed: (error: string) => ({
    type: actionTypes.LOAD_REPORT_TILE_COLUMN_FAILED,
    error,
  }),
  // Graph related actions
  setSearchEntityType: (entityType: string) => ({
    type: actionTypes.SET_SEARCH_ENTITY_TYPE,
    entityType,
  }),
  setSearchEntityId: (entityId: string) => ({
    type: actionTypes.SET_SEARCH_ENTITY_ID,
    entityId,
  }),
  setSearchEntityVersion: (entityVersion: string) => ({
    type: actionTypes.SET_SEARCH_ENTITY_VERSION,
    entityVersion,
  }),
  loadEntityStarted: (entityType: string, entityId: string, entityVersion: string) => ({
    type: actionTypes.LOAD_ENTITY_STARTED,
    entityType,
    entityId,
    entityVersion,
  }),
  loadEntityCompleted: (result, entityType) => ({
    type: actionTypes.LOAD_ENTITY_COMPLETED,
    result,
    entityType,
  }),
  loadEntityFailed: (error) => ({
    type: actionTypes.LOAD_ENTITY_FAILED,
    error,
  }),
  loadEntityRelationshipsStarted: (entityType: string, entityId: string, entityVersion: string) => ({
    type: actionTypes.LOAD_ENTITY_RELATIONSHIPS_STARTED,
    entityType,
    entityId,
    entityVersion,
  }),
  loadEntityRelationshipsCompleted: (result) => ({
    type: actionTypes.LOAD_ENTITY_RELATIONSHIPS_COMPLETED,
    result,
  }),
  loadEntityRelationshipsFailed: (error) => ({
    type: actionTypes.LOAD_ENTITY_RELATIONSHIPS_FAILED,
    error,
  }),
  showEntityDetails: () => ({
    type: actionTypes.SHOW_ENTITY_DETAILS,
  }),
  hideEntityDetails: () => ({
    type: actionTypes.HIDE_ENTITY_DETAILS,
  }),
  loadEntityDetailsStarted: () => ({
    type: actionTypes.LOAD_ENTITY_DETAILS_STARTED,
  }),
  loadEntityDetailsCompleted: (result) => ({
    type: actionTypes.LOAD_ENTITY_DETAILS_COMPLETED,
    result,
  }),
  loadEntityDetailsFailed: (error) => ({
    type: actionTypes.LOAD_ENTITY_DETAILS_FAILED,
    error,
  }),
  loadFinancialEntitiesStarted: (startTime: Date, endTime: Date) => ({
    type: actionTypes.LOAD_FINANCIAL_ENTITIES_STARTED,
    startTime,
    endTime,
  }),
  loadFinancialEntitiesCompleted: (result) => ({
    type: actionTypes.LOAD_FINANCIAL_ENTITIES_COMPLETED,
    result,
  }),
  loadFinancialEntitiesFailed: (error) => ({
    type: actionTypes.LOAD_FINANCIAL_ENTITIES_FAILED,
    error,
  }),
  loadEntityMetricsStarted: (startTime: Date, endTime: Date) => ({
    type: actionTypes.LOAD_ENTITY_METRICS_STARTED,
    startTime,
    endTime,
  }),
  loadEntityMetricsCompleted: (metrics, executionTimestamp, queryTime) => ({
    type: actionTypes.LOAD_ENTITY_METRICS_COMPLETED,
    metrics,
    executionTimestamp,
    queryTime,
  }),
  loadEntityMetricsFailed: (error) => ({
    type: actionTypes.LOAD_ENTITY_METRICS_FAILED,
    error,
  }),
  loadEntityTraceMetricsStarted: () => ({
    type: actionTypes.LOAD_ENTITY_TRACE_METRICS_STARTED,
  }),
  loadEntityTraceMetricsCompleted: (metrics) => ({
    type: actionTypes.LOAD_ENTITY_TRACE_METRICS_COMPLETED,
    metrics,
  }),
  loadEntityTraceMetricsFailed: (error) => ({
    type: actionTypes.LOAD_ENTITY_TRACE_METRICS_FAILED,
    error,
  }),
  loadEntityTilesStarted: () => ({
    type: actionTypes.LOAD_ENTITY_TILES_STARTED,
  }),
  loadEntityTilesCompleted: (result) => ({
    type: actionTypes.LOAD_ENTITY_TILES_COMPLETED,
    result,
  }),
  loadEntityTilesFailed: (error) => ({
    type: actionTypes.LOAD_ENTITY_TILES_FAILED,
    error,
  }),
  changeSearchEntityStartTime: (startTime: Date) => ({
    type: actionTypes.CHANGE_SEARCH_ENTITY_START_TIME,
    startTime,
  }),
  changeSearchEntityEndTime: (endTime: Date) => ({
    type: actionTypes.CHANGE_SEARCH_ENTITY_END_TIME,
    endTime,
  }),
  changeSearchEntityMonth: (startTime: Date) => ({
    type: actionTypes.CHANGE_SEARCH_ENTITY_MONTH,
    startTime,
  }),
  changeSearchEntitySelectedKey: (key) => ({
    type: actionTypes.CHANGE_SEARCH_ENTITY_SELECTED_KEY,
    key,
  }),
  changeSearchEntityDateType: (dateType: string) => ({
    type: actionTypes.CHANGE_SEARCH_ENTITY_DATE_TYPE,
    dateType,
  }),
  loadEntityTestsStarted: () => ({
    type: actionTypes.LOAD_ENTITY_TESTS_STARTED,
  }),
  loadEntityTestsCompleted: (tests) => ({
    type: actionTypes.LOAD_ENTITY_TESTS_COMPLETED,
    tests,
  }),
  loadEntityTestsFailed: (error) => ({
    type: actionTypes.LOAD_ENTITY_TESTS_FAILED,
    error,
  }),
  backToPreviousEntitySelection: () => ({
    type: actionTypes.BACK_TO_PREVIOUS_ENTITY_SELECTION,
  }),
  selectEntityView: (selectedId: string) => ({
    type: actionTypes.SELECT_ENTITY_VIEW,
    selectedId,
  }),
  changeEntityMetric: (entityType: string, metricId: string) => ({
    type: actionTypes.CHANGE_ENTITY_METRIC,
    entityType,
    metricId,
  }),
  changeSubPaneSelectedKey: (selectedKey: string) => ({
    type: actionTypes.CHANGE_SUBPANE_SELECTED_KEY,
    selectedKey,
  }),
  changeEntityMetricSelection: (metricId: string) => ({
    type: actionTypes.CHANGE_ENTITY_METRIC_SELECTION,
    metricId,
  }),
  addSearchFilter: (searchFilter: ISearchFilter) => ({
    type: actionTypes.ADD_SEARCH_FILTER,
    searchFilter,
  }),
  editSearchFilter: (searchFilter: ISearchFilter, selectedFilterIndex: number) => ({
    type: actionTypes.EDIT_SEARCH_FILTER,
    searchFilter,
    selectedFilterIndex,
  }),
  removeSearchFilter: (selectedFilterIndexes: number[]) => ({
    type: actionTypes.REMOVE_SEARCH_FILTER,
    selectedFilterIndexes,
  }),
  groupSearchFilter: (selectedFilterIndexes: number[]) => ({
    type: actionTypes.GROUP_SEARCH_FILTER,
    selectedFilterIndexes,
  }),
  ungroupSearchFilter: (selectedFilterIndexes: number[]) => ({
    type: actionTypes.UNGROUP_SEARCH_FILTER,
    selectedFilterIndexes,
  }),
  clearAllSearchFilters: () => ({
    type: actionTypes.CLEAR_ALL_SEARCH_FILTERS,
  }),
  restoreSearchFilters: () => ({
    type: actionTypes.RESTORE_SEARCH_FILTERS,
  }),
  changeSearchViewFilter: (viewField: IVisualProps, value) => ({
    type: actionTypes.CHANGE_SEARCH_VIEW_FILTER,
    viewField,
    value,
  }),
  changeSearchBatchSize: (size: number) => ({
    type: actionTypes.CHANGE_SEARCH_BATCH_SIZE,
    size,
  }),
  changeSearchRandomTop: (randomTop: boolean) => ({
    type: actionTypes.CHANGE_SEARCH_RANDOM_TOP,
    randomTop,
  }),
  // DevOps related actions
  loadDevOpsViewStarted: (devOpsViewId) => ({
    type: actionTypes.LOAD_DEVOPS_VIEW_STARTED,
    devOpsViewId,
  }),
  loadDevOpsViewCompleted: (result) => ({
    type: actionTypes.LOAD_DEVOPS_VIEW_COMPLETED,
    result,
  }),
  loadDevOpsViewFailed: (error) => ({
    type: actionTypes.LOAD_DEVOPS_VIEW_FAILED,
    error,
  }),
  loadDevOpsViewsStarted: () => ({
    type: actionTypes.LOAD_DEVOPS_VIEWS_STARTED,
  }),
  loadDevOpsViewsCompleted: (devOpsViews) => ({
    type: actionTypes.LOAD_DEVOPS_VIEWS_COMPLETED,
    devOpsViews,
  }),
  loadDevOpsViewsFailed: (error) => ({
    type: actionTypes.LOAD_DEVOPS_VIEWS_FAILED,
    error,
  }),
  generateDevOpsWorkItemsStarted: () => ({
    type: actionTypes.GENERATE_DEVOPS_WORKITEMS_STARTED,
  }),
  generateDevOpsWorkItemsCompleted: (result) => ({
    type: actionTypes.GENERATE_DEVOPS_WORKITEMS_COMPLETED,
    result,
  }),
  generateDevOpsWorkItemsFailed: (error) => ({
    type: actionTypes.GENERATE_DEVOPS_WORKITEMS_FAILED,
    error,
  }),
  downloadEmailFileStarted: () => ({
    type: actionTypes.DOWNLOAD_EMAIL_FILE_STARTED,
  }),
  downloadEmailFileCompleted: () => ({
    type: actionTypes.DOWNLOAD_EMAIL_FILE_COMPLETED,
  }),
  downloadEmailFileFailed: (error) => ({
    type: actionTypes.DOWNLOAD_EMAIL_FILE_FAILED,
    error,
  }),
  deleteDevOpsWorkItemStarted: () => ({
    type: actionTypes.DELETE_DEVOPS_WORKITEM_STARTED,
  }),
  deleteDevOpsWorkItemCompleted: () => ({
    type: actionTypes.DELETE_DEVOPS_WORKITEM_COMPLETED,
  }),
  deleteDevOpsWorkItemFailed: (error) => ({
    type: actionTypes.DELETE_DEVOPS_WORKITEM_FAILED,
    error,
  }),
  changeDevOpsSearchText: (searchText: string) => ({
    type: actionTypes.CHANGE_DEVOPS_SEARCH_TEXT,
    searchText,
  }),
  selectDevOpsInlineFilter: (value: string) => ({
    type: actionTypes.SELECT_DEVOPS_INLINE_FILTER,
    value,
  }),
};

const { startDate, endDate } = getStartEndDates(searchDateType.thisMonth);

export const initialState: IAppModuleState = {
  // Incidents related
  loading_incidents: false,
  loading_incident_details: false,
  loading_incident_tiles: false,
  loading_incident_views: false,
  incidents: null,
  filtered_incidents: null,
  filtered_incident_ids: [],
  selected_incident_id: null,
  selected_incident_index: -1,
  incident_filters: [],
  selected_incident_filters: [],
  selected_incident_time: TimeSelectorValue.sixMonths,
  incidents_sorted_descending: true,
  incidents_sorted_column_key: "createDate",
  selected_incident_view_id: null,
  incident_views: null,
  incident_view_search_text: "",
  // Controls related
  control_report: null,
  selected_control_views: null,
  control_view_filters: null,
  filtered_control_ids: [],
  selected_control_view_id: null,
  selected_control_node: null,
  selected_control_link: null,
  selected_control: null,
  selected_control_index: -1,
  control_results: null,
  control_result_items: null,
  loading_control_result_items: false,
  loading_control_results: false,
  control_views: {},
  loading_control_view: false,
  controls: null,
  is_next_view_collapsed: false,
  control_entities: {},
  loading_control_tiles: false,
  loading_control_result_history: false,
  selected_control_result_id: null,
  loading_controls: false,
  saving_control_details: false,
  editing_control_comment: null,
  saving_control_comment: false,
  editing_control_linked_item: null,
  saving_control_linked_item: false,
  icm_connectors: [],
  // Config related
  loading_config: false,
  loading_config_items: false,
  config_items: null,
  loading_config_defs_items: false,
  loading_config_environments: false,
  config_environments: null,
  config_attribute_values: {},
  editing_config_value_original: null,
  editing_config_value: null,
  editing_config_values: {},
  editing_config_original_values: {},
  saving_config: false,
  saving_config_success: false,
  publishing_config: false,
  publishing_config_success: false,
  team_selected_date: getFirstOfMonthISODate(-1),
  // Report related
  loading_report_group: false,
  selected_report_group: null,
  reports: {},
  report_loading: false,
  report_error: null,
  selected_report_id: null,
  selected_report_filters: null,
  report_excel_downloading: false,
  // Graph related
  entity_types: [],
  search_entity_type: null,
  search_entity_id: null,
  search_entity_start_time: startDate,
  search_entity_end_time: endDate,
  search_entity_date_type: searchDateType.thisMonth,
  loading_entity_relationships: false,
  selected_entity: null,
  no_entity_found: false,
  no_entity_trace_found: false,
  entity_selection_history: [],
  entity_views: null,
  entity_metrics_for_graph: [],
  selected_entity_view_id: localStorage[selectedEntityViewIdStateName],
  search_batch_size: 10000,
  // DevOps related
  devops_items: [],
  devops_views: [],
  selected_devops_view: null,
  loading_devops_items: false,
  loading_devops_views: false,
  selected_devops_view_id: null,
  generating_devops_workitems: false,
  downloading_email_file: false,
  selected_devops_inline_filters: [],
  // Misc
  errors: {},
};

const reducer = (state: IAppModuleState = initialState, action: IAction): IAppModuleState => {
  //console.log(action.type);
  switch (action.type) {
    // ======================================================================
    // Incidents related actions.
    case actionTypes.REQUEST_INCIDENTS: {
      return {
        ...state,
        loading_incidents: true,
        incidents: [],
        filtered_incidents: [],
        filtered_incident_ids: [],
        errors: {
          ...state.errors,
          [errorType.incidents]: undefined,
        },
      };
    }
    case actionTypes.RECEIVED_INCIDENTS: {
      let incidents = getIncidents(action.icmData),
        incidentFilters = getIncidentsFilters(incidents, state.selected_incident_view),
        filteredIds = getFilteredIncidentIds(
          incidents,
          incidentFilters,
          state.selected_incident_filters,
          null,
          null,
          state.selected_incident_view
        );

      return {
        ...state,
        incidents,
        filtered_incidents: state.filtered_incidents?.length ? state.filtered_incidents : incidents,
        incident_filters: incidentFilters,
        filtered_incident_ids: filteredIds,
        loading_incidents: false,
        incident_view_search_text: "",
      };
    }
    case actionTypes.FILTER_INCIDENTS: {
      let filteredIncidents = action.filteredIncidents?.length
        ? action.filteredIncidents
        : getIncidents(action.icmData);

      return {
        ...state,
        filtered_incidents: filteredIncidents,
      };
    }
    case actionTypes.REQUEST_INCIDENTS_FAILED: {
      return {
        ...state,
        loading_incidents: false,
        errors: {
          ...state.errors,
          [errorType.incidents]: action.error,
        },
      };
    }
    case actionTypes.SELECT_INCIDENT: {
      let isIncidentNaN = isNaN(action.incident),
        incidents = state.filtered_incidents;

      if (!incidents || !incidents.length || (isIncidentNaN && (!action.incident || !action.incident.incidentId))) {
        return state;
      }

      let incidentIndex = isIncidentNaN
        ? incidents.findIndex((i) => i.incidentId === action.incident.incidentId)
        : action.incident;
      let incidentId = isIncidentNaN ? action.incident.incidentId : incidents[action.incident].incidentId;

      return {
        ...state,
        selected_incident_id: incidentId,
        selected_incident_index: incidentIndex,
      };
    }
    case actionTypes.UNSELECT_INCIDENT: {
      return {
        ...state,
        selected_incident_id: null,
        selected_incident_index: -1,
      };
    }
    case actionTypes.REQUEST_INCIDENT_DETAILS: {
      return {
        ...state,
        loading_incident_details: true,
        errors: {
          ...state.errors,
          [errorType.incidentDetails]: undefined,
        },
      };
    }
    case actionTypes.RECEIVED_INCIDENT_DETAILS: {
      let { incidentDetails, ...rest } = action.icmData;
      let updatedIncidents =
        state.incidents &&
        state.incidents.map((incident) => {
          return incident.incidentId === action.id ? { ...incident, ...incidentDetails, ...rest } : incident;
        });

      let updatedFilteredIncidents =
        state.filtered_incidents &&
        state.filtered_incidents.map((incident) => {
          return incident.incidentId === action.id ? { ...incident, ...incidentDetails, ...rest } : incident;
        });

      return {
        ...state,
        loading_incident_details: false,
        incidents: updatedIncidents,
        filtered_incidents: updatedFilteredIncidents,
      };
    }
    case actionTypes.REQUEST_INCIDENT_DETAILS_FAILED: {
      return {
        ...state,
        loading_incident_details: false,
        errors: {
          ...state.errors,
          [errorType.incidentDetails]: action.error,
        },
      };
    }
    case actionTypes.SELECT_INCIDENT_FILTER: {
      let selectedFilters = state.selected_incident_filters.slice(),
        filterIndex = selectedFilters.indexOf(action.filterValue);

      filterIndex >= 0 ? selectedFilters.splice(filterIndex, 1) : selectedFilters.push(action.filterValue);

      let columnNames = state.incidents_default_columns?.map((column) => column.fieldName),
        filteredIds = getFilteredIncidentIds(
          state.incidents,
          state.incident_filters,
          selectedFilters,
          state.incident_view_search_text,
          columnNames,
          state.selected_incident_view
        );

      let newState: IAppModuleState = {
        ...state,
        selected_incident_filters: selectedFilters,
        filtered_incident_ids: filteredIds,
      };

      return reducer(newState, actionCreator.unselectIncident());
    }
    case actionTypes.SELECT_INCIDENT_TIME: {
      return {
        ...state,
        selected_incident_time: action.timeValue,
      };
    }
    case actionTypes.CHANGE_INCIDENTS: {
      return {
        ...state,
        incidents: action.incidents,
        incidents_sorted_descending: action.isSortedDescending,
        incidents_sorted_column_key: action.sortedColumnKey,
      };
    }
    case actionTypes.SELECT_INCIDENT_VIEW: {
      let selectedView = state.incident_views.find((view) => view.id === action.incidentViewId),
        selectedFilters = selectedView?.defaultFilters?.map((filter) => filter.toLowerCase()) || [],
        incidentFilters = selectedView ? state.incident_filters : [],
        filteredIds = getFilteredIncidentIds(
          state.incidents,
          state.incident_filters,
          selectedFilters,
          null,
          null,
          state.selected_incident_view
        );

      const { sortedColumnKey, sortedDescending } = getIncidentViewSortSettings(selectedView);

      return {
        ...state,
        selected_incident_view_id: action.incidentViewId,
        selected_incident_view: selectedView,
        incidents_sorted_column_key: sortedColumnKey,
        incidents_sorted_descending: sortedDescending,
        selected_incident_filters: selectedFilters,
        filtered_incident_ids: filteredIds,
        incident_filters: incidentFilters,
        incident_view_search_text: "",
        incidents: selectedView ? state.incidents : [],
      };
    }
    case actionTypes.REQUEST_INCIDENT_VIEWS: {
      return {
        ...state,
        loading_incident_views: true,
        errors: {
          ...state.errors,
          [errorType.incidents]: undefined,
        },
      };
    }
    case actionTypes.RECEIVED_INCIDENT_VIEWS: {
      const incidentViews = action.incidentViews.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)),
        selectedView = incidentViews.find((view) => view.id === action.defaultViewId),
        selectedFilters = selectedView?.defaultFilters?.map((filter) => filter.toLowerCase()) || [];

      const { sortedColumnKey, sortedDescending } = getIncidentViewSortSettings(selectedView);

      return {
        ...state,
        loading_incident_views: false,
        incident_views: incidentViews,
        selected_incident_view_id: action.defaultViewId,
        selected_incident_view: selectedView,
        selected_incident_filters: selectedFilters,
        incidents_sorted_descending: sortedDescending,
        incidents_sorted_column_key: sortedColumnKey,
      };
    }
    case actionTypes.REQUEST_INCIDENT_VIEWS_FAILED: {
      return {
        ...state,
        loading_incident_views: false,
        errors: {
          ...state.errors,
          [errorType.incidents]: action.error,
        },
      };
    }
    case actionTypes.REQUEST_INCIDENT_TILES: {
      return {
        ...state,
        loading_incident_tiles: true,
      };
    }
    case actionTypes.RECEIVED_INCIDENT_TILES: {
      let incidentViews =
        state.incident_views &&
        state.incident_views.map((view) => {
          if (view.id === state.selected_incident_view_id) {
            return {
              ...view,
              tileResults: action.tileResults,
            };
          } else {
            return view;
          }
        });

      return {
        ...state,
        loading_incident_tiles: false,
        incident_views: incidentViews,
      };
    }
    case actionTypes.REQUEST_INCIDENT_TILES_FAILED: {
      return {
        ...state,
        loading_incident_tiles: false,
      };
    }
    case actionTypes.CHANGE_INCIDENTS_SEARCH_TEXT: {
      let columnNames = state.incidents_default_columns?.map((column) => column.fieldName),
        filteredIds = getFilteredIncidentIds(
          state.incidents,
          state.incident_filters,
          state.selected_incident_filters,
          action.searchText,
          columnNames,
          state.selected_incident_view
        );

      return {
        ...state,
        filtered_incident_ids: filteredIds,
        incident_view_search_text: action.searchText,
      };
    }
    case actionTypes.LOAD_INCIDENT_STARTED: {
      return {
        ...state,
        selected_incident_id: action.incidentId,
        selected_incident_view_id: "",
        selected_incident_view: undefined,
        loading_incident: true,
        errors: {
          ...state.errors,
          [errorType.incidentDetails]: undefined,
        },
      };
    }
    case actionTypes.LOAD_INCIDENT_COMPLETED: {
      return {
        ...state,
        loading_incident: false,
        filtered_incident_ids: [action.incident.incidentId],
        incidents: [action.incident],
      };
    }
    case actionTypes.LOAD_INCIDENT_FAILED: {
      return {
        ...state,
        loading_incident: false,
        filtered_incident_ids: [],
        incidents: [],
        errors: {
          ...state.errors,
          [errorType.incidentDetails]: action.error,
        },
      };
    }
    case actionTypes.LOAD_INCIDENT_OPENAI_SUMMARY_STARTED: {
      let targetIncidents = state.filtered_incidents.slice(),
        targetIncident = targetIncidents?.find((incident) => Number(incident.incidentId) === Number(action.incidentId));

      if (!targetIncidents || !targetIncident || targetIncident["loadingOpenAiSummary"]) {
        return state;
      }

      targetIncident["loadingOpenAiSummary"] = true;

      return {
        ...state,
        filtered_incidents: targetIncidents,
        errors: {
          ...state.errors,
          [errorType.incidentOpenAiSummary]: undefined,
        },
      };
    }
    case actionTypes.LOAD_INCIDENT_OPENAI_SUMMARY_COMPLETED: {
      let targetIncidents = state.filtered_incidents.slice(),
        targetIncident = targetIncidents?.find((incident) => Number(incident.incidentId) === Number(action.incidentId));

      if (!targetIncidents || !targetIncident) {
        return state;
      }

      targetIncident["loadingOpenAiSummary"] = false;
      targetIncident["openAiSummary"] = action.summaryText;

      return {
        ...state,
        filtered_incidents: targetIncidents,
      };
    }
    case actionTypes.LOAD_INCIDENT_OPENAI_SUMMARY_FAILED: {
      let targetIncidents = state.filtered_incidents.slice(),
        targetIncident = targetIncidents?.find((incident) => Number(incident.incidentId) === Number(action.incidentId));

      if (!targetIncidents && !targetIncident) {
        targetIncident["openAiSummary"] = action.error;
        targetIncident["loadingOpenAiSummary"] = false;
      }

      return {
        ...state,
        filtered_incidents: targetIncidents,
        errors: {
          ...state.errors,
          [errorType.incidentOpenAiSummary]: action.error,
        },
      };
    }
    // ======================================================================
    // Control related actions.
    case actionTypes.UPDATE_DIAGRAM_NODE_POSITION: {
      let selectedControlViews = state.selected_control_views;

      selectedControlViews =
        selectedControlViews &&
        selectedControlViews.map((controlView) => {
          if (controlView.id === action.viewId) {
            return {
              ...controlView,
              nodes: controlView.nodes.map((node) => (node.id === action.node.id ? action.node : node)),
            };
          } else {
            return controlView;
          }
        });

      return {
        ...state,
        selected_control_views: selectedControlViews,
      };
    }
    case actionTypes.SELECT_SYSTEM_NODE: {
      let currentNodeId = state.selected_control_node && state.selected_control_node.id,
        selectedNode = action.node.id === currentNodeId ? null : action.node;
      return {
        ...state,
        selected_control_node: selectedNode,
        selected_control_link: null,
        selected_control: null,
        control_report: getControlReportByNode(state.control_results, selectedNode),
      };
    }
    case actionTypes.SELECT_SYSTEM_LINK: {
      let currentLinkId = state.selected_control_link && state.selected_control_link.id,
        selectedLink = action.link.id === currentLinkId ? null : action.link;
      return {
        ...state,
        selected_control_link: selectedLink,
        selected_control_node: null,
        selected_control: null,
        control_report: getControlReportByLink(state.control_results, selectedLink),
      };
    }
    case actionTypes.SELECT_ALL_CONTROLS: {
      return {
        ...state,
        selected_control_node: null,
        selected_control_link: null,
        selected_control: null,
        control_report: getControlReport(state.control_results, undefined, state.selected_control_views),
      };
    }
    case actionTypes.SELECT_CONTROL: {
      if (action.id === (state.selected_control && state.selected_control.id) || !state.control_results) {
        return state;
      }

      let selectedIndex = state.control_results.findIndex((control) => control.id === action.id),
        selectedControl = state.control_results[selectedIndex];
      return {
        ...state,
        selected_control: selectedControl,
        selected_control_index: selectedIndex,
        control_report: getControlReport(state.control_results, selectedControl, state.selected_control_views),
        selected_control_result_id: null,
      };
    }
    case actionTypes.UNSELECT_CONTROL: {
      return {
        ...state,
        selected_control: null,
        selected_control_index: -1,
        control_report: getControlReport(state.control_results, undefined, state.selected_control_views),
        selected_control_result_id: null,
      };
    }
    case actionTypes.LOAD_CONTROL_RESULT_STARTED: {
      return {
        ...state,
        loading_control_results: true,
        control_report: undefined,
        errors: {},
      };
    }
    case actionTypes.LOAD_CONTROL_RESULT_COMPLETED: {
      let newControlResult = updateControlResult(state.selected_control, action.controlResult);

      return {
        ...state,
        loading_control_results: false,
        selected_control: newControlResult,
      };
    }
    case actionTypes.LOAD_CONTROL_RESULT_FAILED: {
      return {
        ...state,
        loading_control_results: false,
        errors: {
          ...state.errors,
          [errorType.controlResults]: action.error,
        },
      };
    }
    case actionTypes.REQUEST_CONTROL_RESULTS: {
      return {
        ...state,
        loading_control_results: true,
        errors: {
          ...state.errors,
          [errorType.controlResults]: undefined,
          [errorType.controlComment]: undefined,
          [errorType.controlLinkedItem]: undefined,
          [errorType.controlIncident]: undefined,
        },
      };
    }
    case actionTypes.RECEIVED_CONTROL_RESULTS: {
      let controls =
          state.controls &&
          state.controls.map((currentResult) => {
            let newResult =
              action.controlResults.find((newResult: IControlResult) => newResult.id === currentResult.id) || {};

            return updateControlResult(currentResult, newResult);
          }),
        controlViews = getSelectedControlViews(state.control_views, state.selected_control_view_id),
        controlResults = getControlResults(controlViews, controls),
        selectedControl =
          state.selected_control &&
          controlResults &&
          controlResults.find((control) => control.id === state.selected_control.id);

      return {
        ...state,
        controls,
        control_results: controlResults,
        control_report: getControlReport(controlResults, undefined, state.selected_control_views),
        selected_control: { ...selectedControl, ...state.selected_control },
        selected_control_views: updateControlViews(state.selected_control_views, controlResults, action.userInfo),
        selected_control_result_id: undefined,
        loading_control_results: false,
      };
    }
    case actionTypes.REQUEST_CONTROL_RESULTS_FAILED: {
      return {
        ...state,
        loading_control_results: false,
        errors: {
          ...state.errors,
          [errorType.controlResults]: action.error,
        },
      };
    }
    case actionTypes.REQUEST_CONTROL_RESULT_ITEMS: {
      return {
        ...state,
        loading_control_result_items: true,
        errors: {
          ...state.errors,
          [errorType.controlResults]: undefined,
        },
      };
    }
    case actionTypes.RECEIVED_CONTROL_RESULT_ITEMS: {
      let resultItems = action.controlResultItems;

      resultItems =
        resultItems &&
        resultItems.map &&
        resultItems.map((item) => {
          let lineItemDetail = {};

          try {
            lineItemDetail = JSON.parse(item.lineItemDetail);
          } catch {}

          if (lineItemDetail) {
            return {
              ...item,
              ...lineItemDetail,
            };
          } else {
            return item;
          }
        });

      return {
        ...state,
        loading_control_result_items: false,
        control_report: getControlReport(
          resultItems,
          state.selected_control,
          state.selected_control_views,
          action.totalCount
        ),
      };
    }
    case actionTypes.REQUEST_CONTROL_RESULT_ITEMS_FAILED: {
      return {
        ...state,
        loading_control_result_items: false,
        errors: {
          ...state.errors,
          [errorType.controlResults]: action.error,
        },
      };
    }
    case actionTypes.SELECT_CONTROL_FILTER: {
      let selectedControlViewId = action.filterValue;

      if (state.selected_control_view_id === selectedControlViewId) {
        return state;
      }

      let selectedControlViews = getSelectedControlViews(state.control_views, selectedControlViewId),
        controlResults = getControlResults(selectedControlViews, state.controls);

      return {
        ...state,
        selected_control: null,
        selected_control_node: null,
        selected_control_link: null,
        selected_control_view_id: selectedControlViewId,
        control_results: controlResults,
        control_report: getControlReport(controlResults, undefined, state.selected_control_views),
        selected_control_views: updateControlViews(selectedControlViews, controlResults, action.userInfo),
        is_next_view_collapsed: false,
        errors: {
          ...state.errors,
          [errorType.controlView]: undefined,
        },
      };
    }
    case actionTypes.REQUEST_CONTROL_VIEWS: {
      return {
        ...state,
        loading_control_view: true,
        loading_control_results: true,
        control_results: null,
        control_report: null,
        errors: {
          ...state.errors,
          [errorType.controlView]: undefined,
        },
      };
    }
    case actionTypes.RECEIVED_CONTROL_VIEWS: {
      let controlViews = state.control_views;

      let selectedControlViews =
        action.controlViews && action.controlViews.filter((controlView: IControlView) => controlView && controlView.id);

      selectedControlViews.forEach((controlView: IControlView) => {
        controlViews = {
          ...controlViews,
          [controlView.id]: controlView,
        };
      });

      return {
        ...state,
        loading_control_view: false,
        control_views: controlViews,
        selected_control_views: selectedControlViews,
      };
    }
    case actionTypes.REQUEST_CONTROL_VIEWS_FAILED: {
      return {
        ...state,
        loading_control_view: false,
        loading_control_results: false,
        errors: {
          ...state.errors,
          [errorType.controlView]: action.error,
        },
      };
    }
    case actionTypes.REQUEST_CONTROLS: {
      return {
        ...state,
        loading_controls: true,
        errors: {},
      };
    }
    case actionTypes.RECEIVED_CONTROLS: {
      let controls =
          action.controls &&
          action.controls.map((control: any) => ({
            ...control,
            ...control.outputConfig,
          })),
        controlViews = getSelectedControlViews(state.control_views, state.selected_control_view_id);

      return {
        ...state,
        loading_controls: false,
        controls,
        control_results: getControlResults(controlViews, controls),
      };
    }
    case actionTypes.REQUEST_CONTROLS_FAILED: {
      return {
        ...state,
        loading_controls: false,
        loading_control_results: false,
        errors: {
          ...state.errors,
          [errorType.controls]: action.error,
        },
      };
    }
    case actionTypes.TOGGLE_NEXT_VIEW: {
      return {
        ...state,
        is_next_view_collapsed: !state.is_next_view_collapsed,
      };
    }
    case actionTypes.RECEIVED_ENTITIES: {
      let entities: IEntityList = { ...state.control_entities };

      action.entities &&
        action.entities.forEach((entity) => {
          if (entities[entity.id]) {
            entities[entity.id] = {
              ...entities[entity.id],
              ...entity,
            };
          } else {
            entities[entity.id] = entity;
          }
        });

      return {
        ...state,
        control_entities: entities,
      };
    }
    case actionTypes.REQUEST_CONTROL_TILES: {
      return {
        ...state,
        loading_control_tiles: true,
        errors: {
          ...state.errors,
          [errorType.controlTiles]: undefined,
        },
      };
    }
    case actionTypes.RECEIVED_CONTROL_TILES: {
      return {
        ...state,
        loading_control_tiles: false,
        selected_control: {
          ...state.selected_control,
          tileResults: action.tileResults,
        },
      };
    }
    case actionTypes.REQUEST_CONTROL_TILES_FAILED: {
      return {
        ...state,
        loading_control_tiles: false,
        errors: {
          ...state.errors,
          [errorType.controlTiles]: action.error,
        },
      };
    }
    case actionTypes.SELECT_CONTROL_DRILLDOWN_VALUE: {
      return {
        ...state,
        selected_control: {
          ...state.selected_control,
          selectedDrilldownValue: action.selectedDrilldownValue,
        },
      };
    }
    case actionTypes.REQUEST_CONTROL_RESULT_HISTORY: {
      return {
        ...state,
        loading_control_result_history: true,
      };
    }
    case actionTypes.RECEIVED_CONTROL_RESULT_HISTORY: {
      const results =
        action.controlResults &&
        action.controlResults.filter((result) => !isNaN(new Date(result.dateRangeEndDateTime).valueOf()));

      return {
        ...state,
        loading_control_result_history: false,
        selected_control: {
          ...state.selected_control,
          resultHistory: results,
        },
      };
    }
    case actionTypes.REQUEST_CONTROL_RESULT_HISTORY_FAILED: {
      return {
        ...state,
        loading_control_result_history: false,
      };
    }
    case actionTypes.SELECT_CONTROL_RESULT: {
      let selectedControl = state.controls.find((control) => control.id === state.selected_control.id),
        resultHistory = state.selected_control.resultHistory,
        selectedResult =
          resultHistory && resultHistory.find((result) => result.controlResultId === action.controlResultId),
        resultDetail = {};

      try {
        resultDetail = JSON.parse(selectedResult.resultDetail);
      } catch {}

      return {
        ...state,
        selected_control_result_id: action.controlResultId,
        selected_control: {
          ...selectedControl,
          ...selectedResult,
          ...resultDetail,
          resultHistory,
        },
      };
    }
    case actionTypes.REQUEST_CONTROL_RESULT_DETAILS: {
      return {
        ...state,
        loading_control_result_details: true,
      };
    }
    case actionTypes.REQUEST_CONTROL_RESULT_DETAILS_FAILED: {
      return {
        ...state,
        loading_control_result_details: false,
      };
    }
    case actionTypes.RECEIVED_CONTROL_RESULT_DETAILS: {
      let controlResultStatus = action.results && action.results.controlResultStatus,
        selectedControl = {
          ...state.selected_control,
          ...action.results,
        };

      selectedControl.controlResultStatus = selectedControl.controlResultStatus || {};
      selectedControl.impactScoreOverride = selectedControl.controlResultStatus.impactScoreOverride;

      let oldControlResultStatus = state.selected_control && state.selected_control.controlResultStatus,
        oldImpactScoreOverride = oldControlResultStatus && oldControlResultStatus.impactScoreOverride,
        newImpactScoreOverride = controlResultStatus && controlResultStatus.impactScoreOverride,
        controlResults = state.control_results || [],
        selectedControlViews = state.selected_control_views;

      // If impactScoreOverride has changed, update the control results and the related control views so that the correct colors are updated.
      if (oldImpactScoreOverride !== newImpactScoreOverride) {
        let controlResultId = controlResultStatus && controlResultStatus.controlResultId;

        // Update new impactScoreOverride in control results.
        controlResults = controlResults.map((controlResult) => {
          if (controlResultId === controlResult.controlResultId) {
            return {
              ...controlResult,
              impactScoreOverride: newImpactScoreOverride,
            };
          }
          return controlResult;
        });

        // Update new impactScoreOverride in control views.
        selectedControlViews = updateControlViews(state.selected_control_views, controlResults, action.userInfo);
      }

      return {
        ...state,
        control_results: controlResults,
        selected_control_views: selectedControlViews,
        selected_control: selectedControl,
        loading_control_result_details: false,
      };
    }
    case actionTypes.UPDATE_CONTROL_RESULT_DETAILS_STARTED: {
      return {
        ...state,
        saving_control_details: true,
      };
    }
    case actionTypes.UPDATE_CONTROL_RESULT_DETAILS_COMPLETED: {
      let newState = {
        ...state,
        saving_control_details: false,
      };

      return reducer(
        newState,
        actionCreator.receivedControlResultDetails(action.controlId, action.results, action.userInfo)
      );
    }
    case actionTypes.UPDATE_CONTROL_RESULT_DETAILS_FAILED: {
      return {
        ...state,
        saving_control_details: false,
      };
    }
    case actionTypes.EDIT_CONTROL_COMMENT: {
      return {
        ...state,
        editing_control_comment: action.comment,
      };
    }
    case actionTypes.CANCEL_CONTROL_COMMENT_EDIT: {
      return {
        ...state,
        editing_control_comment: null,
      };
    }
    case actionTypes.UPDATE_CONTROL_COMMENT_STARTED: {
      return {
        ...state,
        saving_control_comment: true,
        errors: {
          ...state.errors,
          [errorType.controlComment]: undefined,
        },
      };
    }
    case actionTypes.UPDATE_CONTROL_COMMENT_FAILED: {
      return {
        ...state,
        saving_control_comment: false,
        errors: {
          ...state.errors,
          [errorType.controlComment]: action.error,
        },
      };
    }
    case actionTypes.UPDATE_CONTROL_COMMENT_COMPLETED: {
      let comments = state.selected_control.comments,
        isExistingComment = false;

      comments =
        comments &&
        comments
          .map((comment) => {
            if (comment.commentId === action.comment.commentId) {
              isExistingComment = true;
              return action.comment.isPurged ? null : action.comment;
            }
            return comment;
          })
          .filter((comment) => comment !== null);

      if (!isExistingComment) {
        comments.unshift(action.comment);
      }

      return {
        ...state,
        saving_control_comment: false,
        editing_control_comment: null,
        selected_control: {
          ...state.selected_control,
          comments,
        },
      };
    }
    case actionTypes.EDIT_CONTROL_LINKED_ITEM: {
      return {
        ...state,
        editing_control_linked_item: action.linkedItem,
        errors: {
          ...state.errors,
          [errorType.controlLinkedItem]: undefined,
        },
      };
    }
    case actionTypes.CANCEL_CONTROL_LINKED_ITEM_EDIT: {
      return {
        ...state,
        editing_control_linked_item: null,
        errors: {
          ...state.errors,
          [errorType.controlLinkedItem]: undefined,
        },
      };
    }
    case actionTypes.UPDATE_CONTROL_LINKED_ITEM_STARTED: {
      return {
        ...state,
        saving_control_linked_item: true,
        errors: {
          ...state.errors,
          [errorType.controlLinkedItem]: undefined,
        },
      };
    }
    case actionTypes.UPDATE_CONTROL_LINKED_ITEM_FAILED: {
      return {
        ...state,
        saving_control_linked_item: false,
        errors: {
          ...state.errors,
          [errorType.controlLinkedItem]: action.error,
        },
      };
    }
    case actionTypes.UPDATE_CONTROL_LINKED_ITEM_COMPLETED: {
      let linkedItems = state.selected_control.linkedItems,
        isExistingLinkedItem = false;

      linkedItems =
        linkedItems &&
        linkedItems
          .map((linkedItem) => {
            if (linkedItem.linkedItemId === action.linkedItem.linkedItemId) {
              isExistingLinkedItem = true;
              return action.linkedItem.isPurged ? null : action.linkedItem;
            }
            return linkedItem;
          })
          .filter((linkedItem) => linkedItem !== null);

      if (!isExistingLinkedItem) {
        linkedItems.unshift(action.linkedItem);
      }

      return {
        ...state,
        saving_control_linked_item: false,
        editing_control_linked_item: null,
        selected_control: {
          ...state.selected_control,
          linkedItems,
        },
      };
    }
    case actionTypes.CREATE_CONTROL_INCIDENT_STARTED: {
      return {
        ...state,
        creating_control_incident: true,
        errors: {
          ...state.errors,
          [errorType.controlIncident]: null,
        },
      };
    }
    case actionTypes.CREATE_CONTROL_INCIDENT_FAILED: {
      return {
        ...state,
        creating_control_incident: false,
        errors: {
          ...state.errors,
          [errorType.controlIncident]: action.error,
        },
      };
    }
    case actionTypes.CREATE_CONTROL_INCIDENT_COMPLETED: {
      return {
        ...state,
        creating_control_incident: false,
      };
    }
    // ======================================================================
    // Config related actions.
    case actionTypes.LOAD_CONFIG_ITEMS_STARTED: {
      return {
        ...state,
        loading_config_items: true,
        errors: {
          ...state.errors,
          [errorType.config]: undefined,
        },
      };
    }
    case actionTypes.LOAD_CONFIG_ITEMS_COMPLETED: {
      let newState = {
        ...state,
        loading_config_items: false,
      };

      if (!action.doNotUpdateConfigItems) {
        newState.config_items = action.configItems;
      }

      if (action.configItemType === ConfigItemType.Events) {
        newState.radar_events = action.configItems;
      } else if (action.configItemType === ConfigItemType.ComplianceDocs) {
        newState.compliance_configs = action.configItems;
      } else if (action.configItemType === ConfigItemType.ServiceContent) {
        newState.service_content_configs = action.configItems;
        newState.all_entity_types = action.configItems
          ?.filter((e) => e.isProcessValidation)
          ?.map((item) => item.name)
          ?.sort();

        if (!newState.metric_search_fields?.length) {
          setSearchFields(newState);
        }
      } else if (action.configItemType === ConfigItemType.Teams) {
        newState.teams = action.configItems?.sort((a, b) =>
          a["sortOrder"] > b["sortOrder"] ? 1 : a["sortOrder"] < b["sortOrder"] ? -1 : 0
        );
      } else if (action.configItemType === ConfigItemType.EntityTraceViews && action.configItems?.length) {
        var entityTraceViews = action.configItems.slice();
        newState.entity_trace_views = entityTraceViews;

        // Default selection to the first entity trace view if none is already defined.
        if (newState.entity_trace_view_id === null || newState.entity_trace_view_id === undefined) {
          newState.entity_trace_view_id = entityTraceViews[0].id;
        }
      } else if (action.configItemType === ConfigItemType.EntityViews && action.configItems?.length) {
        // For Entity Views, sort by names first.
        var entityViews = action.configItems.slice();
        entityViews.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
        newState.entity_views = entityViews;

        // Default selection to the first entity view if none is already defined or previously selected one no longer exists.
        var selectedView = entityViews.find((v) => v.id === newState.selected_entity_view_id);

        if (
          newState.selected_entity_view_id === null ||
          newState.selected_entity_view_id === undefined ||
          !selectedView
        ) {
          newState.selected_entity_view_id = entityViews[0].id;
        }

        // Set default subpane selected key for entity view.
        newState.graph_subpane_selected_key = getDefaultEntitySubPaneKey(entityViews, newState.selected_entity_view_id);

        // Extract the related entity types based on the selected entity view.
        if (!newState.entity_types || !newState.entity_types.length) {
          newState.entity_types = getSelectedEntityTypes(newState, newState.selected_entity_view_id);
          let selectedEntityTypes = getSelectedEntityTypes(newState, newState.selected_entity_view_id, true);
          newState.entity_metrics_by_selected_view =
            newState.entity_metrics_raw &&
            newState.entity_metrics_raw.filter(
              (metric) => selectedEntityTypes && selectedEntityTypes.indexOf(metric.entityType) >= 0
            );

          if (!newState.search_entity_type && newState.selected_entity_view_id === dynamicEntityViewId) {
            newState.search_entity_type =
              newState.entity_types && newState.entity_types.length && newState.entity_types[0];
          }
        }

        // Extract the searchable fields based on the anchor entity types.
        if (newState.entity_types?.length > 0 && newState.service_content_configs?.length > 0) {
          setSearchFields(newState);
        }

        // Set the default filters for the selected entity view.
        setupDefaultSearchFilters(newState);
      }

      return newState;
    }
    case actionTypes.LOAD_CONFIG_ITEMS_FAILED: {
      let error = action.error;
      if (error && error.indexOf("NotFound (404)")) {
        console.error(error);
        error = "Warning: One or more service calls did not complete as expected: 'NotFound (404)'";
      }
      return {
        ...state,
        loading_config_items: false,
        errors: {
          ...state.errors,
          [errorType.config]: error,
        },
      };
    }
    case actionTypes.CLEAR_CONFIG_ITEMS: {
      return {
        ...state,
        config_items: null,
      };
    }
    case actionTypes.LOAD_CONFIG_DEFS_STARTED: {
      return {
        ...state,
        loading_config_defs_items: true,
      };
    }
    case actionTypes.LOAD_CONFIG_DEFS_COMPLETED: {
      let newState = {
        ...state,
        loading_config_defs_items: false,
      };

      if (!action.doNotUpdateConfigItems) {
        newState.config_def = action.configDef;
      }
      return newState;
    }
    case actionTypes.LOAD_CONFIG_ENVIRONMENTS_STARTED: {
      return {
        ...state,
        loading_config_environments: true,
        errors: {
          ...state.errors,
          [errorType.config]: undefined,
        },
      };
    }
    case actionTypes.LOAD_CONFIG_ENVIRONMENTS_COMPLETED: {
      let { configEnvironments } = action;
      let newState = {
        ...state,
        loading_config_environments: false,
        config_environments: configEnvironments,
      };

      if (configEnvironments) {
        Object.keys(configEnvironments).forEach((key) => {
          if (configEnvironments[key]?.default) {
            const environment = configEnvironments[key]?.name;
            newState.default_environment = configEnvironments[key].name;
            // Pointing the already loaded environment configs to the default environment to avoid having to load it again
            newState.editing_config_original_values[environment] = newState.editing_config_value_original;
            newState.editing_config_values[environment] = newState.editing_config_value;
          }
        });
      }

      return newState;
    }
    case actionTypes.LOAD_CONFIG_ENVIRONMENTS_FAILED: {
      return {
        ...state,
        loading_config_environments: false,
        errors: {
          ...state.errors,
          [errorType.config]: action.error,
        },
      };
    }
    case actionTypes.EDIT_CONFIG_ITEM: {
      const { newValue, environment } = action;

      const newState = {
        ...state,
        publishing_config_success: false,
      };

      if (environment) {
        newState.editing_config_values = { ...newState.editing_config_values };
        newState.editing_config_values[environment] = newValue;
      } else {
        newState.editing_config_value = newValue;
      }
      return newState;
    }
    case actionTypes.CREATE_CONFIG_ITEM: {
      let defaultItem = { ...action.defaultItem, owner: action.userId, editors: [action.userId] };
      return {
        ...state,
        loading_config: false,
        saving_config: false,
        saving_config_success: false,
        editing_config_value: JSON.stringify(defaultItem, null, 2),
        editing_config_value_original: null,
        publishing_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: undefined,
        },
      };
    }
    case actionTypes.LOAD_CONFIG_ITEM_STARTED: {
      return {
        ...state,
        loading_config: true,
        saving_config: false,
        saving_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: undefined,
        },
      };
    }
    case actionTypes.LOAD_CONFIG_ITEM_COMPLETED: {
      let configItem = action.configItem;
      let environment = action.environment;

      // This is needed for allowing tileDataSource to select the target tile by tileId.
      injectTileIdAsNeeded(configItem);

      let isNewNonGlobalItem = false;

      if (!isGlobalEnvironment(environment)) {
        let environmentConfigTypeSettings = getEnvironmentConfigTypeSettings(state.environments, action.configItemType);

        isNewNonGlobalItem = updateConfigItemForNonGlobalEnv(
          configItem,
          environmentConfigTypeSettings?.fields,
          state.editing_config_item_global[action.configItemType]
        );
      }

      let configValue = JSON.stringify(configItem, null, 2);

      let newState = {
        ...state,
        loading_config: false,
      };

      if (!action.doNotUpdateConfigValue) {
        newState.editing_config_value = configValue;
        newState.editing_config_value_original = isNewNonGlobalItem ? "{}" : configValue;
      }

      if (isGlobalEnvironment(environment)) {
        if (!newState.editing_config_item_global) {
          newState.editing_config_item_global = {};
        }
        newState.editing_config_item_global[action.configItemType] = configItem;
      }

      if (environment) {
        newState.editing_config_values[environment] = configValue;
        newState.editing_config_original_values[environment] = configValue;
      }

      if (action.configItemType === ConfigItemType.AppSettings && configItem.id === "ControlViewFilters") {
        newState.control_view_filters = configItem.settings;
      } else if (action.configItemType === ConfigItemType.AppSettings && configItem.id === "IcMConnectors") {
        newState.icm_connectors = configItem.settings;
      } else if (action.configItemType === ConfigItemType.AppSettings && configItem.id === "ControlMetadata") {
        newState.control_metadata = configItem.settings;
      } else if (action.configItemType === ConfigItemType.AppSettings && configItem.id === "DevOpsOrgs") {
        newState.devops_orgs = configItem.settings;
      } else if (action.configItemType === ConfigItemType.AppSettings && configItem.id === "StandardTemplates") {
        newState.standard_templates = configItem.templates;
      } else if (
        action.configItemType === ConfigItemType.AppSettings &&
        configItem.id === "IncidentViewDefaultColumns"
      ) {
        newState.incidents_default_columns = configItem.settings;
      } else if (action.configItemType === ConfigItemType.Controls) {
        let control = { ...configItem, ...configItem.outputConfig };
        newState.selected_control = control;
        newState.controls = [control];
        newState.selected_control_view_id = undefined;
      }

      return newState;
    }
    case actionTypes.LOAD_CONFIG_ITEM_FAILED: {
      return {
        ...state,
        loading_config: false,
        loading_config_attribute_values: false,
        errors: {
          ...state.errors,
          [errorType.config]: action.error,
        },
      };
    }
    case actionTypes.LOAD_CONFIG_ATTRIBUTE_VALUES_STARTED: {
      return {
        ...state,
        loading_config_attribute_values: true,
        errors: {
          ...state.errors,
          [errorType.configAttributeValues]: undefined,
        },
      };
    }
    case actionTypes.LOAD_CONFIG_ATTRIBUTE_VALUES_COMPLETED: {
      let { id, configValues } = action;
      let newConfigValues = { ...state.config_attribute_values };

      newConfigValues[id] = configValues;

      return {
        ...state,
        loading_config_attribute_values: false,
        config_attribute_values: newConfigValues,
      };
    }
    case actionTypes.LOAD_CONFIG_ATTRIBUTE_VALUES_FAILED: {
      return {
        ...state,
        loading_config_attribute_values: false,
        errors: {
          ...state.errors,
          [errorType.configAttributeValues]: action.error,
        },
      };
    }
    case actionTypes.PUBLISH_CONFIG_ITEM_STARTED: {
      return {
        ...state,
        publishing_config: true,
        publishing_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: undefined,
        },
      };
    }
    case actionTypes.PUBLISH_CONFIG_ITEM_COMPLETED: {
      const newValue = JSON.stringify(action.configItem, null, 2);
      return {
        ...state,
        publishing_config: false,
        publishing_config_success: true,
        editing_config_value: newValue,
        editing_config_value_original: newValue,
      };
    }
    case actionTypes.PUBLISH_CONFIG_ITEM_FAILED: {
      return {
        ...state,
        saving_config: false,
        publishing_config: false,
        publishing_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: action.error,
        },
      };
    }
    case actionTypes.PUBLISH_CONFIG_ITEM_RESET: {
      return {
        ...state,
        publishing_config_success: false,
        reverting_config_success: false,
      };
    }
    case actionTypes.DUPLICATE_CONFIG_ITEM_STARTED: {
      return {
        ...state,
        saving_config: true,
        saving_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: undefined,
        },
      };
    }
    case actionTypes.DUPLICATE_CONFIG_ITEM_COMPLETED: {
      const newValue = JSON.stringify(action.configItem, null, 2);
      return {
        ...state,
        saving_config: false,
        saving_config_success: true,
        editing_config_value: newValue,
        editing_config_value_original: newValue,
      };
    }
    case actionTypes.DUPLICATE_CONFIG_ITEM_FAILED: {
      return {
        ...state,
        saving_config: false,
        saving_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: action.error,
        },
      };
    }
    case actionTypes.UPDATE_CONFIG_ITEM_STARTED: {
      return {
        ...state,
        saving_config: true,
        saving_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: undefined,
        },
      };
    }
    case actionTypes.UPDATE_CONFIG_ITEM_COMPLETED: {
      const newValue = JSON.stringify(action.configItem, null, 2);
      let newState = {
        ...state,
        saving_config: false,
        saving_config_success: true,
        editing_config_value: newValue,
        editing_config_value_original: newValue,
      };

      if (isGlobalEnvironment(action.environment)) {
        newState.editing_config_item_global[action.configItemType] = action.configItem;
      }

      return newState;
    }
    case actionTypes.UPDATE_CONFIG_ITEM_FAILED: {
      return {
        ...state,
        saving_config: false,
        saving_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: action.error,
        },
      };
    }
    case actionTypes.DELETE_CONFIG_ITEM_STARTED: {
      return {
        ...state,
        saving_config: true,
        publishing_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: undefined,
        },
      };
    }
    case actionTypes.DELETE_CONFIG_ITEM_COMPLETED: {
      return {
        ...state,
        saving_config: false,
      };
    }
    case actionTypes.DELETE_CONFIG_ITEM_FAILED: {
      return {
        ...state,
        saving_config: false,
        errors: {
          ...state.errors,
          [errorType.config]: action.error,
        },
      };
    }
    case actionTypes.REVERT_CONFIG_ITEM_STARTED: {
      return {
        ...state,
        reverting_config: true,
        reverting_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: undefined,
        },
      };
    }
    case actionTypes.REVERT_CONFIG_ITEM_COMPLETED: {
      return {
        ...state,
        reverting_config: false,
        reverting_config_success: true,
      };
    }
    case actionTypes.REVERT_CONFIG_ITEM_FAILED: {
      return {
        ...state,
        reverting_config: false,
        reverting_config_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: action.error,
        },
      };
    }
    case actionTypes.LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_STARTED: {
      return {
        ...state,
        loading_financial_entity_journal_items: true,
        loading_financial_entity_journal_items_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: undefined,
        },
      };
    }
    case actionTypes.LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_COMPLETED: {
      let { uid, journalItems } = action;
      let newValues = { ...state.financial_entity_journal_items };

      newValues[uid] = journalItems;

      return {
        ...state,
        loading_financial_entity_journal_items: false,
        loading_financial_entity_journal_items_success: true,
        financial_entity_journal_items: newValues,
      };
    }
    case actionTypes.LOAD_FINANCIAL_ENTITY_JOURNAL_ITEMS_FAILED: {
      return {
        ...state,
        loading_financial_entity_journal_items: false,
        loading_financial_entity_journal_items_success: false,
        errors: {
          ...state.errors,
          [errorType.config]: action.error,
        },
      };
    }
    case actionTypes.LOAD_TEAM_TILES_STARTED: {
      return {
        ...state,
        loading_team_tiles: true,
        team_tiles: null,
        errors: {
          ...state.errors,
          [errorType.teamTiles]: undefined,
        },
      };
    }
    case actionTypes.LOAD_TEAM_TILES_COMPLETED: {
      return {
        ...state,
        loading_team_tiles: false,
        team_tiles: action.teamTiles,
      };
    }
    case actionTypes.LOAD_TEAM_TILES_FAILED: {
      return {
        ...state,
        loading_team_tiles: false,
        errors: {
          ...state.errors,
          [errorType.teamTiles]: action.error,
        },
      };
    }
    case actionTypes.LOAD_TEAM_REPORT_STARTED: {
      return {
        ...state,
        selected_team_id: action.teamId,
      };
    }
    case actionTypes.LOAD_TEAM_REPORT_COMPLETED: {
      var teams = updateTeamReport(state.teams, action.teamId, action.report);
      return {
        ...state,
        teams,
      };
    }
    case actionTypes.LOAD_TEAM_OKR_METRICS_STARTED: {
      return {
        ...state,
        loading_team_okr_metrics: true,
        team_okr_metrics: null,
        errors: {
          ...state.errors,
          [errorType.teamOkrMetrics]: undefined,
        },
      };
    }
    case actionTypes.LOAD_TEAM_OKR_METRICS_COMPLETED: {
      return {
        ...state,
        loading_team_okr_metrics: false,
        team_okr_metrics: action.okrMetrics,
      };
    }
    case actionTypes.LOAD_TEAM_OKR_METRICS_FAILED: {
      return {
        ...state,
        loading_team_okr_metrics: false,
        errors: {
          ...state.errors,
          [errorType.teamOkrMetrics]: action.error,
        },
      };
    }
    case actionTypes.CHANGE_TEAM_SELECTED_KEY: {
      return {
        ...state,
        selected_team_id: action.teamId,
        team_tiles: null,
      };
    }
    case actionTypes.CHANGE_TEAM_SELECTED_DATE: {
      return {
        ...state,
        team_selected_date: action.date,
      };
    }
    case actionTypes.SET_ENVIRONMENTS: {
      return {
        ...state,
        environments: action.environments,
      };
    }
    // ======================================================================
    // Report related actions.
    case actionTypes.GET_REPORT_GROUP_STARTED: {
      return {
        ...state,
        selected_report_group: null,
        selected_report_filters: null,
        loading_report_group: true,
        errors: {
          ...state.errors,
          [errorType.reportGroup]: undefined,
        },
      };
    }
    case actionTypes.GET_REPORT_GROUP_COMPLETED: {
      return {
        ...state,
        loading_report_group: false,
        selected_report_group: action.reportGroup,
      };
    }
    case actionTypes.GET_REPORT_GROUP_FAILED: {
      return {
        ...state,
        loading_report_group: false,
        errors: {
          ...state.errors,
          [errorType.reportGroup]: action.error,
        },
      };
    }
    case actionTypes.GET_REPORT_STARTED: {
      return {
        ...state,
        report_loading: true,
        report_error: null,
        selected_report_id: action.reportId,
      };
    }
    case actionTypes.GET_REPORT_COMPLETED: {
      const report = action.report;
      return {
        ...state,
        report_loading: false,
        selected_report_id: report.id,
        reports: {
          ...state.reports,
          [report.id]: report,
        },
      };
    }
    case actionTypes.GET_REPORT_FAILED: {
      return {
        ...state,
        report_loading: false,
        report_error: action.error,
        selected_report_id: null,
      };
    }
    case actionTypes.GET_REPORT_TILE_STARTED: {
      return {
        ...state,
        loading_report_tile: true,
        report_tile_error: null,
      };
    }
    case actionTypes.GET_REPORT_TILE_COMPLETED: {
      const { reportId, tileIndex, tile } = action;

      let newTiles = state.reports[reportId].tiles.slice();
      newTiles[tileIndex] = tile;

      return {
        ...state,
        loading_report_tile: false,
        reports: {
          ...state.reports,
          [reportId]: {
            ...state.reports[reportId],
            tiles: newTiles,
          },
        },
      };
    }
    case actionTypes.GET_REPORT_TILE_FAILED: {
      return {
        ...state,
        loading_report_tile: false,
        report_tile_error: action.error,
      };
    }
    case actionTypes.CLEAR_REPORT_STATE: {
      return {
        ...state,
        reports: {
          ...state.reports,
          [action.reportId]: null,
        },
      };
    }
    case actionTypes.SELECT_REPORT: {
      return {
        ...state,
        report_error: null,
        selected_report_id: action.reportId,
      };
    }
    case actionTypes.UPDATE_REPORT_FILTERS: {
      return {
        ...state,
        selected_report_filters: action.filters,
      };
    }
    case actionTypes.DOWNLOAD_REPORT_DATA_TO_EXCEL_STARTED: {
      return {
        ...state,
        report_excel_downloading: true,
        errors: {
          ...state.errors,
          [errorType.reportExcelDownload]: undefined,
        },
      };
    }
    case actionTypes.DOWNLOAD_REPORT_DATA_TO_EXCEL_COMPLETED: {
      return {
        ...state,
        report_excel_downloading: false,
      };
    }
    case actionTypes.DOWNLOAD_REPORT_DATA_TO_EXCEL_FAILED: {
      return {
        ...state,
        report_excel_downloading: false,
        errors: {
          ...state.errors,
          [errorType.reportExcelDownload]: action.error,
        },
      };
    }
    case actionTypes.DOWNLOAD_REPORT_DATA_TO_EXCEL_RESET: {
      return {
        ...state,
        errors: {
          ...state.errors,
          [errorType.reportExcelDownload]: undefined,
        },
      };
    }
    case actionTypes.LOAD_REPORT_TILE_COLUMN_STARTED: {
      return {
        ...state,
        report_loading: true,
        report_error: null,
      };
    }
    case actionTypes.LOAD_REPORT_TILE_COLUMN_COMPLETED: {
      return {
        ...state,
        report_loading: false,
      };
    }
    case actionTypes.LOAD_REPORT_TILE_COLUMN_FAILED: {
      return {
        ...state,
        report_loading: false,
        report_error: action.error,
      };
    }
    // ======================================================================
    // Graph related actions.
    case actionTypes.SET_SEARCH_ENTITY_TYPE: {
      let searchEntityType =
        state.search_entity_type === action.entityType && state.selected_entity_view_id !== dynamicEntityViewId
          ? null
          : action.entityType;

      let selectionHistory = state.entity_selection_history.slice(),
        existingEntityIndex: any =
          searchEntityType &&
          selectionHistory.length &&
          selectionHistory.findIndex((e: any) => e.entityType === searchEntityType && !e.metricId);

      if (existingEntityIndex >= 0) {
        selectionHistory.splice(existingEntityIndex, 1);
      }

      searchEntityType && selectionHistory.unshift({ entityType: searchEntityType });

      let entitySearchFields = getSearchFields(
        searchEntityType ? [searchEntityType] : state.entity_types,
        state.service_content_configs
      );

      return {
        ...state,
        search_entity_type: searchEntityType,
        search_entity_id: null,
        search_entity_version: null,
        selected_entity: null,
        metric_search_entities: null,
        entity_search_entities: null,
        entity_search_metrics_for_graph: null,
        graph_subpane_selected_key:
          state.graph_subpane_selected_key !== EntitySubPaneKey.Metrics &&
          state.graph_subpane_selected_key !== EntitySubPaneKey.Analytics
            ? EntitySubPaneKey.Metrics
            : state.graph_subpane_selected_key,
        selected_metric_ids: searchEntityType ? state.selected_metric_ids : [],
        entity_selection_history: selectionHistory,
        entity_search_fields: entitySearchFields,
      };
    }
    case actionTypes.SET_SEARCH_ENTITY_ID: {
      return {
        ...state,
        search_entity_id: action.entityId,
      };
    }
    case actionTypes.SET_SEARCH_ENTITY_VERSION: {
      return {
        ...state,
        search_entity_version: action.entityVersion,
      };
    }
    case actionTypes.LOAD_ENTITY_STARTED: {
      return {
        ...state,
        loading_entity: true,
        loading_entity_relationships: true,
        search_entity_type: action.entityType,
        search_entity_id: action.entityId,
        search_entity_version: action.entityVersion,
        search_item_key: SearchItemKey.entityLookup,
        entity_tests: null,
        errors: {
          ...state.errors,
          [errorType.entity]: undefined,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_COMPLETED: {
      var entity = action.result;
      var entityType = action.entityType;
      entity && (entity.entityType = entityType);
      return {
        ...state,
        loading_entity: false,
        loading_entity_relationships: false,
        selected_entity: entity && { entity, entityType },
        no_entity_found: !entity,
      };
    }
    case actionTypes.LOAD_ENTITY_FAILED: {
      return {
        ...state,
        loading_entity: false,
        loading_entity_relationships: false,
        selected_entity: null,
        no_entity_found: true,
        errors: {
          ...state.errors,
          [errorType.entity]: action.error,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_RELATIONSHIPS_STARTED: {
      return {
        ...state,
        loading_entity_relationships: true,
        search_entity_type: action.entityType,
        search_entity_id: action.entityId,
        search_entity_version: action.entityVersion,
        errors: {
          ...state.errors,
          [errorType.entityRelationships]: undefined,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_RELATIONSHIPS_COMPLETED: {
      let selectionHistory = state.entity_selection_history.slice(),
        result = action.result,
        entity = result && result.entity,
        existingEntityIndex: any =
          entity &&
          selectionHistory.length &&
          selectionHistory.findIndex((e: any) => e.entityType === entity.entityType);

      if (existingEntityIndex >= 0) {
        selectionHistory.splice(existingEntityIndex, 1);
      }

      if (entity && entity.id) {
        selectionHistory.unshift(entity);
      }

      return {
        ...state,
        loading_entity_relationships: false,
        selected_entity: result,
        entity_selection_history: selectionHistory,
        no_entity_found: entity && entity.id ? false : true,
      };
    }
    case actionTypes.LOAD_ENTITY_RELATIONSHIPS_FAILED: {
      return {
        ...state,
        loading_entity_relationships: false,
        selected_entity: null,
        no_entity_found: false,
        errors: {
          ...state.errors,
          [errorType.entityRelationships]: action.error,
        },
      };
    }
    case actionTypes.SHOW_ENTITY_DETAILS: {
      return {
        ...state,
        show_entity_details: true,
        entity_details: null,
      };
    }
    case actionTypes.HIDE_ENTITY_DETAILS: {
      return {
        ...state,
        show_entity_details: false,
        entity_details: null,
      };
    }
    case actionTypes.LOAD_ENTITY_DETAILS_STARTED: {
      return {
        ...state,
        loading_entity_details: true,
        errors: {
          ...state.errors,
          [errorType.entityDetails]: undefined,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_DETAILS_COMPLETED: {
      return {
        ...state,
        loading_entity_details: false,
        entity_details: action.result,
      };
    }
    case actionTypes.LOAD_ENTITY_DETAILS_FAILED: {
      return {
        ...state,
        loading_entity_details: false,
        errors: {
          ...state.errors,
          [errorType.entityDetails]: action.error,
        },
      };
    }
    case actionTypes.LOAD_FINANCIAL_ENTITIES_STARTED: {
      return {
        ...state,
        loading_financial_entities: true,
        selected_entity: null,
        financial_entities: null,
        metric_search_entities:
          state.search_item_key === SearchItemKey.metricSearch ? null : state.metric_search_entities,
        entity_search_entities:
          state.search_item_key === SearchItemKey.entitySearch ? null : state.entity_search_entities,
        no_entity_found: false,
        errors: {
          ...state.errors,
          [errorType.financialEntities]: undefined,
        },
      };
    }
    case actionTypes.LOAD_FINANCIAL_ENTITIES_COMPLETED: {
      let newState = {
        ...state,
      };

      let selectedEntityTypes = getSelectedEntityTypes(state),
        entitiesByView = getFinancialEntitiesByEntityView(action.result, selectedEntityTypes);

      newState.financial_entities = action.result;
      newState.metric_search_entities =
        state.search_item_key === SearchItemKey.metricSearch ? entitiesByView : state.metric_search_entities;
      newState.entity_search_entities =
        state.search_item_key === SearchItemKey.entitySearch ? entitiesByView : state.entity_search_entities;
      newState.loading_financial_entities = false;
      selectedEntityTypes && (newState.entity_types = selectedEntityTypes);

      return newState;
    }
    case actionTypes.LOAD_FINANCIAL_ENTITIES_FAILED: {
      return {
        ...state,
        loading_financial_entities: false,
        loading_financial_entity_counts: false,
        errors: {
          ...state.errors,
          [errorType.financialEntities]: action.error,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_METRICS_STARTED: {
      const isEntitySearch = state.search_item_key === SearchItemKey.entitySearch;

      return {
        ...state,
        loading_entity_metrics: true,
        no_entity_found: false,
        entity_metrics_for_graph: isEntitySearch ? state.entity_metrics_for_graph : null,
        entity_metrics_raw: isEntitySearch ? state.entity_metrics_raw : null,
        entity_metrics_by_selected_view: isEntitySearch ? state.entity_metrics_by_selected_view : null,
        entity_metrics_query_timestamp: null,
        entity_metrics_query_time: undefined,
        financial_entities: null,
        metric_search_entities: null,
        selected_entity: null,
        selected_metric_ids: null,
        search_entity_start_time: action.startTime || state.search_entity_start_time,
        search_entity_end_time: action.endTime || state.search_entity_end_time,
        search_item_key: state.search_item_key || SearchItemKey.metricSearch,
        errors: {
          ...state.errors,
          [errorType.financialEntities]: undefined,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_METRICS_COMPLETED: {
      const metrics = action.metrics,
        isEntitySearch = state.search_item_key === SearchItemKey.entitySearch;

      // Sort the metrics by metric names.
      metrics?.forEach(
        (entityMetric) =>
          (entityMetric.metrics = entityMetric.metrics?.sort((a, b) =>
            a.name > b.name ? 1 : a.name < b.name ? -1 : 0
          ))
      );

      const metricsForGraph = transformEntityMetrics(metrics),
        entityTypes = state.entity_types,
        metricsByView =
          entityTypes && metrics?.filter((entityMetric) => entityTypes.indexOf(entityMetric.entityType) >= 0);

      var newState = {
        ...state,
        entity_metrics_query_timestamp: action.executionTimestamp,
        entity_metrics_query_time: action.queryTime,
        loading_entity_metrics: false,
      };

      if (isEntitySearch) {
        return {
          ...newState,
          entity_search_metrics_for_graph: metricsForGraph,
        };
      } else {
        return {
          ...newState,
          entity_metrics_for_graph: metricsForGraph,
          entity_metrics_raw: metrics,
          entity_metrics_by_selected_view: metricsByView,
          entity_selection_history: getNewSelectionHistoryForAggregateSearch(state),
        };
      }
    }
    case actionTypes.LOAD_ENTITY_METRICS_FAILED: {
      return {
        ...state,
        loading_entity_metrics: false,
        entity_selection_history: getNewSelectionHistoryForAggregateSearch(state),
        errors: {
          ...state.errors,
          [errorType.financialEntities]: action.error,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_TRACE_METRICS_STARTED: {
      return {
        ...state,
        loading_entity_trace_metrics: true,
        entity_trace_metrics: null,
        no_entity_trace_found: false,
        errors: {
          ...state.errors,
          [errorType.entityTraces]: undefined,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_TRACE_METRICS_COMPLETED: {
      return {
        ...state,
        loading_entity_trace_metrics: false,
        entity_trace_metrics: action.metrics,
        no_entity_trace_found: action.metrics && action.metrics.length ? false : true,
        errors: {
          ...state.errors,
          [errorType.entityTraces]: undefined,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_TRACE_METRICS_FAILED: {
      return {
        ...state,
        loading_entity_trace_metrics: false,
        entity_trace_metrics: null,
        no_entity_trace_found: false,
        errors: {
          ...state.errors,
          [errorType.entityTraces]: action.error,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_TILES_STARTED: {
      return {
        ...state,
        loading_entity_tiles: true,
        entity_tiles: null,
        errors: {
          ...state.errors,
          [errorType.entityTiles]: undefined,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_TILES_COMPLETED: {
      return {
        ...state,
        loading_entity_tiles: false,
        entity_tiles: action.result,
        errors: {
          ...state.errors,
          [errorType.entityTiles]: undefined,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_TILES_FAILED: {
      return {
        ...state,
        loading_entity_tiles: false,
        entity_tiles: null,
        errors: {
          ...state.errors,
          [errorType.entityTiles]: action.error,
        },
      };
    }
    case actionTypes.CHANGE_SEARCH_ENTITY_START_TIME: {
      action.startTime.setUTCHours(0, 0, 0, 0);
      return {
        ...state,
        search_entity_start_time: action.startTime,
      };
    }
    case actionTypes.CHANGE_SEARCH_ENTITY_END_TIME: {
      action.endTime.setUTCHours(23, 59, 59, 999);
      return {
        ...state,
        search_entity_end_time: action.endTime,
      };
    }
    case actionTypes.CHANGE_SEARCH_ENTITY_MONTH: {
      const { startDate, endDate } = getStartEndDates(searchDateType.customMonth, action.startTime);
      return {
        ...state,
        search_entity_start_time: startDate,
        search_entity_end_time: endDate,
      };
    }
    case actionTypes.CHANGE_SEARCH_ENTITY_SELECTED_KEY: {
      const isMetricSearch = action.key === SearchItemKey.metricSearch;

      return {
        ...state,
        search_item_key: action.key,
        graph_subpane_selected_key:
          isMetricSearch && state.metric_search_entities?.length
            ? EntitySubPaneKey.Entities
            : state.graph_subpane_selected_key,
        default_search_filters: isMetricSearch
          ? getDefaultSearchFilters(state.metric_search_fields, state.entity_views, state.selected_entity_view_id)
          : getDefaultSearchFilters(state.entity_search_fields),
      };
    }
    case actionTypes.CHANGE_SEARCH_ENTITY_DATE_TYPE: {
      const { startDate, endDate } = getStartEndDates(action.dateType, state.search_entity_start_time);
      return {
        ...state,
        search_entity_date_type: action.dateType,
        search_entity_start_time: startDate || state.search_entity_start_time,
        search_entity_end_time: endDate || state.search_entity_end_time,
      };
    }
    case actionTypes.LOAD_ENTITY_TESTS_STARTED: {
      return {
        ...state,
        loading_entity_tests: true,
        loading_entity_trace_metrics: true,
        entity_tests: null,
        entity_trace_metrics: null,
        errors: {
          ...state.errors,
          [errorType.entityTests]: undefined,
        },
      };
    }
    case actionTypes.LOAD_ENTITY_TESTS_COMPLETED: {
      return {
        ...state,
        loading_entity_tests: false,
        entity_tests: action.tests,
        graph_subpane_selected_key:
          action.tests && action.tests.length ? EntitySubPaneKey.Tests : EntitySubPaneKey.Entities,
      };
    }
    case actionTypes.LOAD_ENTITY_TESTS_FAILED: {
      return {
        ...state,
        loading_entity_tests: false,
        errors: {
          ...state.errors,
          [errorType.entityTests]: action.error,
        },
      };
    }
    case actionTypes.BACK_TO_PREVIOUS_ENTITY_SELECTION: {
      let newSelections = state.entity_selection_history.slice();
      newSelections.push(newSelections.shift()); // Move the first selection to the last.

      return {
        ...state,
        entity_selection_history: newSelections,
      };
    }
    case actionTypes.SELECT_ENTITY_VIEW: {
      let selectedViewId = action.selectedId,
        entityTypes = getSelectedEntityTypes(state, selectedViewId),
        selectedEntityTypes = getSelectedEntityTypes(state, selectedViewId, true),
        searchEntityType =
          selectedViewId === dynamicEntityViewId ? entityTypes && entityTypes.length && entityTypes[0] : null,
        entityMetrics =
          state.entity_metrics_raw &&
          state.entity_metrics_raw.filter(
            (metric) => selectedEntityTypes && selectedEntityTypes.indexOf(metric.entityType) >= 0
          ),
        anchorEntityTypes = getAnchorEntityTypes(state.entity_views, selectedViewId, entityTypes),
        metricSearchFields = getSearchFields(anchorEntityTypes, state.service_content_configs),
        entitySearchFields = getSearchFields(
          searchEntityType ? [searchEntityType] : entityTypes,
          state.service_content_configs
        ),
        defaultMetricSearchFilters = getDefaultSearchFilters(metricSearchFields, state.entity_views, selectedViewId),
        defaultEntitySearchFilters = getDefaultSearchFilters(entitySearchFields),
        dateType = state.search_entity_date_type,
        startDate = state.search_entity_start_time,
        endDate = state.search_entity_end_time,
        searchViewFields = getSearchViewFields(state.entity_views, selectedViewId),
        searchViewFilters = getSearchViewFilters(searchViewFields),
        subPaneSelectedKey = getDefaultEntitySubPaneKey(state.entity_views, selectedViewId);

      // Normalize date type to this month if it's not already on the common data types of thisMonth and lastMonth.
      if (dateType !== searchDateType.thisMonth && dateType !== searchDateType.lastMonth) {
        const startEndDates = getStartEndDates(searchDateType.thisMonth);
        dateType = searchDateType.thisMonth;
        startDate = startEndDates.startDate;
        endDate = startEndDates.endDate;
      }

      localStorage[selectedEntityViewIdStateName] = selectedViewId;

      return {
        ...state,
        selected_entity_view_id: selectedViewId,
        selected_entity: null,
        metric_search_entities: null,
        entity_search_entities: null,
        entity_types: entityTypes,
        search_item_key: SearchItemKey.metricSearch,
        search_entity_type: searchEntityType,
        search_entity_id: null,
        search_entity_version: null,
        selected_metric_ids: null,
        graph_subpane_selected_key: subPaneSelectedKey,
        entity_metrics_by_selected_view: entityMetrics,
        entity_search_metrics_for_graph: null,
        entity_tiles: null,
        metric_search_filters: defaultMetricSearchFilters,
        metric_search_fields: metricSearchFields,
        entity_search_filters: defaultEntitySearchFilters,
        entity_search_fields: entitySearchFields,
        default_search_filters: defaultMetricSearchFilters,
        search_entity_date_type: dateType,
        search_entity_start_time: startDate,
        search_entity_end_time: endDate,
        search_view_fields: searchViewFields,
        search_view_filters: searchViewFilters,
      };
    }
    case actionTypes.CHANGE_ENTITY_METRIC: {
      let selectionHistory = state.entity_selection_history.slice(),
        startTime = state.search_entity_start_time,
        endTime = state.search_entity_end_time,
        entityType = action.entityType,
        metricId = action.metricId,
        existingSelectionIndex = selectionHistory.findIndex(
          (e: any) =>
            e.startTime === startTime && e.endTime === endTime && e.entityType === entityType && e.metricId === metricId
        );

      // If the metric selection item already exists, remove it first and then add it back to the selections as the first item.
      if (existingSelectionIndex >= 0) {
        selectionHistory.splice(existingSelectionIndex, 1);
      }
      selectionHistory.unshift({ startTime, endTime, entityType, metricId });

      return {
        ...state,
        search_entity_type: entityType,
        selected_metric_ids: metricId ? [metricId] : [],
        graph_subpane_selected_key: metricId ? EntitySubPaneKey.Entities : EntitySubPaneKey.Metrics,
        metric_search_entities: metricId ? state.metric_search_entities : null,
        entity_selection_history: selectionHistory,
      };
    }
    case actionTypes.CHANGE_SUBPANE_SELECTED_KEY: {
      return {
        ...state,
        graph_subpane_selected_key: action.selectedKey,
      };
    }
    case actionTypes.CHANGE_ENTITY_METRIC_SELECTION: {
      const origMetricIds = state.selected_metric_ids || [],
        metricIndex = origMetricIds.indexOf(action.metricId),
        newMetricIds = origMetricIds.slice();

      if (metricIndex < 0) {
        newMetricIds.push(action.metricId);
      } else {
        newMetricIds.splice(metricIndex, 1);
      }

      return {
        ...state,
        selected_metric_ids: newMetricIds,
        graph_subpane_selected_key: EntitySubPaneKey.Metrics,
        metric_search_entities: null,
      };
    }
    case actionTypes.ADD_SEARCH_FILTER: {
      let searchFilters = getSearchFilters(state);

      searchFilters.push(action.searchFilter);

      return getSearchFiltersState(state, searchFilters);
    }
    case actionTypes.EDIT_SEARCH_FILTER: {
      let selectedIndex = action.selectedFilterIndex,
        newSearchFilter = action.searchFilter,
        searchFilters = getSearchFilters(state),
        selectionIndex = 0;

      for (let i = 0; i < searchFilters?.length; i++) {
        let topFilter = searchFilters[i];

        if (topFilter.filters?.length) {
          for (let j = 0; j < topFilter.filters.length; j++) {
            if (selectionIndex++ === selectedIndex) {
              topFilter.filters.splice(j, 1, newSearchFilter);
            }
          }
        } else {
          if (selectionIndex++ === selectedIndex) {
            searchFilters.splice(i, 1, newSearchFilter);
            break;
          }
        }
      }

      return getSearchFiltersState(state, searchFilters);
    }
    case actionTypes.REMOVE_SEARCH_FILTER: {
      let searchFilters = getSearchFilters(state),
        selectedIndexes = action.selectedFilterIndexes,
        selectionIndex = 0;

      if (selectedIndexes?.length) {
        // Need to find the total # of filters first.
        for (let i = 0; i < searchFilters?.length; i++) {
          if (searchFilters[i].filters?.length) {
            selectionIndex += searchFilters[i].filters?.length;
          } else {
            selectionIndex++;
          }
        }
        selectionIndex--;

        //  Then reversely loop thru the list because deletion would change the index order in searchFilters.
        for (let i = searchFilters?.length - 1; i >= 0; i--) {
          let topFilter = searchFilters[i];

          if (topFilter.filters?.length) {
            for (let j = topFilter.filters.length - 1; j >= 0; j--) {
              if (selectedIndexes.indexOf(selectionIndex--) >= 0) {
                topFilter.filters.splice(j, 1);
                if (!topFilter.filters.length) {
                  searchFilters.splice(i, 1);
                }
              }
            }
          } else {
            if (selectedIndexes.indexOf(selectionIndex--) >= 0) {
              searchFilters.splice(i, 1);
            }
          }
        }
      }

      return getSearchFiltersState(state, searchFilters);
    }
    case actionTypes.GROUP_SEARCH_FILTER: {
      let searchFilters = getSearchFilters(state),
        selectedIndexes = action.selectedFilterIndexes,
        targetFilters = [],
        selectionIndex = 0,
        startIndex = -1;

      if (selectedIndexes?.length) {
        for (let i = 0; i < searchFilters?.length; i++) {
          let topFilter = searchFilters[i];

          if (topFilter.filters?.length) {
            for (let j = 0; j < topFilter.filters.length; j++) {
              selectionIndex++;
            }
          } else {
            if (selectedIndexes.indexOf(selectionIndex++) >= 0) {
              targetFilters.push(searchFilters[i]);
              if (startIndex < 0) {
                startIndex = i;
              }
            }
          }
        }

        // Create a new group filter with all target filters as children.
        searchFilters.splice(startIndex, selectedIndexes.length, { filters: targetFilters });
      }

      return getSearchFiltersState(state, searchFilters);
    }
    case actionTypes.UNGROUP_SEARCH_FILTER: {
      let searchFilters = getSearchFilters(state),
        selectedIndexes = action.selectedFilterIndexes,
        selectionIndex = 0;

      if (selectedIndexes?.length) {
        // Need to find the total # of filters first.
        for (let i = 0; i < searchFilters?.length; i++) {
          if (searchFilters[i].filters?.length) {
            selectionIndex += searchFilters[i].filters?.length;
          } else {
            selectionIndex++;
          }
        }
        selectionIndex--;

        //  Then reversely loop thru the list because deletion would change the index order in searchFilters.
        for (let i = searchFilters?.length - 1; i >= 0; i--) {
          let topFilter = searchFilters[i];

          if (topFilter.filters?.length) {
            for (let j = topFilter.filters.length - 1; j >= 0; j--) {
              if (selectedIndexes.indexOf(selectionIndex--) >= 0) {
                let targetFilter = topFilter.filters.splice(j, 1)[0];
                searchFilters.splice(i + 1, 0, targetFilter);
                if (!topFilter.filters.length) {
                  searchFilters.splice(i, 1);
                }
              }
            }
          } else {
            selectionIndex--;
          }
        }
      }

      return getSearchFiltersState(state, searchFilters);
    }
    case actionTypes.CLEAR_ALL_SEARCH_FILTERS: {
      return getSearchFiltersState(state, []);
    }
    case actionTypes.RESTORE_SEARCH_FILTERS: {
      const isMetricSearch = state.search_item_key === SearchItemKey.metricSearch,
        searchFilters = isMetricSearch
          ? getDefaultSearchFilters(state.metric_search_fields, state.entity_views, state.selected_entity_view_id)
          : getDefaultSearchFilters(state.entity_search_fields);

      return getSearchFiltersState(state, searchFilters);
    }
    case actionTypes.CHANGE_SEARCH_VIEW_FILTER: {
      var { viewField, value } = action,
        searchViewFilters = changeSearchViewFilters(state.search_view_filters, viewField, value);

      return {
        ...state,
        search_view_filters: searchViewFilters,
      };
    }
    case actionTypes.CHANGE_SEARCH_BATCH_SIZE: {
      return {
        ...state,
        search_batch_size: action.size,
      };
    }
    case actionTypes.CHANGE_SEARCH_RANDOM_TOP: {
      return {
        ...state,
        search_random_top: action.randomTop,
      };
    }
    // ======================================================================
    // DevOps related actions.
    case actionTypes.LOAD_DEVOPS_VIEW_STARTED: {
      return {
        ...state,
        loading_devops_items: true,
        devops_items: [],
        devops_search_text: null,
        selected_devops_view: null,
        selected_devops_view_id: action.devOpsViewId,
        selected_devops_inline_filters: [],
        errors: {
          ...state.errors,
          [errorType.devOpsItems]: undefined,
          [errorType.devOpsEmailDownload]: undefined,
          [errorType.devOpsWorkItemDelete]: undefined,
        },
      };
    }
    case actionTypes.LOAD_DEVOPS_VIEW_COMPLETED: {
      let devOpsView = action.result.view,
        devOpsItems = action.result.items,
        inlineFilters = getDefaultDevOpsInlineFilters(devOpsView),
        columnFieldNames = getDevOpsViewColumnFieldNames(devOpsView.columns),
        filteredDevOpsItems = getFilteredDevOpsItems(devOpsItems, null, columnFieldNames, inlineFilters);
      return {
        ...state,
        loading_devops_items: false,
        devops_items: devOpsItems,
        filtered_devops_items: filteredDevOpsItems,
        selected_devops_view: devOpsView,
        selected_devops_view_id: devOpsView && devOpsView.id,
        selected_devops_inline_filters: inlineFilters,
        devops_view_column_field_names: columnFieldNames,
      };
    }
    case actionTypes.LOAD_DEVOPS_VIEW_FAILED: {
      return {
        ...state,
        loading_devops_items: false,
        devops_items: [],
        filtered_devops_items: [],
        errors: {
          ...state.errors,
          [errorType.devOpsItems]: action.error,
        },
      };
    }
    case actionTypes.LOAD_DEVOPS_VIEWS_STARTED: {
      return {
        ...state,
        loading_devops_views: true,
        errors: {
          ...state.errors,
          [errorType.devOpsViews]: undefined,
        },
      };
    }
    case actionTypes.LOAD_DEVOPS_VIEWS_COMPLETED: {
      const views =
          action.devOpsViews && action.devOpsViews.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)),
        view = views && !!views.length && views.find((view) => view.id !== featureRequestsViewId),
        id = view && view.id;

      return {
        ...state,
        loading_devops_views: false,
        devops_views: views,
        selected_devops_view: view,
        selected_devops_view_id: id,
      };
    }
    case actionTypes.LOAD_DEVOPS_VIEWS_FAILED: {
      return {
        ...state,
        loading_devops_views: false,
        errors: {
          ...state.errors,
          [errorType.devOpsViews]: action.error,
        },
      };
    }
    case actionTypes.GENERATE_DEVOPS_WORKITEMS_STARTED: {
      return {
        ...state,
        generating_devops_workitems: true,
        generated_devops_workitem: null,
        errors: {
          ...state.errors,
          [errorType.devOpsWorkItems]: undefined,
        },
      };
    }
    case actionTypes.GENERATE_DEVOPS_WORKITEMS_COMPLETED: {
      return {
        ...state,
        generating_devops_workitems: false,
        generated_devops_workitem: action.result,
      };
    }
    case actionTypes.GENERATE_DEVOPS_WORKITEMS_FAILED: {
      return {
        ...state,
        generating_devops_workitems: false,
        generated_devops_workitem: null,
        errors: {
          ...state.errors,
          [errorType.devOpsWorkItems]: action.error,
        },
      };
    }
    case actionTypes.DOWNLOAD_EMAIL_FILE_STARTED: {
      return {
        ...state,
        downloading_email_file: true,
        errors: {
          ...state.errors,
          [errorType.devOpsEmailDownload]: undefined,
        },
      };
    }
    case actionTypes.DOWNLOAD_EMAIL_FILE_COMPLETED: {
      return {
        ...state,
        downloading_email_file: false,
      };
    }
    case actionTypes.DOWNLOAD_EMAIL_FILE_FAILED: {
      return {
        ...state,
        downloading_email_file: false,
        errors: {
          ...state.errors,
          [errorType.devOpsEmailDownload]: action.error,
        },
      };
    }
    case actionTypes.DELETE_DEVOPS_WORKITEM_STARTED: {
      return {
        ...state,
        deleting_devops_workitem: true,
        errors: {
          ...state.errors,
          [errorType.devOpsWorkItemDelete]: undefined,
        },
      };
    }
    case actionTypes.DELETE_DEVOPS_WORKITEM_COMPLETED: {
      return {
        ...state,
        deleting_devops_workitem: false,
      };
    }
    case actionTypes.DELETE_DEVOPS_WORKITEM_FAILED: {
      return {
        ...state,
        deleting_devops_workitem: false,
        errors: {
          ...state.errors,
          [errorType.devOpsWorkItemDelete]: action.error,
        },
      };
    }
    case actionTypes.CHANGE_DEVOPS_SEARCH_TEXT: {
      let filteredDevOpsItems = getFilteredDevOpsItems(
        state.devops_items,
        action.searchText,
        state.devops_view_column_field_names,
        state.selected_devops_inline_filters
      );

      return {
        ...state,
        filtered_devops_items: filteredDevOpsItems,
        devops_search_text: action.searchText,
      };
    }
    case actionTypes.SELECT_DEVOPS_INLINE_FILTER: {
      let inlineFilters = getDevOpsInlineFilters(state.selected_devops_inline_filters, action.value),
        filteredDevOpsItems = getFilteredDevOpsItems(
          state.devops_items,
          state.devops_search_text,
          state.devops_view_column_field_names,
          inlineFilters
        );

      return {
        ...state,
        selected_devops_inline_filters: inlineFilters,
        filtered_devops_items: filteredDevOpsItems,
      };
    }
    default:
      return state;
  }
};

export default reducer;

const injectTileIdAsNeeded = (configItem) => {
  configItem &&
    configItem.reports &&
    configItem.reports.forEach((report) => {
      injectTileIdForTiles(report.tiles);
    });

  configItem && injectTileIdForTiles(configItem.tiles);
};

const injectTileIdForTiles = (tiles) => {
  tiles &&
    tiles.forEach((tile, index) => {
      // Inject tile id if not already defined.
      if (!tile.tileId) {
        tile.tileId = new Date().valueOf() + "." + index;
      }
    });
};

const getIncidentViewSortSettings = (selectedView) => {
  let sortedColumnKey = "createDate",
    sortedDescending = true;

  selectedView &&
    selectedView.columns &&
    selectedView.columns.forEach((column) => {
      column.isSorted && (sortedColumnKey = column.fieldName);
      column.isSortedDescending && (sortedDescending = true);
    });

  return { sortedColumnKey, sortedDescending };
};

const setSearchFields = (state) => {
  let anchorEntityTypes = getAnchorEntityTypes(state.entity_views, state.selected_entity_view_id, state.entity_types),
    searchEntityType = state.search_entity_type;
  state.metric_search_fields = getSearchFields(anchorEntityTypes, state.service_content_configs);
  state.entity_search_fields = getSearchFields(
    searchEntityType ? [searchEntityType] : state.entity_types,
    state.service_content_configs
  );
  state.search_view_fields = getSearchViewFields(state.entity_views, state.selected_entity_view_id);
  state.search_view_filters = getSearchViewFilters(state.search_view_fields);
};

const getSearchFilters = (state): ISearchFilter[] => {
  let isEntitySearch = state.search_item_key === SearchItemKey.entitySearch,
    searchFilters = isEntitySearch ? state.entity_search_filters : state.metric_search_filters;
  return JSON.parse(JSON.stringify(searchFilters));
};

const getSearchFiltersState = (state, searchFilters) => {
  let isEntitySearch = state.search_item_key === SearchItemKey.entitySearch;
  return {
    ...state,
    metric_search_filters: isEntitySearch ? state.metric_search_filters : searchFilters,
    entity_search_filters: isEntitySearch ? searchFilters : state.entity_search_filters,
  };
};

const getDefaultEntitySubPaneKey = (entityViews: IEntityView[], selectedViewId: string): string =>
  entityViews?.find((v) => v.id === selectedViewId)?.defaultEntitySubPaneKey || EntitySubPaneKey.Metrics;

const setupDefaultSearchFilters = (state) => {
  // Set the default filters for the selected entity view.
  const defaultMetricSearchFilters = getDefaultSearchFilters(
    state.metric_search_fields,
    state.entity_views,
    state.selected_entity_view_id
  );
  state.metric_search_filters = defaultMetricSearchFilters;
  state.default_search_filters = defaultMetricSearchFilters;
  state.entity_search_filters = getDefaultSearchFilters(state.entity_search_fields);
};

const updateConfigItemForNonGlobalEnv = (
  configItem: object,
  environmentFields: IField[],
  configItemGlobal: object
): boolean => {
  // If configItem has only the id field, assume this is the first time creating a environment-specific copy of the config item.
  // Walk through the environment fields to copy the environment-specific config values.
  const itemKeys = Object.keys(configItem);

  if (itemKeys?.length !== 1 || itemKeys[0] !== "id") {
    return false; // No need to do any action if configItem already has some field values in it.
  }

  updateConfigItemForNonGlobalEnvInternal(configItem, environmentFields, configItemGlobal);

  return true;
};

const updateConfigItemForNonGlobalEnvInternal = (
  configItem: object,
  environmentFields: IField[],
  configItemGlobal: object
) => {
  environmentFields?.forEach((environmentField) => {
    let fieldName = environmentField.fieldName,
      globalValue = configItemGlobal[fieldName];

    if (globalValue && Array.isArray(globalValue) && globalValue.length) {
      configItem[fieldName] = [];

      globalValue.forEach((arrayItem) => {
        let arrayItemConfig = {};

        if (Array.isArray(environmentField.fields) && environmentField.fields?.length) {
          updateConfigItemForNonGlobalEnvInternal(arrayItemConfig, environmentField.fields, arrayItem);
        }

        configItem[fieldName].push(arrayItemConfig);
      });
    } else if (typeof globalValue === "object") {
      configItem[fieldName] = {};

      if (Array.isArray(environmentField.fields) && environmentField.fields?.length) {
        updateConfigItemForNonGlobalEnvInternal(configItem[fieldName], environmentField.fields, globalValue);
      }
    } else {
      configItem[fieldName] = globalValue;
    }
  });
};

const updateControlResult = (currentResult, newResult) => {
  let resultDetail = {};

  try {
    resultDetail = JSON.parse(newResult.resultDetail);
  } catch {}

  return {
    ...currentResult,
    ...newResult,
    ...resultDetail,
    impactScore: newResult.impactScore !== undefined ? newResult.impactScore : newResult.status === "pass" ? 0 : 10,
  };
};
