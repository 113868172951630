import React, { useEffect, useState } from "react";
import TileDetailsList from "../../shared/components/Tile/TileDetailsList";
import classNames from "./HealthReport.module.scss";
import { CheckboxVisibility, IColumn, SelectionMode, Spinner } from "@fluentui/react";
import { fetchControlErrors } from "../../api/radarApi";
import { BuiltInCommandBarItemKey } from "../../shared/components/Tile";
import { ContentDisplayType, getFormatContent } from "../../shared/utilities/miscHelper";

const daysAgo = 30;

export interface IControlErrorsProps {
  controlId: string;
}

export const ControlErrors = (props: IControlErrorsProps) => {
  const { controlId } = props;
  const [errors, setErrors] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (controlId) {
      setLoading(true);
      fetchControlErrors(controlId, daysAgo)
        .then((errors: any) => setErrors(errors))
        .catch(() => setErrors(undefined))
        .finally(() => setLoading(false));
    } else {
      setErrors(undefined);
    }
  }, [controlId]);

  return (
    <>
      {loading ? (
        <Spinner label="Loading control errors..." />
      ) : !!errors?.length ? (
        <TileDetailsList
          title={`Control Query Errors History (last ${daysAgo}d)`}
          classNameForDetailsList={classNames.list}
          items={errors}
          columns={columns}
          compact={false}
          selectionMode={SelectionMode.none}
          checkboxVisibility={CheckboxVisibility.hidden}
          commandBarItemKeys={[BuiltInCommandBarItemKey.toggleContent]}
        />
      ) : (
        <div className={classNames.noErrorMsg}>No query error found for this control in the last {daysAgo} days.</div>
      )}
    </>
  );
};

export default ControlErrors;

const columns: IColumn[] = [
  {
    fieldName: "timestamp",
    key: "timestamp",
    name: "Timestamp",
    minWidth: 160,
    maxWidth: 180,
    isResizable: true,
    onRender: (item) => getFormatContent(item, ContentDisplayType.datetime, "timestamp"),
  },
  {
    fieldName: "error",
    key: "error",
    name: "Error",
    minWidth: 3000,
    isResizable: true,
  },
];
