import React, { useContext, useMemo, useState } from "react";
import { connect } from "react-redux";
import AppModuleHeader from "../common/AppModuleHeader";
import { IState } from "../../reducers/interfaces";
import { getCommandBarProps } from "./HealthReport.helper";
import { actionCreator } from "../duck";
import { IControlResult } from "./interfaces";
import { IComment, ILinkedItem } from "../common/interfaces";
import { IBreadcrumbItem } from "@fluentui/react/lib/Breadcrumb";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { History } from "react-router-dom/node_modules/history";
import { ControlContext } from "./HealthReport";
import { getUserId } from "../common/helper";
import { startControlRun } from "../../api/radarApi";
import { INavLink, Spinner } from "@fluentui/react";
import classNames from "./HealthReport.module.scss";
import AlertModal from "../common/AlertModal";
import { SystemMessages } from "../common/SystemMessages";
import { ISystemMessage, IUserInfo } from "../../app/interfaces";
import {
  getIcmSettingWarningSystemMessage,
  shouldShowIcmSettingWarning,
  shouldShowNarrativeWarning,
  shouldShowLinkedDocWarning,
} from "../management/control/MyControlEdit.helper";

export interface IHealthReportHeaderStateProps {
  isSmallScreen: boolean;
  selectedControl: IControlResult;
  controlResults: IControlResult[];
  selectedControlIndex: number;
  isSuperAdmin: boolean;
  userId: string;
  selectedLeftNavLink?: INavLink;
  userInfo: IUserInfo;
  loadingControlData?: boolean;
}

export interface IHealthReportHeaderDispatchProps {
  selectAllControls: () => void;
  unselectControl: () => void;
  loadControl: (controlId: string, refreshData?: boolean) => void;
  loadControlResultItems: (control: IControlResult, controlResultId?: string, refreshData?: boolean) => void;
  loadControlViews: (controlViewIds: string, refreshData?: boolean, hasControls?: boolean, controlId?: string) => void;
  editControlComment: (comment: IComment) => void;
  editControlLinkedItem: (linkedItem: ILinkedItem) => void;
}

export interface IHealthReportHeaderProps extends IHealthReportHeaderStateProps, IHealthReportHeaderDispatchProps {
  onShowControlSearch: () => void;
  history: History;
  error?: string;
}

export const HealthReportHeader = (props: IHealthReportHeaderProps) => {
  const { viewId, controlId } = useContext(ControlContext);
  const { selectedControl, history, selectedLeftNavLink, error, loadingControlData, selectAllControls } = props;
  const [modalContent, setModalContent] = useState<React.ReactNode>();
  const headerItems: IBreadcrumbItem[] = [];
  const isUserRestricted = selectedControl?.isRestricted && !selectedControl?.doesUserHasFullMSSalesAccess;
  const systemMessages: ISystemMessage[] = [];

  const onControlRun = () => {
    setModalContent(<Spinner label="Starting control run..." />);

    startControlRun(controlId)
      .then(() => setModalContent(<div className={classNames.info}>Control run on demand has started.</div>))
      .catch((error) => setModalContent(<div className={classNames.error}>{error}</div>));
  };

  const commandBarProps = useMemo(
    () => getCommandBarProps(props, viewId, controlId, isUserRestricted, onControlRun),
    // eslint-disable-next-line
    [props.selectedControl?.id, props.isSuperAdmin, viewId, controlId]
  );

  let headerText = viewId ? "Control View" : "Control";

  if (!error && controlId) {
    headerText = selectedControl?.name || selectedLeftNavLink?.name || headerText;
  }

  headerItems.push({
    text: headerText,
    key: "",
  });

  const onBreadcrumbClick = () => {
    selectAllControls();
    history.push(`${leftNavUrls.all.controls}/${viewId}`);
  };

  if (controlId && selectedControl) {
    if (selectedControl?.offline) {
      var offlineReason = selectedControl?.offlineReason ? `Reason: ${selectedControl?.offlineReason}` : "";
      systemMessages.push({ messageType: "warning", message: `This control is currently offline. ${offlineReason}` });
    }

    if (shouldShowIcmSettingWarning(selectedControl)) {
      systemMessages.push(getIcmSettingWarningSystemMessage(selectedControl));
    }

    if (!loadingControlData) {
      if (shouldShowNarrativeWarning(selectedControl)) {
        systemMessages.push({
          messageType: "warning",
          message:
            "This SOX control with Onboarding Status = 'In Production' is missing the required Narrative document.  Please add a new Narrative using the 'Add Linked Item' option on the top menu.",
        });
      }

      if (shouldShowLinkedDocWarning(selectedControl)) {
        systemMessages.push({
          messageType: "warning",
          message:
            "This control with Onboarding Status = 'In Production' is missing any linked document.  Please add a new Narrative, OnePager, and/or TSG using the 'Add Linked Item' option on the top menu.",
        });
      }
    }
  }

  return (
    <>
      <AppModuleHeader
        className={classNames.appModuleHeader}
        commandBarProps={commandBarProps}
        onBreadcrumbClick={viewId && selectedControl ? onBreadcrumbClick : null}
        breadcrumbItems={headerItems}
        messageContent={<SystemMessages systemMessages={systemMessages} />}
      />
      {modalContent && <AlertModal onCommit={() => setModalContent(undefined)}>{modalContent}</AlertModal>}
    </>
  );
};

const mapStateToProps = (state: IState): IHealthReportHeaderStateProps => ({
  isSmallScreen: state.app.is_small_screen,
  selectedControl: state.modules.selected_control,
  controlResults: state.modules.control_results,
  selectedControlIndex: state.modules.selected_control_index,
  isSuperAdmin: state.app.login_user_info?.isSuperAdmin,
  userId: getUserId(state.app.login_user),
  selectedLeftNavLink: state.app.selected_left_nav_link,
  userInfo: state.app.login_user_info,
  loadingControlData:
    state.modules.loading_controls ||
    state.modules.loading_control_results ||
    state.modules.loading_control_result_details,
});

const mapDispatchToProps = {
  selectAllControls: actionCreator.selectAllControls,
  unselectControl: actionCreator.unselectControl,
  loadControl: actionCreator.loadControl,
  loadControlResultItems: actionCreator.loadControlResultItems,
  loadControlViews: actionCreator.loadControlViews,
  editControlComment: actionCreator.editControlComment,
  editControlLinkedItem: actionCreator.editControlLinkedItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthReportHeader);
