/// <reference path="../../../typings/global.d.ts" />

import React, { Component, CSSProperties } from "react";
import { INode, IMetric } from "./interfaces";
import * as helper from "./helper";
import { StandardColorStyles } from "../../utilities/colorHelper";
import Icon from "./Icon";
import NodeTooltip from "./NodeTooltip";
import Metric from "./Metric";
import classNames from "./Node.module.scss";

const mouseMoveBuffer = 4; // Mouse moved less than this number of pixel is ignored.

export interface INodeProps {
  node: INode;
  children?: React.ReactNode;
  onClick?: (node: INode) => void;
  onMoved?: (node: INode) => void;
}

export interface INodeState {
  dragging: boolean;
  x: number;
  y: number;
  showTooltip: boolean;
}

export class Node extends Component<INodeProps, INodeState> {
  private nodeElement = React.createRef<HTMLDivElement>();

  constructor(props: INodeProps) {
    super(props);

    const { node } = this.props;

    this.state = {
      dragging: false,
      x: node && node.x,
      y: node && node.y,
      showTooltip: false,
    };
  }

  componentDidUpdate(props: INodeProps, state: INodeState) {
    if (this.state.dragging && !state.dragging) {
      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.onMouseUp);
    } else if (!this.state.dragging && state.dragging) {
      document.removeEventListener("mousemove", this.onMouseMove);
      document.removeEventListener("mouseup", this.onMouseUp);
    }
  }

  render() {
    const { node, onClick: defaultOnClick } = this.props;

    if (!node) return null;

    const { x: dragX, y: dragY, dragging, showTooltip } = this.state;

    const {
      x,
      y,
      width,
      height,
      className,
      classNames: customClassNames = {},
      style,
      selected,
      metrics,
      tooltip,
      disabled,
      onClick = defaultOnClick,
    } = node;

    const nodeSelectionStyle = selected ? { opacity: 1 } : {};

    const rootStyle = {
      ...StandardColorStyles.blueDark,
      ...style,
      ...helper.getStyleByMeasurements(x, y, width, height),
      ...nodeSelectionStyle,
    };

    const rootClassNames = [classNames.root, customClassNames.root];
    const nodeClassNames = [classNames.node, customClassNames.node, className];

    selected && rootClassNames.push(classNames.selected);

    if (!disabled && onClick && !dragging) {
      nodeClassNames.push(classNames.clickable);
      nodeClassNames.push(customClassNames.clickable);
    } else if (!dragging) {
      nodeClassNames.push(classNames.draggable);
    }

    const dragStyle = {
        ...rootStyle,
        left: dragX - x,
        top: dragY - y,
      },
      dragClassNames = nodeClassNames.slice();

    dragClassNames.push(classNames.dragging);

    var tooltipMetrics: IMetric[] =
      metrics &&
      metrics.filter(
        (metric) => (metric.showInTooltip === true || metric.showInTooltip === "true") && metric.hidden !== true
      );

    let nodeProperties = {
      className: rootClassNames.join(" "),
      style: rootStyle,
      ref: this.nodeElement,
      role: "presentation",
      title: typeof tooltip === "string" ? tooltip : "",
      onMouseDown: this.onMouseDown,
      onMouseEnter: () => this.setState({ showTooltip: true }),
      onMouseLeave: () => this.setState({ showTooltip: false }),
    };
    if (!disabled) {
      nodeProperties = {
        ...nodeProperties,
        ...{
          role: "button",
          onClick: this.onNodeClick,
          onKeyUp: (ev: React.KeyboardEvent) => ev.which === 13 && this.onNodeClick(),
          tabIndex: 0,
        },
      };
    }

    return (
      <div {...nodeProperties}>
        {this.renderNode(nodeClassNames, {})}
        {/* For dragging scenario, show a duplicate of the node as ghost node while dragging. */}
        {dragging && this.renderNode(dragClassNames, dragStyle, true)}
        {this.renderInfoPane()}
        {showTooltip && typeof tooltip !== "string" && (
          <NodeTooltip children={tooltip} metrics={tooltipMetrics} target={this.nodeElement.current} />
        )}
      </div>
    );
  }

  renderNode = (nodeClassNames: string[], style: React.CSSProperties, isDragClone: boolean = false) => {
    const { node } = this.props;
    const { id, name = id, content, icon } = node;

    return (
      <div key={id + (isDragClone && "-drag")} className={nodeClassNames.join(" ")} style={style} id={id}>
        {content || (
          <div className={classNames.content}>
            <span className={[classNames.name].join(" ")} dangerouslySetInnerHTML={{ __html: name }} />
            {icon && <Icon className={classNames.icon} icon={icon} />}
          </div>
        )}
      </div>
    );
  };

  renderInfoPane = () => {
    const { node } = this.props;
    const { infoPane, metrics, height, width } = node;

    let content = null;

    if (infoPane?.content || metrics?.length) {
      let infoPaneClassNames = [classNames.infoPane],
        infoPaneStyle: CSSProperties = { top: height - 4 };

      if (infoPane?.position) {
        let position = infoPane.position;

        position === "right" && infoPaneClassNames.push(classNames.infoPaneRight);
        position === "left" && infoPaneClassNames.push(classNames.infoPaneLeft);

        position === "top" && (infoPaneStyle["bottom"] = height - 4);
        position === "top" && (infoPaneStyle["top"] = "auto");
        position === "right" && (infoPaneStyle["left"] = width - 4);
        position === "left" && (infoPaneStyle["right"] = width - 4);
        (position === "left" || position === "right") && (infoPaneStyle["top"] = (-16 * metrics.length) / 2);
      }

      var infoPaneMetrics: IMetric[] =
        metrics &&
        metrics.filter(
          (metric) => metric.showInTooltip !== true && metric.showInTooltip !== "true" && metric.hidden !== true
        );

      content = (
        <div className={infoPaneClassNames.join(" ")} style={infoPaneStyle}>
          {infoPane && infoPane.content
            ? infoPane.content
            : infoPaneMetrics &&
              infoPaneMetrics.length &&
              infoPaneMetrics.map((metric) => <Metric key={`node-metric-${node.id}-${metric.name}`} metric={metric} />)}
        </div>
      );
    }

    return content;
  };

  onMouseDown = (e: React.MouseEvent) => {
    const { draggable = true } = this.props.node;

    if (!draggable || e.button !== 0) return; // Only left mouse button to trigger drag.

    this.setState({ dragging: true });
    e.stopPropagation();
    e.preventDefault();
  };

  onMouseUp = (e: any) => {
    const { node, onMoved, onClick: defaultOnClick } = this.props;
    const { draggable = true, onClick = defaultOnClick, disabled } = node;
    const { dragging } = this.state;

    this.setState({ dragging: false });
    e.stopPropagation();
    e.preventDefault();

    if (
      dragging &&
      (Math.abs(node.x - this.state.x) > mouseMoveBuffer || Math.abs(node.y - this.state.y) > mouseMoveBuffer)
    ) {
      draggable &&
        onMoved &&
        onMoved({
          ...node,
          x: this.state.x,
          y: this.state.y,
        });
    } else {
      !disabled && onClick && onClick(node);
    }
  };

  onMouseMove = (e: any) => {
    if (!this.state.dragging) return;

    this.setState({
      x: this.state.x + e.movementX,
      y: this.state.y + e.movementY,
    });
    e.stopPropagation();
    e.preventDefault();
  };

  onNodeClick = () => {
    const { node, onClick: defaultOnClick } = this.props;
    const { onClick = defaultOnClick } = node;

    onClick && onClick(node);
  };
}

export default Node;
