import React from "react";
import { Callout, Target } from "@fluentui/react/lib/Callout";
import { IMetric } from "./interfaces";
import Metric from "./Metric";
import classNames from "./Node.module.scss";

export interface INodeTooltipProps {
  content?: string;
  metrics?: IMetric[];
  target?: Target;
  children?: React.ReactNode;
}

export class NodeTooltip extends React.Component<INodeTooltipProps> {
  render() {
    const { target, content, metrics } = this.props;

    if (!content && (!metrics || !metrics.length)) return null;

    return (
      <Callout className={classNames.nodeTooltip} target={target}>
        {content && <div className={classNames.tooltipContent} dangerouslySetInnerHTML={{ __html: content }} />}
        {content && metrics && !!metrics.length && <div className={classNames.spacing} />}
        {metrics &&
          !!metrics.length &&
          metrics.map((metric) => (
            <Metric
              key={`node-tooltip-metric-${metric.name}`}
              metric={metric}
              itemClassName={classNames.tooltipMetric}
            />
          ))}
      </Callout>
    );
  }
}

export default NodeTooltip;
