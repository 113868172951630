import React, { useEffect } from "react";
import classNames from "./CopilotHome.module.scss";
import CopilotPane, { CopilotMode } from "./CopilotPane";
import { actionCreator } from "../../app/duck";
import { connect } from "react-redux";

export interface ICopilotHomeStateProps {
  collapseLeftNav: () => void;
}

export const CopilotHome = (props: ICopilotHomeStateProps) => {
  const { collapseLeftNav } = props;

  useEffect(() => {
    collapseLeftNav();
  }, [collapseLeftNav]);

  return (
    <div className={classNames.appModuleContent}>
      <CopilotPane
        className={classNames.copilotPane}
        copilotMode={CopilotMode.Immersive}
        hideResizeButtons
        largeHeaderText
        useFloatingInputPane
      />
    </div>
  );
};

const mapDispatchToProps = {
  collapseLeftNav: actionCreator.collapseLeftNav,
};

export default connect(null, mapDispatchToProps)(CopilotHome);
