import React, { useEffect, useRef, useState } from "react";
import classNames from "./Chat.module.scss";
import { ChatRole, IChatMessage } from "./Chat.types";
import ChatMessage from "./ChatMessage";
import { IconButton, Spinner, SpinnerSize } from "@fluentui/react";
import ChatSuggestion from "./ChatSuggestion";

export interface IChatProps {
  chatMessages: IChatMessage[];
  userPhoto?: string;
  className?: string;
  messagesClassName?: string;
  loadingData?: boolean;
  convertMarkdown?: boolean;
  suggestions?: string[];
  maxSuggestions?: number;
  useFloatingInputPane?: boolean;
  onSendRequested?: (message: string) => void;
  onResetMessages?: () => void;
  onSuggestionClick?: (message: string) => void;
  onMessageThumbsUp?: (IChatMessage) => void;
  onMessageThumbsDown?: (IChatMessage) => void;
}

export const Chat = (props: IChatProps) => {
  const {
    chatMessages,
    userPhoto,
    className,
    messagesClassName,
    loadingData,
    convertMarkdown,
    suggestions,
    maxSuggestions = 3,
    useFloatingInputPane,
    onSendRequested,
    onResetMessages,
    onSuggestionClick,
  } = props;
  const [userInputText, setUserInputText] = useState<string>();
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const onUserInputChange = (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUserInputText(ev.target.value);
  };

  const onUserInputKeyDown = (ev: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!ev.shiftKey && ev.key === "Enter") {
      onSendButtonClick();
      ev.preventDefault();
    }
  };

  const onSendButtonClick = () => {
    if (userInputText) {
      onSendRequested(userInputText);
      setUserInputText("");
    }
  };

  const rootClassNames = [classNames.root, className];
  const userInputClassNames = [classNames.userInput];

  if (!chatMessages?.length) {
    rootClassNames.push(classNames.noMessage);
  }

  if (useFloatingInputPane) {
    userInputClassNames.push(classNames.floatingInput);
  }

  return (
    <div className={rootClassNames.join(" ")}>
      {!!chatMessages?.length && (
        <div className={`${classNames.messages} ${messagesClassName}`}>
          {!!chatMessages?.length &&
            chatMessages
              .filter((message) => !message.hidden)
              .map((message) => (
                <ChatMessage chatMessage={message} userPhoto={userPhoto} convertMarkdown={convertMarkdown} />
              ))}
          {!!suggestions?.length &&
            suggestions.map(
              (suggestion, index) =>
                index < maxSuggestions && (
                  <ChatSuggestion content={suggestion} showIcon={index === 0} onSuggestionClick={onSuggestionClick} />
                )
            )}
          {loadingData && (
            <ChatMessage
              chatMessage={{
                role: ChatRole.system,
                content: (
                  <div className={classNames.waitMessage}>
                    <span>Just a moment</span>
                    <Spinner size={SpinnerSize.xSmall} />
                  </div>
                ),
              }}
              convertMarkdown={convertMarkdown}
            />
          )}
          <div ref={messagesEndRef} />
          {!!chatMessages?.length && (
            <div className={classNames.disclaimerText}>
              Copilot is powered by AI, so surprises and mistakes are possible. Please verify facts from the respective
              team before any decision making.
            </div>
          )}
        </div>
      )}
      <div className={userInputClassNames.join(" ")}>
        <textarea
          rows={5}
          value={userInputText}
          placeholder="Type user question here. Shift-Enter for new line."
          onChange={onUserInputChange}
          onKeyDown={onUserInputKeyDown}
        />
        <div className={classNames.buttonsPane}>
          <IconButton
            className={classNames.sendButton}
            iconProps={{ iconName: "Send" }}
            title="Send user message"
            onClick={onSendButtonClick}
          />
          <IconButton
            className={classNames.resetButton}
            iconProps={{ iconName: "Refresh" }}
            title="Reset messages"
            onClick={onResetMessages}
          />
        </div>
      </div>
    </div>
  );
};

export default Chat;
