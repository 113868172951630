import { Icon } from "@fluentui/react";
import React, { useCallback, useMemo } from "react";
import { ITeam } from "../common/interfaces";
import classNames from "./HealthDashboard.module.scss";
import { IHealthMetricGroup } from "./HealthDashboard.types";

export interface IHealthDashboardLinksProps {
  metricGroup: IHealthMetricGroup;
  teams?: ITeam[];
}

export const HealthDashboardLinks = (props: IHealthDashboardLinksProps) => {
  const { teams, metricGroup } = props;

  const getLinksContent = useCallback((linksContent, links, linkKey = "") => {
    links?.forEach((link, index) => {
      link.url &&
        linksContent.push(
          <div key={`healthlink-${linkKey}-${index}`} className={classNames.link}>
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.name || link.url}
            </a>
            <Icon iconName="link" className={classNames.icon} />
          </div>
        );
    });
  }, []);

  const linksContent = useMemo(() => {
    let linksContent = [];

    getLinksContent(linksContent, metricGroup.links, metricGroup.id?.toString());

    teams?.forEach((team) => {
      let metricGroupTeamSettings = metricGroup?.teams?.find((metricGroupTeam) => metricGroupTeam.id === team.id),
        links = metricGroupTeamSettings?.links;

      getLinksContent(linksContent, links, team.id);
    });

    return linksContent;
  }, [teams, metricGroup, getLinksContent]);

  return <div className={classNames.links}>{linksContent}</div>;
};

export default HealthDashboardLinks;
