import { ICommandBarProps } from "@fluentui/react/lib/CommandBar";
import { IHealthReportHeaderProps } from "./HealthReportHeader";
import { getPagingCommandBarItems, getBasicPageCommandBarItems, isUserOwnerOrEditor } from "../common/helper";
import { IControl, IControlView } from "./interfaces";
import { leftNavUrls } from "../../app/LeftNav.helper";
import { checkForValue } from "../../shared/utilities/miscHelper";
import { IUserInfo } from "../../app/interfaces";
import { isUserSoxAuditor } from "../../app/App.helper";

export const controlType = {
  Anomaly: "Anomaly",
  Change: "Change",
  Custom: "Custom",
  Dynamic: "Dynamic",
  External: "External",
  EntityMissingSla: "Entity Missing SLA",
  EntityValidation: "Entity Validation",
  SDK: "SDK",
  Recon: "Recon",
  Outlier: "Outlier",
};

export const onboardingStatus = [
  "In Draft",
  "Pending Data",
  "In Review",
  "Ready For Production",
  "In Production",
  "Inactive",
  "POC",
];

export const getOnboardingStatusText = (item: any): string => {
  let statusIndex = Number(item?.onboardingStatus);
  return statusIndex < onboardingStatus.length ? onboardingStatus[statusIndex] : "undefined";
};

export const getContactMessageSubject = (control: IControl): string => `Commerce Radar Control: ${control.name}`;

export const getCommandBarProps = (
  props: IHealthReportHeaderProps,
  viewId: string,
  controlId: string,
  isUserRestricted: boolean,
  onControlRun: () => void
): ICommandBarProps => {
  const {
    isSmallScreen,
    loadControlViews,
    loadControl,
    loadControlResultItems,
    selectedControl,
    unselectControl,
    selectedControlIndex,
    editControlComment,
    editControlLinkedItem,
    onShowControlSearch,
    history,
    isSuperAdmin,
    userId,
    controlResults,
    userInfo,
  } = props;

  const canRun = isSuperAdmin || isUserOwnerOrEditor(selectedControl, userId);

  const basicPageCommandBarActions = {
    refresh: () => (controlId ? loadControl(controlId, true) : loadControlViews(viewId, true)),
  };

  const commandBarProps: ICommandBarProps = {
    items: [],
    farItems: [
      ...getBasicPageCommandBarItems(isSmallScreen, basicPageCommandBarActions, userInfo?.isNonGlobalEnvironment),
    ],
  };

  if (controlId && !isUserRestricted && !userInfo?.isNonGlobalEnvironment) {
    const controlAdminCommands = [
      {
        key: "search",
        name: "Search",
        title: "Search This control",
        iconProps: {
          iconName: "Search",
        },
        iconOnly: isSmallScreen,
        onClick: onShowControlSearch,
      },
      {
        key: "history",
        name: "Change History",
        title: "Change History",
        iconProps: {
          iconName: "History",
        },
        iconOnly: isSmallScreen,
        onClick: (ev) => {
          ev.stopPropagation();
          history.push(`${leftNavUrls.management.configHistory}/controls/${controlId}`);
        },
      },
      {
        key: "addComment",
        name: "Add Comment",
        title: "Add Comment",
        iconProps: {
          iconName: "CommentAdd",
        },
        iconOnly: isSmallScreen,
        onClick: (ev) =>
          editControlComment({
            controlIds: selectedControl?.controlId || controlId,
            controlResultIds: selectedControl?.controlResultId,
          }),
      },
      {
        key: "addLinkedItem",
        name: "Add Linked Item",
        title: "Add Linked Item",
        iconProps: {
          iconName: "CloudAdd",
        },
        iconOnly: isSmallScreen,
        onClick: (ev) =>
          editControlLinkedItem({
            controlIds: selectedControl?.controlId || controlId,
            controlResultIds: selectedControl?.controlResultId,
            settings: "",
          }),
      },
    ];

    if (canRun) {
      controlAdminCommands.unshift({
        key: "editControl",
        name: "Edit",
        title: "Edit config for this control",
        iconProps: {
          iconName: "Edit",
        },
        iconOnly: isSmallScreen,
        onClick: () => window.open(`/#${leftNavUrls.management.control}/${controlId}?staging`, "_blank"),
      });

      if (selectedControl?.isManaged) {
        controlAdminCommands.unshift({
          key: "runControl",
          name: "Run",
          title: "Run this control on demand",
          iconProps: {
            iconName: "Play",
          },
          iconOnly: isSmallScreen,
          onClick: onControlRun,
        });
      }
    } else if (isUserSoxAuditor(userInfo)) {
      controlAdminCommands.unshift({
        key: "viewConfig",
        name: "View Config",
        title: "View config for this control",
        iconProps: {
          iconName: "View",
        },
        iconOnly: isSmallScreen,
        onClick: () => window.open(`/#${leftNavUrls.management.control}/${controlId}`, "_blank"),
      });
    }

    if (checkForValue(selectedControl?.soxDocumentation)) {
      controlAdminCommands.unshift({
        key: "soxDocumentation",
        name: "SOX Documentation",
        title: "SOX Documentation",
        iconProps: {
          iconName: "KnowledgeArticle",
        },
        iconOnly: isSmallScreen,
        onClick: (ev) => {
          ev.stopPropagation();
          history.push(`${leftNavUrls.all.complianceDocs}/${selectedControl.soxDocumentation}`);
        },
      });
    }

    commandBarProps.farItems = [
      ...controlAdminCommands,
      ...commandBarProps.farItems,
      ...(!isUserSoxAuditor(userInfo)
        ? getPagingCommandBarItems(
            history,
            "Control",
            unselectControl,
            `${leftNavUrls.all.controls}/${viewId}`,
            controlResults,
            selectedControlIndex,
            loadControlResultItems
          )
        : []),
    ];
  }

  return commandBarProps;
};

export const getDateStringByAbbreviation = (dateAbbreviation: string): string => {
  // At least two chars, e.g. 2h for 2 hours, 5d for 5 days.
  if (!dateAbbreviation || dateAbbreviation.length < 2) return dateAbbreviation;

  let lastChar = dateAbbreviation[dateAbbreviation.length - 1],
    number = parseInt(dateAbbreviation.substring(0, dateAbbreviation.length - 1)),
    dateString = "";

  if (!isNaN(number)) {
    if (lastChar === "y") {
      dateString = `${number} year`;
    } else if (lastChar === "m") {
      dateString = `${number} month`;
    } else if (lastChar === "d") {
      dateString = `${number} day`;
    } else if (lastChar === "h") {
      dateString = `${number} hour`;
    }
    return dateString + (number > 1 ? "s" : "");
  }
  return dateAbbreviation;
};

export const getEntityIds = (controlViews: IControlView[]): string => {
  let ids = [];

  controlViews &&
    controlViews.forEach((controlView) => {
      controlView && controlView.nodes && controlView.nodes.forEach((node) => ids.push(node.id));
    });

  return ids.join(",");
};

export const filterControlResults = (controlResults: any[], userInfo: IUserInfo): any[] => {
  if (controlResults?.length) {
    // Filter out the inactive controls.
    controlResults = controlResults.filter((control) => control.onboardingStatus !== 5); // Skip Inactive controls
  }

  return controlResults;
};
