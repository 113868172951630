import React, { createContext, useCallback, useContext, useReducer } from "react";
import AppRoutes from "./AppRoutes";
import classNames from "./AppModule.module.scss";
import CopilotPane, { CopilotPaneSize } from "../copilot/CopilotPane";
import { AppContext } from "../../app/App";
import { IChatMessage } from "../../shared/components/Chat/Chat.types";

const copilotPaneSizeWidthMin = 40;
const copilotPaneSizeWidthNormal = 340;

var appModulePaneElement: HTMLElement;

// Note: Separating IAppModuleState and IAppModuleContext so we can update multiple state props with one single context prop call.
export interface IAppModuleState {
  copilotDataText?: string;
  copilotSuggestions?: string[];
  copilotExtraChatMessages?: IChatMessage[];
  copilotInitialMessages?: IChatMessage[];
  copilotInitialMessageCount?: number;
  copilotFollowupMessageCount?: number;
  copilotPaneSize?: CopilotPaneSize;
  appModuleName?: string;
}

export interface IAppModuleContext {
  appModuleState?: IAppModuleState;
  changeAppModuleState?: (newState) => void;
}

export const AppModuleContext = createContext<IAppModuleContext>({});

// This component is created to anticipate common functions across all AppModules, e.g. common alert pane in the future.
export const AppModule = () => {
  const [appModuleState, setAppModuleState] = useReducer((state, newState) => ({ ...state, ...newState }), {});
  const { appState } = useContext(AppContext);
  const { showCopilot, showCopilotOverride, featureFlags } = appState;
  const { copilotPaneSize = CopilotPaneSize.normal } = appModuleState;
  const showCopilotFinal =
    !featureFlags?.disableCopilot && (showCopilotOverride !== undefined ? showCopilotOverride : showCopilot);
  const appModulePaneWidth = appModulePaneElement?.clientWidth;

  const copilotPaneWidth =
    copilotPaneSize === CopilotPaneSize.min
      ? copilotPaneSizeWidthMin
      : copilotPaneSize === CopilotPaneSize.normal
      ? copilotPaneSizeWidthNormal
      : copilotPaneSize === CopilotPaneSize.large
      ? appModulePaneWidth / 2
      : appModulePaneWidth;

  const copilotPanePosX = appModulePaneWidth - copilotPaneWidth;

  const changeAppModuleState = useCallback((newState) => setAppModuleState(newState), []);

  return (
    <AppModuleContext.Provider value={{ appModuleState, changeAppModuleState }}>
      <div className={classNames.root} ref={(el) => (appModulePaneElement = el)}>
        {copilotPaneSize !== CopilotPaneSize.max && (
          <div className={classNames.contentPane} style={{ width: showCopilotFinal ? copilotPanePosX : "100%" }}>
            <AppRoutes />
          </div>
        )}
        {showCopilotFinal && <CopilotPane className={classNames.copilotPane} style={{ left: copilotPanePosX }} />}
      </div>
    </AppModuleContext.Provider>
  );
};

export default AppModule;
