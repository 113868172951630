import { IColumn, IDropdownOption, TooltipHost } from "@fluentui/react";
import React from "react";
import { startCase } from "../../shared/utilities/miscHelper";
import { ITeam } from "../common/interfaces";
import classNames from "./StreamsHome.module.scss";
import moment from "moment";
import { CopilotChatDataTextMaxSize } from "../copilot/CopilotPane";

export const statusTextDelayed = "Delayed";
export const statusTextOnTime = "On Time";
export const statusTextCompleted = "Completed";
export const statusTextSkipped = "Skipped";
export const statusTextHealthy = "Healthy";
export const statusTextUnhealthy = "Unhealthy";

export const mergeStreamSchedulesAndRuns = (streamSchedules: any[], streamRuns: any[]): any[] => {
  let finalStreamRuns = [];

  // Merge all stream runs with the corresponding stream schedules, if any.
  streamRuns?.forEach((run) => {
    let relatedSchedules = streamSchedules?.filter(
      (schedule) => schedule.id?.toLowerCase() === run.scheduleId?.toLowerCase()
    );

    if (relatedSchedules?.length >= 1) {
      let schedule = relatedSchedules[0];
      finalStreamRuns.push({ ...run, scheduleName: schedule.scheduleName });
    } else {
      finalStreamRuns.push(run);
    }
  });

  //Sort by scheduledStartTime or eventTimestamp
  finalStreamRuns.sort((a, b) => {
    let timeA = formatDateTime(a.scheduledStartTime?.trim() || a.eventTimestamp?.trim()),
      timeB = formatDateTime(b.scheduledStartTime?.trim() || b.eventTimestamp?.trim());

    return timeA < timeB ? 1 : timeA > timeB ? -1 : 0;
  });

  return finalStreamRuns;
};

export const getStreamOptions = (streams: any[], fieldName: string): IDropdownOption[] => {
  let streamOptions = streams
    .map((schedule) => schedule[fieldName])
    .filter((fieldValue, i, array) => fieldValue && array.indexOf(fieldValue) === i)
    .map((fieldValue) => ({ key: fieldValue, text: startCase(fieldValue) }))
    .sort((a, b) => (a.text > b.text ? 1 : a.text < b.text ? -1 : 0));

  streamOptions.unshift({ key: "", text: "All" });

  return streamOptions;
};

export const processStreamRuns = (streamRuns: any[]) => {
  streamRuns?.forEach((run) => {
    run.status = getStatusText(run);
  });
};

export const getUpStreamRuns = (streamRuns: any[], selectedTeam: ITeam, streamSchedules: any[]): any[] => {
  if (!selectedTeam) {
    return null;
  }

  var filteredRuns = streamRuns?.filter((run) => {
    if (selectedTeam.upStreams?.length) {
      for (let i = 0; i < selectedTeam.upStreams.length; i++) {
        let upStream = selectedTeam.upStreams[i];

        if (
          Number(upStream?.teamId) === Number(run?.teamId) &&
          upStream?.streamId?.toLowerCase() === run?.streamId?.toLowerCase()
        ) {
          return true;
        }
      }
    }
    return false;
  });

  return mergeStreamSchedulesAndRuns(streamSchedules, filteredRuns);
};

export const getDownStreamRuns = (streamRuns: any[], selectedTeam: ITeam, streamSchedules: any[]): any[] => {
  if (!selectedTeam) {
    return mergeStreamSchedulesAndRuns(streamSchedules, streamRuns);
  }

  var filteredRuns = streamRuns?.filter((run) => Number(selectedTeam.id) === Number(run.teamId));

  return mergeStreamSchedulesAndRuns(streamSchedules, filteredRuns);
};

export const timeFilterOptions: IDropdownOption[] = [
  { key: 1, text: "Since yesterday" },
  { key: 3, text: "Since 3 days ago" },
  { key: 7, text: "Since 7 days ago" },
  { key: 14, text: "Since 14 days ago" },
  { key: 30, text: "Since 30 days ago" },
];

export const getStreamColumns = (runs: any[]): IColumn[] => {
  let columns: IColumn[] = [
    {
      key: "teamName",
      fieldName: "teamName",
      name: "Team",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
    },
    {
      key: "scheduleName",
      fieldName: "scheduleName",
      name: "Schedule Name",
      minWidth: 160,
      isResizable: true,
      isMultiline: true,
    },
    {
      key: "streamId",
      fieldName: "streamId",
      name: "Stream Id",
      minWidth: 130,
      maxWidth: 180,
      isResizable: true,
      isMultiline: true,
      styles: { root: { wordWrap: "break-word" } },
      onRender: (item) => (
        <TooltipHost
          id={`tooltip-${item["scheduleId"]}-${item["id"]}}`}
          tooltipProps={{
            onRenderContent: () => (
              <span>
                <b>Run Id</b>: {item["id"]}
                {item["scheduleId"] && (
                  <>
                    <br />
                    <b>Schedule Id</b>: {item["scheduleId"]}
                  </>
                )}
              </span>
            ),
          }}
        >
          {item["streamId"]}
        </TooltipHost>
      ),
    },
    {
      key: "status",
      fieldName: "status",
      name: "Status",
      minWidth: 75,
      maxWidth: 85,
      isResizable: true,
      onRender: (item) => {
        let statusText = item.status,
          sla = item["sla"],
          actualSla = item["actualSla"],
          estimatedSla = item["estimatedSla"],
          statusColor =
            statusText?.toLowerCase() === statusTextDelayed.toLowerCase() ||
            statusText?.toLowerCase() === statusTextUnhealthy.toLowerCase()
              ? "goldenrod"
              : statusText?.toLowerCase() === statusTextCompleted.toLowerCase()
              ? "dodgerblue"
              : statusText?.toLowerCase() === statusTextSkipped.toLowerCase()
              ? "grey"
              : "green",
          runId = item["id"],
          tooltipId = "tooltip-" + runId;

        return (
          statusText && (
            <TooltipHost
              id={tooltipId}
              tooltipProps={{
                hidden:
                  statusText?.toLowerCase() === statusTextHealthy.toLowerCase() ||
                  statusText?.toLowerCase() === statusTextUnhealthy.toLowerCase(),
                onRenderContent: () => (
                  <span>
                    <b>Expected SLA</b>: {sla}
                    <br />
                    <b>Actual SLA</b>: {actualSla}
                    <br />
                    <b>Estimated SLA</b>: {estimatedSla}
                  </span>
                ),
              }}
            >
              <div
                className={classNames.streamStatusText}
                style={{ backgroundColor: statusColor }}
                aria-describedby={tooltipId}
              >
                {statusText}
              </div>
            </TooltipHost>
          )
        );
      },
    },
    {
      key: "state",
      fieldName: "state",
      name: "State",
      minWidth: 70,
      maxWidth: 80,
      isResizable: true,
      onRender: (item) => (item["state"] ? startCase(item["state"]) : ""),
    },
    {
      key: "progressPercentage",
      fieldName: "progressPercentage",
      name: "Progress",
      minWidth: 50,
      maxWidth: 50,
      isResizable: true,
      onRender: (item) => (item["progressPercentage"] !== undefined ? `${item["progressPercentage"]}%` : ""),
    },
    {
      key: "scheduleType",
      fieldName: "scheduleType",
      name: "Stream Type",
      minWidth: 110,
      maxWidth: 130,
      isResizable: true,
      onRender: (item) => {
        let scheduleType = item["scheduleType"] || "",
          runMode = item["runMode"]?.trim() && item["runMode"] !== "None" ? `${item["runMode"]}` : "";

        return scheduleType + (scheduleType && runMode ? ", " : "") + runMode;
      },
    },
    {
      key: "eventTimestamp",
      fieldName: "eventTimestamp",
      name: "Event Time",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      onRender: (item) => formatDateTime(item["eventTimestamp"]),
    },
    {
      key: "scheduledStartTime",
      fieldName: "scheduledStartTime",
      name: "Expected Start Time",
      minWidth: 110,
      maxWidth: 130,
      isResizable: true,
      onRender: (item) => formatDateTime(item["scheduledStartTime"]),
    },
    {
      key: "scheduledEndTime",
      fieldName: "scheduledEndTime",
      name: "Expected End Time",
      minWidth: 110,
      maxWidth: 130,
      isResizable: true,
      onRender: (item) => formatDateTime(item["scheduledEndTime"]),
    },
    {
      key: "sla",
      fieldName: "sla",
      name: "SLA",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      onRender: (item) => formatDateTime(item["sla"]),
    },
    {
      key: "actualSla",
      fieldName: "actualSla",
      name: "Actual SLA",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      onRender: (item) => formatDateTime(item["actualSla"]),
    },
    {
      key: "actualStartTime",
      fieldName: "actualStartTime",
      name: "Actual Start Time",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      onRender: (item) => formatDateTime(item["actualStartTime"]),
    },
    {
      key: "actualEndTime",
      fieldName: "actualEndTime",
      name: "Actual End Time",
      minWidth: 100,
      maxWidth: 120,
      isResizable: true,
      onRender: (item) => formatDateTime(item["actualEndTime"]),
    },
  ];

  // Remove the corresponding column if no data are defined for the column.
  columns = removeColumnWithNoData("scheduleName", columns, runs);
  columns = removeColumnWithNoData("state", columns, runs);
  columns = removeColumnWithNoData("progressPercentage", columns, runs);
  columns = removeColumnWithNoData("eventTimestamp", columns, runs);
  columns = removeColumnWithNoData("scheduledStartTime", columns, runs);
  columns = removeColumnWithNoData("scheduledEndTime", columns, runs);
  columns = removeColumnWithNoData("sla", columns, runs);
  columns = removeColumnWithNoData("actualSla", columns, runs);
  columns = removeColumnWithNoData("actualStartTime", columns, runs);
  columns = removeColumnWithNoData("actualEndTime", columns, runs);

  return columns;
};

const removeColumnWithNoData = (fieldName: string, columns: IColumn[], runs: any[]): IColumn[] => {
  let runsWithData = runs?.filter((run) => run[fieldName]?.toString()?.trim());

  if (!runsWithData?.length) {
    columns = columns.filter((column) => column.fieldName !== fieldName);
  }

  return columns;
};

const getStatusText = (item): string => {
  let status = item["status"],
    sla = item["sla"],
    actualSla = item["actualSla"],
    estimatedSla = item["estimatedSla"],
    actualStartTime = item["actualStartTime"],
    actualEndTime = item["actualEndTime"];

  if (status) {
    return status;
  }

  if (sla && actualStartTime) {
    if (actualSla) {
      return new Date(actualSla) <= new Date(sla) ? statusTextOnTime : statusTextDelayed; // Run completes.
    } else if (estimatedSla) {
      return new Date(estimatedSla) <= new Date(sla) ? statusTextOnTime : statusTextDelayed; // Run is still running.
    } else {
      return statusTextOnTime; // CFP scenario.
    }
  } else if (sla && !actualStartTime && new Date(sla) < new Date()) {
    return statusTextSkipped; // Run never started and crossed SLA time.
  } else if (!sla && actualEndTime) {
    return statusTextCompleted; // For FDL, we mark them as completed when run completes.
  }

  return "";
};

export const formatDateTime = (dateTimeString): string =>
  dateTimeString && moment(dateTimeString).format("YYYY-MM-DD HH:mm");

export const getCopilotDataText = (downStreamRuns: any[], upStreamRuns: any[]): string => {
  // TODO: Due to OpenAI token limit, we are capping our data to return for now.
  let result =
    "Use this data:\n\n" +
    "Team Name,Event Type,Schedule Name,Schedule Type,Run Mode,Scheduled Start Time,Scheduled End Time,Actual Start Time,Actual End Time,SLA,Status,State\n";

  result = getStreamRunsDataText(result, downStreamRuns);
  result = getStreamRunsDataText(result, upStreamRuns);

  return result;
};

const getStreamRunsDataText = (result: string, streamRuns: any[]): string => {
  if (streamRuns?.length && result.length < CopilotChatDataTextMaxSize) {
    streamRuns.sort((a, b) =>
      a["scheduledStartTime"] < b["scheduledStartTime"] ? 1 : a["scheduledStartTime"] > b["scheduledStartTime"] ? -1 : 0
    );

    for (let i = 0; i < streamRuns.length; i++) {
      let streamRun = streamRuns[i];

      result += `${streamRun["teamName"]},${streamRun["eventType"]},${streamRun["scheduleName"]},${streamRun["scheduleType"]},${streamRun["runMode"]},${streamRun["scheduledStartTime"]},${streamRun["scheduledEndTime"]},${streamRun["actualStartTime"]},${streamRun["actualEndTime"]},${streamRun["sla"]},${streamRun["status"]},${streamRun["state"]}\n`;

      if (result.length >= CopilotChatDataTextMaxSize) {
        break;
      }
    }
  }

  return result;
};

export const isUnhealthyRun = (run, ignoreUnhealthyRun): boolean =>
  run.status?.toLowerCase() === statusTextUnhealthy.toLowerCase() && !ignoreUnhealthyRun;
