import { Account } from "msal/lib-es6";
import * as thunkActions from "./thunk";
import { IAppState, IUserInfo, ISystemMessage } from "../app/interfaces";
import { IAction } from "../reducers/interfaces";
import { INavLink } from "@fluentui/react/lib/Nav";
import {
  getDefaultLeftNavLink,
  getLeftNavLinkByUrl,
  getLeftNavLinkParents,
  getSmallScreenStatus,
  getMediumScreenStatus,
  isStaging,
  leftNavCollapsedStateName,
} from "./duck.helper";
import { IHealthMetricGroup, IHealthMetricSet, IHealthMetricType } from "../appModules/home/HealthDashboard.types";
import { leftNavUrls } from "./LeftNav.helper";

export const actionTypes = {
  RECEIVED_LOGIN_USER: "RECEIVED_LOGIN_USER",
  RECEIVED_LOGIN_USER_PHOTO: "RECEIVED_LOGIN_USER_PHOTO",
  RECEIVED_LOGIN_USER_INFO: "RECEIVED_LOGIN_USER_INFO",
  SELECT_LEFT_NAV_LINK: "SELECT_LEFT_NAV_LINK",
  SELECT_LEFT_NAV_LINK_BY_URL: "SELECT_LEFT_NAV_LINK_BY_URL",
  TOGGLE_LEFT_NAV: "TOGGLE_LEFT_NAV",
  COLLAPSE_LEFT_NAV: "COLLAPSE_LEFT_NAV",
  RESIZE_WINDOW: "RESIZE_WINDOW",
  LOAD_SYSTEM_MESSAGES_COMPLETED: "LOAD_SYSTEM_MESSAGES_COMPLETED",
  LOAD_SYSTEM_MESSAGES_FAILED: "LOAD_SYSTEM_MESSAGES_FAILED",
  LOAD_HEALTH_METRIC_TYPES_COMPLETED: "LOAD_HEALTH_METRIC_TYPES_COMPLETED",
  LOAD_HEALTH_METRIC_GROUPS_COMPLETED: "LOAD_HEALTH_METRIC_GROUPS_COMPLETED",
  LOAD_HEALTH_METRIC_SETS_COMPLETED: "LOAD_HEALTH_METRIC_SETS_COMPLETED",
};

export const actionCreator = {
  ...thunkActions,
  receivedLoginUser: (user: Account) => ({
    type: actionTypes.RECEIVED_LOGIN_USER,
    user,
  }),
  receivedLoginUserPhoto: (photoUrl: string) => ({
    type: actionTypes.RECEIVED_LOGIN_USER_PHOTO,
    photoUrl,
  }),
  receivedLoginUserInfo: (userInfo: IUserInfo) => ({
    type: actionTypes.RECEIVED_LOGIN_USER_INFO,
    userInfo,
  }),
  selectLeftNavLink: (navLink: INavLink) => ({
    type: actionTypes.SELECT_LEFT_NAV_LINK,
    navLink,
  }),
  selectLeftNavLinkByUrl: (url: string, navGroups = null) => ({
    type: actionTypes.SELECT_LEFT_NAV_LINK_BY_URL,
    url,
    navGroups,
  }),
  toggleLeftNav: () => ({
    type: actionTypes.TOGGLE_LEFT_NAV,
  }),
  collapseLeftNav: () => ({
    type: actionTypes.COLLAPSE_LEFT_NAV,
  }),
  resizeWindow: () => ({
    type: actionTypes.RESIZE_WINDOW,
  }),
  loadSystemMessagesCompleted: (systemMessages: ISystemMessage[]) => ({
    type: actionTypes.LOAD_SYSTEM_MESSAGES_COMPLETED,
    systemMessages,
  }),
  loadSystemMessagesFailed: (error: string) => ({
    type: actionTypes.LOAD_SYSTEM_MESSAGES_FAILED,
    error,
  }),
  loadHealthMetricTypesCompleted: (healthMetricTypes: IHealthMetricType[]) => ({
    type: actionTypes.LOAD_HEALTH_METRIC_TYPES_COMPLETED,
    healthMetricTypes,
  }),
  loadHealthMetricGroupsCompleted: (healthMetricGroups: IHealthMetricGroup[]) => ({
    type: actionTypes.LOAD_HEALTH_METRIC_GROUPS_COMPLETED,
    healthMetricGroups,
  }),
  loadHealthMetricSetsCompleted: (healthMetricSets: IHealthMetricSet[]) => ({
    type: actionTypes.LOAD_HEALTH_METRIC_SETS_COMPLETED,
    healthMetricSets,
  }),
};

let defaultLeftNavLink = getDefaultLeftNavLink(),
  isSmallScreen = getSmallScreenStatus(),
  isStagingInitial = isStaging(),
  leftNavCollapsed = localStorage[leftNavCollapsedStateName];

if (window.location.hash?.indexOf(leftNavUrls.copilot) >= 0) {
  leftNavCollapsed = true;
} else if (leftNavCollapsed !== "true" && leftNavCollapsed !== "false") {
  leftNavCollapsed = getMediumScreenStatus();
  localStorage[leftNavCollapsedStateName] = leftNavCollapsed;
} else {
  leftNavCollapsed = leftNavCollapsed === "true";
}

export const initialState: IAppState = {
  login_user: null,
  login_user_photo: "",
  selected_left_nav_link: defaultLeftNavLink,
  left_nav_parents: getLeftNavLinkParents(defaultLeftNavLink, isStagingInitial),
  left_nav_collapsed: leftNavCollapsed,
  is_small_screen: isSmallScreen,
  is_staging: isStagingInitial,
  is_production: window.location.hostname === "radar.microsoft.com",
  is_prod_secondary: window.location.hostname === "commerceradar-secondary.azurewebsites.net",
};

const reducer = (state: IAppState = initialState, action: IAction): IAppState => {
  switch (action.type) {
    case actionTypes.RECEIVED_LOGIN_USER: {
      return {
        ...state,
        login_user: action.user,
      };
    }
    case actionTypes.RECEIVED_LOGIN_USER_PHOTO: {
      return {
        ...state,
        login_user_photo: action.photoUrl,
      };
    }
    case actionTypes.RECEIVED_LOGIN_USER_INFO: {
      return {
        ...state,
        login_user_info: action.userInfo,
      };
    }
    case actionTypes.SELECT_LEFT_NAV_LINK: {
      return {
        ...state,
        selected_left_nav_link: action.navLink,
        left_nav_parents: getLeftNavLinkParents(action.navLink, state.is_staging),
      };
    }
    case actionTypes.SELECT_LEFT_NAV_LINK_BY_URL: {
      let navLink: INavLink = getLeftNavLinkByUrl(`#${action.url}`, action.navGroups);
      return reducer(state, actionCreator.selectLeftNavLink(navLink));
    }
    case actionTypes.TOGGLE_LEFT_NAV: {
      let newCollapsedState = !state.left_nav_collapsed;

      localStorage[leftNavCollapsedStateName] = newCollapsedState;

      return {
        ...state,
        left_nav_collapsed: newCollapsedState,
      };
    }
    case actionTypes.COLLAPSE_LEFT_NAV: {
      let newCollapsedState = true;

      return {
        ...state,
        left_nav_collapsed: newCollapsedState,
      };
    }
    case actionTypes.RESIZE_WINDOW: {
      let isSmallScreen = getSmallScreenStatus();
      return {
        ...state,
        left_nav_collapsed: isSmallScreen || state.left_nav_collapsed,
        is_small_screen: isSmallScreen,
      };
    }
    case actionTypes.LOAD_SYSTEM_MESSAGES_COMPLETED: {
      return {
        ...state,
        system_messages: action.systemMessages && action.systemMessages.settings,
      };
    }
    case actionTypes.LOAD_HEALTH_METRIC_TYPES_COMPLETED: {
      return {
        ...state,
        health_metric_types: action.healthMetricTypes,
      };
    }
    case actionTypes.LOAD_HEALTH_METRIC_GROUPS_COMPLETED: {
      return {
        ...state,
        health_metric_groups: action.healthMetricGroups,
      };
    }
    case actionTypes.LOAD_HEALTH_METRIC_SETS_COMPLETED: {
      return {
        ...state,
        health_metric_sets: action.healthMetricSets,
      };
    }
    default:
      return state;
  }
};

export default reducer;
