import React from "react";
import BasicModal from "./BasicModal";
import { TextField } from "@fluentui/react/lib/TextField";
import { Dropdown } from "@fluentui/react/lib/Dropdown";
import { ILinkedItem, LinkedItemType } from "./interfaces";
import classNames from "./LinkedItemModal.module.scss";

export interface ILinkedItemModalProps {
  linkedItem: ILinkedItem;
  saving?: boolean;
  error?: string;
  onCommit: (linkedItem: ILinkedItem) => void;
  onCancel: () => void;
}

export interface ILinkedItemModalState {
  externalId: string;
  linkUrl: string;
  itemType: LinkedItemType;
  title: string;
}

export class LinkedItemModal extends React.Component<ILinkedItemModalProps> {
  state = {
    externalId: this.props.linkedItem.externalId,
    linkUrl: this.props.linkedItem.linkUrl,
    itemType: this.props.linkedItem.itemType || LinkedItemType.IcM,
    title: this.props.linkedItem.title,
  };

  getItemTypeOptions = () => {
    const itemTypeOptions = [];
    for (const key in LinkedItemType) {
      if (isNaN(Number(key))) {
        itemTypeOptions.push({ key: key, text: key });
      }
    }
    return itemTypeOptions;
  };

  render() {
    const { linkedItem, saving, error } = this.props;
    const { linkedItemId } = linkedItem;
    const { externalId, linkUrl, itemType, title } = this.state;

    const modalTitle = linkedItemId ? "Update Linked Item" : "Create New Linked Item",
      defaultLinkUrl = this.getLinkUrl(itemType, externalId);

    const itemTypeOptions = this.getItemTypeOptions();
    const requireIdField = itemType === LinkedItemType.DevOps || itemType === LinkedItemType.IcM;

    return (
      <BasicModal
        modalRootClassName={classNames.modalRoot}
        title={modalTitle}
        onCommit={this.onCommit}
        onCancel={this.onClose}
        saving={saving}
        error={error}
      >
        <div className={classNames.entryField}>
          <div className={classNames.fieldLabel}>Linked Item Type</div>
          <div className={classNames.fieldValue}>
            <Dropdown
              options={itemTypeOptions}
              defaultSelectedKey={itemType}
              style={{ width: 100 }}
              onChange={(ev, option) => this.onFieldValueChange("itemType", option.key.toString())}
            />
          </div>
        </div>
        {requireIdField && (
          <div className={classNames.entryField}>
            <div className={classNames.fieldLabel}>{itemType} Id</div>
            <div className={classNames.fieldValue}>
              <TextField
                onChange={(ev, newValue) => this.onFieldValueChange("externalId", newValue)}
                value={externalId}
              />
            </div>
          </div>
        )}
        <div className={`${classNames.entryField}`}>
          <div className={classNames.fieldLabel}>External URL</div>
          <div className={classNames.fieldValue}>
            <TextField
              onChange={(ev, newValue) => this.onFieldValueChange("linkUrl", newValue)}
              value={linkUrl}
              placeholder={defaultLinkUrl}
              style={{ width: 600 }}
            />
          </div>
        </div>
        <div className={`${classNames.entryField} ${classNames.fullWidth}`}>
          <div className={classNames.fieldLabel}>Title</div>
          <div className={classNames.fieldValue}>
            <TextField
              onChange={(ev, newValue) => this.onFieldValueChange("title", newValue)}
              value={title}
              style={{ width: 600 }}
            />
          </div>
        </div>
      </BasicModal>
    );
  }

  onFieldValueChange = (fieldName: string, newValue: string) => {
    this.setState({ [fieldName]: newValue });
  };

  onCommit = () => {
    let linkUrl = this.state.linkUrl;

    if (!linkUrl) {
      const { itemType, externalId } = this.state;
      linkUrl = this.getLinkUrl(itemType, externalId);
    }

    this.props.onCommit({
      ...this.props.linkedItem,
      ...this.state,
      linkUrl,
    });
  };

  onClose = () => {
    this.props.onCancel();
  };

  getLinkUrl = (itemType: LinkedItemType, externalId: string): string => {
    if (itemType === LinkedItemType.DevOps) {
      return `https://microsoft.visualstudio.com/_workitems/edit/${externalId}`;
    } else if (itemType === LinkedItemType.IcM) {
      return `https://portal.microsofticm.com/imp/v3/incidents/details/${externalId}/home`;
    }
    return "";
  };
}

export default LinkedItemModal;
