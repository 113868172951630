import React, { useEffect, useMemo, useState } from "react";
import { ConfigItemType, fetchConfigItem } from "../../api/radarApi";
import HealthDashboard from "./HealthDashboard";
import { IHealthMetricGroup } from "./HealthDashboard.types";

export const HealthDashboardContent = () => {
  const [healthMetricGroups, setHealthMetricGroups] = useState<IHealthMetricGroup[]>([]);
  const selectedMetricGroupId = (healthMetricGroups?.length && healthMetricGroups[0].id) || 1;
  const selectedMetricGroup = useMemo(
    () => healthMetricGroups?.find((metricGroup) => metricGroup.id === selectedMetricGroupId),
    [healthMetricGroups, selectedMetricGroupId]
  );

  useEffect(() => {
    fetchConfigItem(ConfigItemType.AppSettings, "HealthMetricGroups").then((configItem: any) =>
      setHealthMetricGroups(configItem?.settings)
    );
  }, []);

  return (
    <HealthDashboard
      selectedMetricGroupId={selectedMetricGroupId}
      selectedMetricGroup={selectedMetricGroup}
      healthMetricGroups={healthMetricGroups}
    />
  );
};

export default HealthDashboardContent;
