import { VisualType } from "../../shared/components/Visual";
import { ContentType, IChatResponse, QueryType } from "./interfaces";

export const testContentResponses: IChatResponse[] = [
  {
    contents: [
      { type: ContentType.Markdown, body: "Here is the Kusto query based on your requirements:" },
      {
        type: ContentType.Code,
        body: "DataTable |\nwhere id == '123' |\norder by date",
        queryType: QueryType.Kusto,
        dataSource: {
          cluster: "https://crsintfollower.westus.kusto.windows.net",
          database: "control",
          dataSourceType: "kusto",
        },
      },
    ],
    messages: [
      { message: "API calling AgentGroupChat with agent1, agent2, agent3", timestamp: "2025-02-06T08:30:00.000" },
      { message: "agent1 calling Semantic Kernel to generate Kusto query", timestamp: "2025-02-06T08:31:00.000" },
    ],
  },
  {
    contents: [
      { type: ContentType.Markdown, body: "Here is the returned data based on the Kusto query:" },
      {
        type: ContentType.Table,
        data: [
          { column1: "value1.1", column2: "value1.2" },
          { column1: "value2.1", column2: "value2.2" },
        ],
      },
      {
        type: ContentType.Markdown,
        body: "Note: You can switch the visual type for different visualization of the data.",
      },
    ],
  },
  {
    contents: [
      { type: ContentType.Markdown, body: "Here is an example of multiple tiles of data being returned:" },
      {
        type: ContentType.Tile,
        tiles: [
          {
            name: "Column Chart Example",
            cluster: "https://crsintfollower.westus.kusto.windows.net",
            database: "control",
            dataSourceType: "kusto",
            visualType: VisualType.column,
            categoryFieldName: "column1",
            valueFieldNames: ["column2"],
            data: [
              { column1: "2025-01", column2: 30 },
              { column1: "2025-02", column2: 40 },
            ],
            height: 200,
            visualProps: { margin: { bottom: 60 } },
          },
          {
            name: "Data Table",
            cluster: "https://crsintfollower.westus.kusto.windows.net",
            database: "control",
            dataSourceType: "kusto",
            visualType: VisualType.table,
            data: [
              { column1: "value1.1", column2: "value1.2" },
              { column1: "value2.1", column2: "value2.2" },
            ],
            height: 200,
          },
        ],
      },
    ],
  },
];
