import ReactMarkdown from "react-markdown";
import { ContentType, IContent, IChatResponse } from "./interfaces";
import classNames from "./CopilotPane.module.scss";
import VisualTileList from "../../shared/components/Tile/VisualTileList";
import SyntaxHighlighter from "react-syntax-highlighter";
import { dark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { PrimaryButton } from "@fluentui/react";
import { Visual, VisualType } from "../../shared/components/Visual";
import remarkGfm from "remark-gfm";
import HealthDashboardContent from "../home/HealthDashboardContent";
import AuditOfAudits from "../home/AuditOfAudits";
import IncidentsReport from "../incidents/IncidentsReport";

export const getMessageContent = (contentResponse: IChatResponse, onExecuteQuery?): JSX.Element => {
  const contents = [];

  if (!contentResponse?.contents?.length) {
    contents.push(<p>No content is returned.</p>);
  } else {
    contentResponse.contents?.forEach((content) => {
      switch (content.type) {
        case ContentType.Markdown:
          contents.push(
            <p className={classNames.markdown}>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>{content.body}</ReactMarkdown>
            </p>
          );
          break;
        case ContentType.Code:
          contents.push(getCodeContent(content, onExecuteQuery));
          break;
        case ContentType.Table:
          contents.push(getDataContent(content));
          break;
        case ContentType.Tile:
          contents.push(getTileContent(content));
          break;
        case ContentType.CloseDashboard:
          contents.push(getCloseDashboardContent());
          break;
        case ContentType.ControlStatuses:
          contents.push(getControlStatusesContent());
          break;
        case ContentType.FinLivesite:
          contents.push(getFinLivesiteContent());
          break;
        default:
          contents.push(<p>{content.body}</p>);
          break;
      }
    });
  }

  return <div>{contents}</div>;
};

export const getCodeContent = (content: IContent, onExecuteQuery?): JSX.Element => {
  let language = (content?.metadata && content?.metadata["language"]) || "kusto";
  return (
    <>
      <SyntaxHighlighter className={classNames.queryContainer} language={language} style={dark}>
        {content.body?.replaceAll("\\r\\n", "\r\n")}
      </SyntaxHighlighter>
      {onExecuteQuery && (
        <PrimaryButton className={classNames.button} text="Execute Query" onClick={() => onExecuteQuery(content)} />
      )}
    </>
  );
};

export const getDataContent = (content: IContent): JSX.Element => {
  if (!content.body?.length) {
    return <p>No data is found.</p>;
  }

  let data;

  try {
    data = JSON.parse(content.body);
  } catch {
    return <p>Failed to parse JSON data.</p>;
  }

  const tableWidth = getTableWidth(data);

  return (
    <div className={classNames.dataTable} style={{ width: tableWidth }}>
      <Visual data={data} visualType={VisualType.table} />
    </div>
  );
};

export const getTileContent = (content: IContent): JSX.Element => {
  if (!content.body?.length) {
    return <p>No tiles data is found.</p>;
  }

  let tiles;

  try {
    tiles = JSON.parse(content.body);
  } catch {
    return <p>Failed to parse JSON data.</p>;
  }

  if (!Array.isArray(tiles)) {
    tiles = [tiles];
  }

  return <VisualTileList className={classNames.tiles} tiles={tiles} />;
};

export const getCloseDashboardContent = (): JSX.Element => {
  return (
    <p className={classNames.pageContent}>
      <HealthDashboardContent />
    </p>
  );
};

export const getControlStatusesContent = (): JSX.Element => {
  return (
    <p className={classNames.pageContent}>
      <AuditOfAudits showOnboardStatus={true} />
    </p>
  );
};

export const getFinLivesiteContent = (): JSX.Element => {
  return (
    <p className={classNames.pageContent}>
      <IncidentsReport history={undefined} location={undefined} match={undefined} />
    </p>
  );
};

const defaultCharWidth = 16;

const getTableWidth = (data: any[]): number => {
  let result = 0;

  if (data?.length) {
    let firstRow = data[0]; // Use first row of data as reference. TODO: use more rows as reference.

    Object.keys(firstRow).forEach((columnName) => {
      let columnMaxChar = Math.max(columnName.length, firstRow[columnName]?.toString().length);
      result += Math.max(columnMaxChar * defaultCharWidth, 140); //
    });
  }

  return Math.max(result, 300);
};
